Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.placeHolderEmail = "Email";

exports.labelHeader = "Value proposition: why users should sign up.";
exports.labelFirstName = "First name";
exports.lastName = "Last name";
exports.labelEmail = "Email";
exports.labelPassword = "Password";
exports.labelRePassword = "Re-Type Password";
exports.errorCountryCodeNotSelected = "Please select country code";
exports.errorBlankField = "can't be blank";

exports.labelLegalText =
  "Legal text: By signing up you agree to our Terms &amp; conditions and Privacy policy.";

exports.labelLegalTermCondition = "Terms and conditions";
exports.labelLegalPrivacyPolicy = "Privacy policy";
exports.btnTextSignUp = "Sign up";
exports.errorPasswordNotValid = "Password not valid.";

exports.errorTitle = "Error";

exports.errorBothPasswordsNotSame = "Passwords should be same.";
exports.errorAllFieldsAreMandatory = "All fields are mandatory.";

exports.contentTypeApiAddDetail = "application/json";

exports.accountsAPiEndPoint ="account_block/accounts";

exports.apiMethodTypeAddDetail = "POST";

exports.errorEmailNotValid = "Email not valid.";
exports.urlGetValidations =
  "profile/validations";
exports.validationApiContentType = "application/json";

exports.validationApiMethodType = "GET";

exports.welcome = "Welcome";
exports.descr = "Please sign up to your account";
exports.reupload = "Re-upload Photo";
exports.removephoto = "Remove Photo";
exports.uploadphoto = "Upload Profile Photo";
exports.photodetail = "Use images at least 800x800 pixels in .jpg or .png format. If no picture is uploaded, your initials will be used";
exports.signuptovitu = "Sign Up to Vitu";
exports.signupwithgoogle = "Sign Up with Google";
exports.alreadyaccount = "Already have an account?";
exports.logintovitu = "Login to a Vitu account";

exports.setupPasswordEndPoint = "account_block/accounts/set_password";
exports.setupPasswordMethod = "POST";

exports.phoneNumberApiEndPoint = "https://people.googleapis.com/v1/people/me?personFields=phoneNumbers"
exports.googleLoginApiEndPoint = "bx_block_login/social_login"
exports.googleLoginApiMethod = "POST"
// Customizable Area End
