import React from "react";
// Customizable Area Start
import {

  Typography,
  Box,

  Button,
 
} from "@material-ui/core";
import { createTheme } from "@material-ui/core/styles";

import SavedDealController, { Props } from "./SavedDealController";

import SavedDeal from "./SavedDeal.web";
import { deal } from "./assets";


const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
  spacing: 8,
});

const styles = {
  containerStyle: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    marginTop: '300px',
    marginBottom: '500px',
    gap:'0px',

  
  }
};

export default class MultipleDealership extends SavedDealController {

  constructor(props: Props) {
    super(props);
  
  }

  render() {

    return (
      <Box width={'100%'}>
        <Box display={'flex'} maxWidth={'1144px'} width={'100%'} flexDirection={'column'} style={{gap:'32px'}}>
          <Typography style={{fontFamily: "Gotham",fontSize: "30px",fontWeight: 300,lineHeight: "40px",textAlign: "left",color:"#013D4F"
}}>Let's Create a Deal</Typography>
          <Typography style={{color:'#4B4C4B',fontFamily: "Gotham",
fontSize: "18px",
fontWeight: 300,
lineHeight: "18px",
textAlign: "left",
marginTop:"-13px"
}}>Start from scratch or pick up where you left off</Typography>
 <Box display="flex"  mb={2}>
            <Button data-test-id="start_btn" style={{width: '200px', textTransform: 'initial', padding:'10px 12px',borderBottom:this.state.selectedTab=='startFromScratch'? '1px solid black' : "none",color:this.state.selectedTab=='startFromScratch'? '#013D4F' : "#C5CBC9",fontFamily:'Roboto',fontWeight:600,fontSize:'16px',lineHeight:'24px',borderRadius:'0px' }} onClick={() => this.handleTabChange('startFromScratch')} color="primary">
              Start from Scratch
            </Button>
            <Button data-test-id="saved_btn" style={{width: '200px', textTransform: 'initial', padding:'10px 12px',borderBottom:this.state.selectedTab=='savedDeals'? '1px solid black' : "none",color:this.state.selectedTab=='savedDeals'? '#013D4F' : "#C5CBC9",fontFamily:'Roboto',fontWeight:600,fontSize:'16px',lineHeight:'24px',borderRadius:'0px' }} onClick={() => this.handleTabChange('savedDeals')} color="primary">
              Saved Deals
            </Button>
 </Box>
        </Box>
        <Box>
        
          {this.state.selectedTab === 'startFromScratch' && this.renderStartFromScratch()}
          {this.state.selectedTab === 'savedDeals' && this.renderSavedDeals()}

        </Box>
      </Box>
    );
  }

  renderStartFromScratch() {
 

    return (
      <Box>
        <Box mb={2}>
          <Typography style={{fontFamily:'Roboto',fontSize:'20px',fontWeight:600,lineHeight:'24px'}}>Choose the Dealership</Typography>
        </Box>

        <Box display="flex" justifyContent="space-around" flexWrap="wrap">

      {this.state.dealershipData?.map((ele:any)=>{
        
      return  this.renderDealership(ele?.id,ele?.attributes?.dealership_logo,ele?.attributes?.dealership_name, ele?.attributes?.addresses?.data[0]?.attributes?.address,ele?.attributes?.addresses?.data[0]?.attributes?.city,ele?.attributes?.addresses?.data[0]?.attributes?.state,ele?.attributes?.addresses?.data[0]?.attributes?.country)

      })}

          

        </Box>
      </Box>
    );
  }

  renderSavedDeals() {
    return (
      <Box>
        <SavedDeal checkHeader="false" handleModal={this.handleClose} navigation={this.props.navigation}/>
      </Box>
    );
  }

  renderDealership(dealId:any,logo:any,name:any, address:any,city:any,state:any,country:any) {
    return (
      <Box
        border="1px solid #F0FAFA"
        borderRadius="12px"
        padding="48px 24px"
        margin="8px"
        textAlign="center"
        maxWidth="365px"
        display={'flex'}
        flexDirection={'column'}
        style={{gap:'24px'}}
      >
        <Box display={'flex'}
        flexDirection={'column'}
        justifyContent={'center'}
        style={{gap:'12px'}} >
            <Box display={'flex'} justifyContent={'center'} alignItems={'center'} width={'100%'} margin={'auto'}> <img src={logo || deal }  alt='deal' width={'48px'} height={'48px'} style={{borderRadius:'12px'}} /></Box>
          <Typography variant="h6">{name}</Typography>
          <Typography variant="body2">{address} {city} {state} {country}</Typography>
        </Box>
        <Button variant="outlined" style={{borderRadius:'8px',padding:'16px',border:'1px solid #013D4F',fontFamily:'Roboto',fontSize:'16px',fontWeight:700,lineHeight:'24px'}} onClick={()=>{
          
          localStorage.setItem("dInfoId",dealId);
          this.navigateToCreatedeal()
          
          }}>Create Deal</Button>
      </Box>
    );
  }

  renderSavedDeal(name:any, details:any) {
    return (
      <Box
        border="1px solid #ccc"
        borderRadius="8px"
        padding="16px"
        margin="8px"
        textAlign="center"
        flex="1 1 30%"
        maxWidth="300px"
      >
        <Box mb={2}>
          <Typography variant="h6">{name}</Typography>
          <Typography variant="body2">{details}</Typography>
        </Box>
        <Button variant="outlined">Open Deal</Button>
      </Box>
    );
  }

}
