import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData, removeStorageData, setStorageData } from "../../../framework/src/Utilities";


// Customizable Area Start
export type NavObject = TypeNav
export interface TypeNav {
    addListener: Function
    goBack: Function,
    getParam: Function,
    navigate: Function
}

export interface ToggleButtonGroupState {
    selectedButtons: string[];
  }
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: NavObject;
    id: string;
    // Customizable Area Start

    // Customizable Area End
}


interface S {
    txtInputValue: string;
    txtSavedValue: string;
    enableField: boolean;
    // Customizable Area Start
    fullName: string;
    loginemail: string;
    name: string;
    template_dis: string;
    phoneNumber: string;
    loginpassword: string;
    showPassword: boolean;
    errors: {
        [key: string]: string;
    };
    registrationSuccess: boolean;
    isModalOpen: boolean,
    isModelFuntionCall: boolean,
    isEditBtn: boolean,
    getUserData: [],
    getShowUserData: {
        attributes: {
            comment: string; id: number, name: string, description: string, status: string,
        };
    },
    getUserDataArchive: [],
    isArchiveModalOpen: boolean
    getorganizationsDetails: [],

    //create Quote
    itemName: string,
    itemPrice: string,
    itemQuantity: string,
    discount: string,
    description: string,
    //PUT for Achive
    serialIdforArchive: string,
    //Level 1
    leveOneModal: boolean;
    selectedRowData: {},
    statusForlevelOne: string;
    selectedStatus: string;
    userRole: string;
    userRolelevelOne: string;
    anchorEl: null,
    selectedOption: string,
    isEditModalOpen: boolean,
    isShowModalOpen: boolean,
    isStatusModalOpen: boolean,
    editClickDataLeveZero: [],
    itemNameEdit: string,
    itemPriceEdit: string,
    itemQuantityEdit: string,
    discountEdit: string,
    descriptionEdit: string,
    serialIdEdit: number;
    openDialog: boolean;
    total_page: number;
    currentPage: number;
    itemsPerPage: number;
    statusBtn: boolean;
    status: string;
    initials: string,
    profilePhoto: string | null,
    termsAndConditionCheck: boolean,
    submittedConfFees : boolean,
    submitReview : boolean,
    rejectedReview : boolean,
    issueResolved : boolean,
    likeButton : boolean | string,
    showTextArea : boolean,
    selectedItems: string[],
    tellUsWhySubject : string,
    tellUsWhyDetails : string,
    // Customizable Area End
}

interface SS {
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface ApiData {
    header?: Record<string, string>;
    contentType?: string;
    method: string;
    endPoint: string;
    body?: LoginPayload | TemplateData | Payload | TemplateIdData | FormData// Adjust the type of body as needed
    type?: string;
}

interface LoginPayload {
    data: {
        email: string;
        password: string;
    };
}

interface TemplateData {
    template: {
        name: string;
        description: string;
    };
}

interface Payload {
    template: {
        name: string;
        description: string;
        kpis_attributes: {
            [key: number]: {
                name: string;
                description: string;
                point: number;
            };
        };


    };
}

interface ChangeEvent {
    target: {
        name: string;
        value: string;
    };
}


interface TemplateIdData {
    status: string;
    comment: string;
}

interface ChangeEvent {
    target: {
        name: string;
        value: string;
    };
}

export interface EventList {
    id: number,
    attributes: {
        name: string,
        description: string,
        status: string,
        comment: string,

    }
}



export default class MultilevelApprovalController extends BlockComponent<
    Props,
    S,
    SS
>
{

    // Customizable Area Start
    AttendancDetailsApi: string = "";
    AttendancePostApi: string = "";
    AttendancDetailsArchivedApi: string = "";
    templatesApi: string = "";
    ArchivedDataPutApi: string = "";
    UpdateApiPutApi: string = "";
    StatusDataPutApi: string = "";
    loginPostDeatilsApi: string = "";
    editUpdateLevelZeroApi: string = "";
    //Level 1
    getListApi: string = "";
    getShowListApi: string = "";
    levelOneTestingApi: string = "";

    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
            // Customizable Area End
        ];

        this.state = {
            txtInputValue: "",
            txtSavedValue: "A",
            enableField: false,
            // Customizable Area Start
            fullName: "",
            loginemail: "",
            name: "",
            template_dis: "",
            phoneNumber: "",
            loginpassword: "",
            showPassword: false,
            errors: {},
            registrationSuccess: false,
            isModalOpen: false,
            isModelFuntionCall: false,
            isEditBtn: false,
            getUserData: [],
            getShowUserData: {
                attributes: {
                    id: 0,
                    name: '',
                    description: '',
                    status: '',
                    comment: ''
                }
            },
            getUserDataArchive: [],
            isArchiveModalOpen: false,
            getorganizationsDetails: [],
            itemName: '',
            itemPrice: '',
            itemQuantity: '',
            discount: '',
            description: '',
            //Put for Archive data
            serialIdforArchive: '',
            //Level 1
            leveOneModal: false,
            selectedRowData: '',
            statusForlevelOne: '',
            selectedStatus: 'under_review',
            userRole: '',
            userRolelevelOne: '',
            anchorEl: null,
            selectedOption: "",
            isEditModalOpen: false,
            isStatusModalOpen: false,
            isShowModalOpen: false,
            editClickDataLeveZero: [],
            itemNameEdit: '',
            itemPriceEdit: '',
            itemQuantityEdit: '',

            discountEdit: '',
            descriptionEdit: '',
            serialIdEdit: 0,
            openDialog: false,
            total_page: 0,
            itemsPerPage: 5,
            currentPage: 1,
            statusBtn: false,
            status: 'pending',
            initials: '',
            profilePhoto: null,
            termsAndConditionCheck: false,
            submittedConfFees : false,
            submitReview : false,
            rejectedReview : false,
            issueResolved : false,
            likeButton : "",
            showTextArea : false,
            selectedItems : [],
            tellUsWhySubject : "",
            tellUsWhyDetails : "",
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start

        // Customizable Area End
    }



    // Customizable Area Start

    handleTermsAndConditionCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
        const isChecked = event.target.checked;
     
        this.setState({
          termsAndConditionCheck: isChecked
        });
    }

    setSubmittedTrue = () => {
        this.setState({
            submittedConfFees: true,
        })
    }

    setSubmitReview = () => {
        this.setState({
            submittedConfFees : false,
            submitReview: true,
        })
    }

    navigateToDashboard =()=>{
        const inviteUserTxtData =new Message( getName(MessageEnum.NavigationMessage ));
        inviteUserTxtData.addData(getName( MessageEnum.NavigationTargetMessage ), 
        "Dashboard");
        inviteUserTxtData.addData(getName(MessageEnum.NavigationPropsMessage ), this.props);
        
        const raiseMessage: Message = new Message(
            getName(MessageEnum.NavigationPayLoadMessage)
          );
          raiseMessage.addData(getName(MessageEnum.NavigationPayLoadMessage), {view: "HOME"});
          inviteUserTxtData.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
          runEngine.sendMessage("MergeEngineUtilities", inviteUserTxtData);
      }

      navigateToDealDashboard =()=>{
        const inviteUserTxtData =new Message( getName(MessageEnum.NavigationMessage ));
        inviteUserTxtData.addData(getName( MessageEnum.NavigationTargetMessage ),"ProjectTemplatesDealDashboard");
        inviteUserTxtData.addData(getName(MessageEnum.NavigationPropsMessage ), this.props);
        this.send( inviteUserTxtData);
      }

      setIssueResolved = () => {
        this.setState({
            rejectedReview: false,
            issueResolved : true,
        })
      }

      setRejectedReview = () => {
        this.setState({
            rejectedReview : true,
        })
    }

    cancelRejectedReview = () => {
        this.setState({
            rejectedReview : false,
        })
    }

    handleTellUsWhySubject = (event : any) => {
        this.setState({
            tellUsWhySubject : event.target.value
        })
    }

    handleTellUsWhyDetails = (event : any) => {
        this.setState({
            tellUsWhyDetails : event.target.value
        })
    }

    handleTellUsWhySentCond = () => {
        return this.state.tellUsWhySubject.length > 0 && this.state.tellUsWhyDetails.length > 0
    }

    handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        this.setState({
            likeButton : value === "true",
            showTextArea: true,
    });
      };

      handleButtonClick = (item: string) => {
        this.setState((prevState: Readonly<S>) => {
          const { selectedItems } = prevState;
          if (selectedItems.includes(item)) {
            return { selectedItems: selectedItems.filter((i) => i !== item) };
          } else {
            return { selectedItems: [...selectedItems, item] };
          }
        });
      };

    async componentDidMount(): Promise<void> {
        setStorageData("authToken", "eyJhbGciOiJIUzUxMiJ9.eyJpZCI6MTMzLCJleHAiOjE3MDg1MTk4NTd9.BzJ66GYWo0swEr2F3I2jVi7IfZmjICN0pfrlVd2mRL3xUVPyeI721YoT3GlumkNolUk6sZw0HxqeMsWUjv2iMQ")
        setStorageData("userRole", "level-1")

        this.props.navigation.navigate("MultilevelApproval")

    }

    componentDidUpdate(prevProp: Props, prevState: S) {
        console.log('submittedConfFees --> ', this.state.submittedConfFees)
        console.log('submitReview --> ', this.state.submitReview)

    }

    async receive(from: string, message: Message) {
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
        }
    }

    // Customizable Area End
}

