import React from "react";
// Customizable Area Start
import OrderSummaryChecklistFeeController, {
  Props,
} from "./OrderSummaryChecklistFeeController";
import { withStyles, createStyles } from "@material-ui/core";
import OrderSummary from "./OrderSummary.web";

const styles = createStyles({
  container: {},
});
// Customizable Area End

export class OrderSummaryChecklistFee extends OrderSummaryChecklistFeeController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End
  render() {
    // Customizable Area Start
    return(
      <OrderSummary id="" navigation={this.props.navigation} formType="checklist"/>
    )
    // Customizable Area End
  }
}

export default withStyles(styles)(OrderSummaryChecklistFee);