import { Task } from '../../blocks/tasks/src/DealDashboardTaskListController';
import React, { Component } from 'react';
import {Box, Checkbox, FormControlLabel, IconButton, WithStyles, createStyles, withStyles} from "@material-ui/core";
import TaskMenu from './TaskMenu';
import {ArrowUpward, ArrowDownward} from '@material-ui/icons';
import { CustomCheckboxIcon, CustomCheckedCheckboxIcon } from '../../../packages/blocks/ordermanagement/src/OrderSummary.web';
export interface Props extends WithStyles {
    task: Task,
    changeTaskStatus(id: string) : void;
    onSelectChange(value: string, id: string) : void,
    onMoveTaskUp(id: string) : void,
    onMoveTaskDown(id: string): void
}

const styles = createStyles({
  taskRow: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },

  formControlLabel: {
    "& .Mui-checked": {
      color: "#4FC8EC"
    },

    "& .MuiTypography-body1": {
      fontFamily: "Gotham, sans-serif",
      fontSize: "18px",
      fontWeight: 400,
      lineHeight: "18px",
      textAlign: "left",
      color: "#4B4C4B"
    },
  },
});

class CustomTask extends Component<Props,{}> {

  constructor(props: any) {
    super(props);
  }

  render() {
    const {task, classes} = this.props;
   
    return (
      <Box className={classes.taskRow}>
        <FormControlLabel
          data-test-id="form-control-label"
          className={classes.formControlLabel}
          value={task.title}
          control={
            <Checkbox
              icon={<CustomCheckboxIcon />}
              checkedIcon={<CustomCheckedCheckboxIcon />}
              data-test-id="checkbox"
              checked={task.status === 'complete'}
              onChange={() => {
                this.props.changeTaskStatus(task.id);
              }}
            />
          }
          label={
            <span
              style={{
                textDecoration:
                  task.status === 'complete' ? 'line-through' : '',
              }}
            >
              {task.title}
            </span>
          }
        />
        <Box sx={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
          <IconButton data-test-id="up-task-btn" onClick={()=>this.props.onMoveTaskUp(task.id)} >
            <ArrowUpward style={{width: '16px', height: "16px"}}/>
          </IconButton>
          <IconButton data-test-id="down-task-btn" onClick={()=>this.props.onMoveTaskDown(task.id)} >
            <ArrowDownward style={{width: '16px', height: "16px"}}/>
          </IconButton>
          <TaskMenu
            data-test-id="task-menu"
            onSelectAction={(value) => this.props.onSelectChange(value, task.id)}
          />
        </Box>
      </Box>
    );
  }
}
export default withStyles(styles)(CustomTask);