import * as React from "react";
// Customizable Area Start
import {
  Container,
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
  TextField,
  Card, CardActionArea, CardContent, CardMedia, Grid,Step, StepLabel,StepConnector, Stepper, Checkbox, FormControlLabel, InputAdornment, IconButton
} from "@material-ui/core";
import { createTheme, styled, withStyles } from "@material-ui/core/styles";
import { Doc1, Doc2 } from "./assets";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { Autocomplete } from "@material-ui/lab";
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
// Customizable Area End

import { ViewProps, configJSON } from "./OrderManagement";

const OrderManagementView: React.FC<ViewProps> = ({
  // Customizable Area Start
  testID,
  orders,
  loading,
  openCreateModal,
  isVisibleCreateModal,
  catalogueId,
  setCatalogueId,
  catalogueVariantId,
  setCatalogueVariantId,
  quantity,
  setQuantity,
  onSubmitCreateOrder,
  navigateToOrderDetail,
  openAddItemModalHandler,
  resetCreateOrderModal,
  selectedOrderId,

  agreed,
  setAgreed,
  activeStep,
  setActiveStep,
  handleBack,
  handleNext,
  handleFillMyself,
  openStepper,
  setOpenStepper,
  createDeal,
  setCreateDeal,

  fromState,
  toState,
  setFromState,
  setToState,
  handleSubmit,
  states2,
  toStateCode,
  setToStateCode,
  disable,setDisable,
  getStatesAPI,
  getColor,
  getFromStateOptions,
  getToStateOptions
  // Customizable Area End
}) => {

  // Customizable Area Start
  const steps = ['Filing Method', 'Transaction Fee', 'States', 'Form Information', 'Downloadable forms'];
  const CustomStepConnector = withStyles({
    alternativeLabel: {
      top: 22,
    },
    active: {
      '& $line': {
        borderColor: '#C5CBC9',
      },
    },
    completed: {
      '& $line': {
        borderColor: '#C5CBC9',
      },
    },
    line: {
      borderColor: '#C5CBC9',
      borderTopStyle: 'dashed',
      borderWidth: 1,
      width: '100%',
    },
  })(StepConnector);

  const CreateDealpage = () =>{
    return(
    <>
  <Box mt={1} data-testid={testID}>
    <Button>
      <ArrowBackIosIcon/>
      {configJSON.backbutton}
    </Button>
    <hr style={webStyle.StyledHr}/>
  </Box> 
  <Container style={{marginTop:'20px'}}>
   <Typography align="center" gutterBottom style={{color:'#013D4F', fontWeight:300, fontSize:'30px'}}>
     {configJSON.createdealheading}
   </Typography>
   <Typography variant="subtitle1" align="center" gutterBottom>
     {configJSON.createdealdescr}
   </Typography>
   <Grid container spacing={6} justifyContent="center">
     <Grid item xs={12} sm={6} md={4}>
       <Cardb>
         <Styledactionarea>
           <CardMedia
             component="img"
             image={Doc1}
             alt="File the Forms Myself"
             style={{borderRadius:'20px', width:'340px'}}
           />
           <CardContent>
             <Typography gutterBottom variant="h5">
               {configJSON.fillmyself}
             </Typography>
             <Typography variant="body2" >
               {configJSON.fillmyselfdescr}
             </Typography>
           </CardContent>
         </Styledactionarea>
         <Box textAlign="center" paddingBottom={2} onClick={handleFillMyself} marginTop={2.5}>
           <StyledButton className="card-button" variant="outlined" color="primary" data-testid="fillmyselfbutton">
             {configJSON.fillmyformbutton}
           </StyledButton>
         </Box>
       </Cardb>
     </Grid>
     <Grid item xs={12} sm={6} md={4}>
       <Cardb>
         <Styledactionarea>
           <CardMedia
             component="img"
             image={Doc2}
             alt="Hire a Service Provider"
             style={{ borderRadius:'20px', width:'340px'}}
           />
           <CardContent>
             <Typography gutterBottom variant="h5" component="div" className="Styledtypo">
               {configJSON.hireserviceprovider}
             </Typography>
             <Typography variant="body2" >
               {configJSON.serviceproviderdescr}
             </Typography>
           </CardContent>
         </Styledactionarea>
         <Box textAlign="center" paddingBottom={2} marginTop={2.5}>
           <StyledButton className="card-button" variant="outlined" color="primary">
             {configJSON.serviceproviderbutton}
           </StyledButton>
         </Box>
       </Cardb>
     </Grid>
   </Grid>
</Container>
</>
    )
  }

  const SelectStatepage = ()=>{

    return (
      <Container style={{ marginTop: '150px'}} data-testid="statepage">
      <Typography style={{textAlign:'center', color:'#013D4F'}} variant="h4" component="h2" gutterBottom>
        Select the Appropriate State
      </Typography>
      <Typography style={{textAlign:'center', fontSize:'18px', fontWeight:250, color:'#4B4C4B'}} gutterBottom>
        Please select the US purchased state and titling state of the vehicle
      </Typography>
      <form style={{marginTop:'50px'}} onSubmit={(event)=>{handleSubmit(event, fromState as string, toState as string)}} data-testid="formtest">
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} style={{width:'628px'}}>
            <Typography gutterBottom style={webStyle.LabelStyle}>
              From State
            </Typography>
      <Autocomplete
      value={fromState}
      style={{zIndex:30}}
      onChange={(event, newValue) => {
        setFromState(newValue);
      }}
      options={getFromStateOptions()}
      popupIcon={<KeyboardArrowDownIcon/>}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder="Select where the car was purchased"
          fullWidth
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                <InputAdornment position="end" style={{top:'0', position:'absolute',  backgroundColor:'white', right:'10'}}>
                  <IconButton edge="end">
                    <InfoOutlinedIcon style={{zIndex:50, height:'15px', backgroundColor:'white'}}/>
                  </IconButton>
                </InputAdornment>
                {params.InputProps.endAdornment}
              </>
            ),
          }}
          variant="outlined"
        />
      )}
    />
          </Grid>
          <Grid item xs={12} sm={6} style={{width:'628px'}}>
          <Typography gutterBottom style={webStyle.LabelStyle}>
              To State
            </Typography>
      <Autocomplete
      value={toState}
      style={{zIndex:30}}
      onChange={(event, newValue) => {
        setToState(newValue);
      }}
      options={getToStateOptions()}
      popupIcon={<KeyboardArrowDownIcon/>}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder="Select where the car will be registered"
          variant="outlined"
          fullWidth
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                <InputAdornment position="end" style={{position:'absolute', top:'0', backgroundColor:'white', right:'10'}}>
                  <IconButton edge="end">
                    <InfoOutlinedIcon style={{zIndex:50, backgroundColor:'white', height:'15px'}}/>
                  </IconButton>
                </InputAdornment>
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
          </Grid>
          <Grid item xs={12} sm={6} style={{textAlign:'center', margin:'auto', marginTop:'30px'}}>
            <Buttondb  type="submit" variant="contained" color="primary" fullWidth disabled={!fromState || !toState}>
              <Typography data-testid="Nextstepbutton" style={{textTransform:'none', fontWeight:700, fontSize:'16px'}}>Next Step</Typography>
            </Buttondb>
            <Typography style={{color:"#C5CBC9", marginTop:'10px', fontSize:'12px'}}>{(!fromState || !toState)? "Completion progress will be automatically saved.": "Profile progress will be automatically saved."}</Typography>
          </Grid>
        </Grid>
      </form>
    </Container>
    )
  }

  const Transactionpage = ()=>{
    return (
      <Box sx={{ width: { xs: '100%', md: '640px' }, margin: 'auto', mt: 6 }}>
          <Typography gutterBottom style={{textAlign:'center', color:'#013D4F', fontSize:'30px', fontWeight:300}}>
            {configJSON.transactionfee}
          </Typography>
          <Typography variant="body1" gutterBottom style={{textAlign:'center'}}>
            {configJSON.transactionmsg}
          </Typography>
          <Box mt={4} p={2} border={1} borderColor="#F0FAFA" borderRadius={6} padding={5}>
            <Typography variant="h6" style={{color:"#013D4F"}}>Fee Details</Typography>
            <Box style={{display:'flex', justifyContent:'space-between'}}>
            <Typography variant="body2">
                {configJSON.transactionfeepaid}
            </Typography>
            <Typography>
              <s style={{color:'#C5CBC9'}}>$60.00</s> {configJSON.amount}
            </Typography>
            </Box>
           
            <Typography variant="body2" color="textSecondary">
              {configJSON.discount} <span style={{color:'#013D4F'}}>Premium Plan</span>
            </Typography>
            <FormControlLabel
            data-testid="checkboxtest"
              control={<Checkbox style={{color: agreed ? '#4FC8EC' : '#C5CBC9'}} checked={agreed} onChange={(event) => setAgreed(event.target.checked)} />}
              label={
                <span style={{ marginTop: '30px' }}>
                  I agree to the{' '}
                  <span style={{ color: '#013D4F', textDecoration: 'underline' }}>Terms and Conditions</span>
                </span>
              }
            />
            <hr style={{ borderTop:'1px dashed #F0FAFA', borderBottom:'none'}}/>
            <Box mt={2} style={webStyle.StyledBox}>
              <BoxButton
              data-testid="paynowtest"
                variant="contained"
                style={{
                  backgroundColor: agreed ? '#4FC8EC' : '#E8F8FD',
                  color: agreed? 'white' : '#75D3F0'
              }}
                onClick={handleNext}
                disabled={!agreed}
              >
                <Typography>{configJSON.paynow}</Typography>
              </BoxButton>
              <Box textAlign="center" mt={2}>
                <Typography style={{fontSize:'12px'}}>{configJSON.totalfee}</Typography>
                <Typography variant="h6" style={{color:'#013D4F'}}>{configJSON.amount}</Typography>
              </Box>
            </Box>
            
          </Box>
          <Box mt={4} p={2} border={1} borderColor="#F0FAFA" borderRadius={6} padding={5}>
            <Typography variant="h6" style={{color:'#013D4F'}}>{configJSON.attention}</Typography>
            <Typography variant="body2">
              {configJSON.attentiondescr}
            </Typography>
          </Box>
        </Box>
    )
  }

const Steppercomponent = ()=>{
  return (
  <div>
   <Box mt={-1}>
    <BoxStyled sx={{flexDirection: { xs: 'column', md: 'row' }}}>
      <Box>
        <Button data-testid="backbuttontest" disabled={activeStep === 0} onClick={()=>{handleBack()}}>
        <ArrowBackIosIcon/>
          {configJSON.backbutton}
        </Button>
      </Box>   
      <Box sx={{ width: { xs: '100%', md: '1000px' } }}>   
      <Stepper activeStep={activeStep} connector={<CustomStepConnector />}>
          {steps.map((label, index) => (
            <Step key={label} completed={index < activeStep}>
               <StepLabel
        StepIconComponent={() => (
          <CheckCircleOutlineIcon style={{color: getColor(index)}} />
        )}
        style={{ display: 'flex', alignItems: 'center' }}
      >
            <Typography style={{color: getColor(index)}}>{label}</Typography>
            </StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>
      <Box>
        {activeStep>1 ? 
        (<Button variant="outlined" style={{marginRight:'10px', fontWeight:700 ,border:'1px solid #013D4F', borderRadius:'8px', color:'#013D4F', textTransform:'none'}}>
          Save & Next
        </Button>)
        : <Button style={{width:'113px', marginRight:'15px'}}></Button>}
      </Box>    
      </BoxStyled>
        <hr style={webStyle.StyledHr}/>
    </Box>
      {activeStep === 1 && (
        <Transactionpage/>
      )}
      {activeStep===2 && (
        <SelectStatepage />
      )}
  </div>
  )
}
  // Customizable Area End
  
  return (
    // Customizable Area Start
  <>
  {createDeal && (<CreateDealpage/>)}
  {openStepper &&(<Steppercomponent/>)}
  </>
    // Customizable Area End
  );
};

// Customizable Area Start
const Styledactionarea = styled(CardActionArea)({
  paddingLeft:'20px', 
  paddingRight:'20px', 
  paddingTop:'20px',
})

const StyledButton = styled(Button)({
  width:'350px', 
  marginLeft:'10px', 
  marginRight:'10px', 
  textTransform:'none',
  color:'#013D4F',
})

const BoxButton = styled(Button)({
  "@media (max-width:960px)": {
      width:'250px'
    },
    "@media (min-width:720px)": {
      width: '377px',
    }
})

const StyledCardMedia = styled(CardMedia)({
  borderRadius:'20px', width:'340px'
})

const BoxStyled = styled(Box)({
  display:'flex', justifyContent:'space-between', alignItems:'center',
})

const Cardb = styled(Card)(({ theme }) => ({
  borderRadius:'20px',
  '&:hover': {
    backgroundColor: '#013D4F',
    color:'white',
  },
  '&:hover .card-button': {
    backgroundColor: '#4FC8EC',
    color:'white'
  },
}));

const Buttondb = styled(Button)(({ theme }) => ({
  '&:disabled': {
    color:'#75D3F0',
    backgroundColor:'#F0FAFA', height:'50px'
  },
  '&:enabled': {
    color:'white',
    backgroundColor:'#4FC8EC', height:'50px'
  },
}));


const theme = createTheme({
  palette: {
    primary: {
      main: "#6200ee",
      contrastText: "#fff",
    },
  },
});

const webStyle = {
  mainWrapper: {
    paddingBottom: 32,
    background: "#fff",
  },
  headerContainer: {
    display: "flex",
    justifyContent: "space-between",
    paddingTop: 10,
    alignItems: "center",
  },
  orderWrapper: {
    border: "1px solid #ccc",
    borderRadius: 5,
    padding: 20,
    marginTop: 20,
  },
  orderIdWrapper: {
    display: "flex",
    justifyContent: "space-between",
  },
  orderItemWrapper: {
    display: "flex",
    border: "1px solid #ccc",
    padding: 10,
    borderRadius: 5,
    marginTop: 10
  },
  orderItemPrice: {
    fontWeight: 700,
  },
  orderItemInnerBox: {
    flex: 1,
  },
  orderStatus: {
    fontWeight: 700,
  },
  addNewOrderItemStyle: {
    marginTop: 10,
    alignSelf: "flex-end",
    marginLeft: 10,
    textTransform: "capitalize" as "capitalize",
  },
  btnGroup: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: 5,
  },
  orderNumber: {
    cursor: "pointer",
    fontWeight: 700
  },
  loadingText: {
    textAlign: "center" as "center",
    marginTop: 20,
  },
  modalTitle: {
    marginBottom: 10,
  },
  textFieldStyle: {
    marginBottom: 15,
  },
  StyledHr:{
    width:'100%',height:'1px', border:'none',color:'#F0FAFA',backgroundColor:'#F0FAFA'
  },
  StyledBox:{
    display:'flex', justifyContent:'space-between',
  },
  LabelStyle: {
    fontSize:'18px', fontWeight:300, color:'#4B4C4B'
  }
};
// Customizable Area End

export default OrderManagementView;
