import ReactQuill, { ReactQuillProps } from 'react-quill';
import React, { Component } from 'react';
import { withStyles, createStyles } from "@material-ui/core";

export interface CustomRichTextEditorProps extends ReactQuillProps{
    
}
const modules = {
  toolbar: [
    ['bold', 'underline', 'italic', { list: 'bullet' }, ''],
  ],
};

const formats = [
  'header',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'link',
  'color',
  'image',
  'background',
  'align',
];

const styles = createStyles({
  quill: {
    color: 'red'
  },
});

export class CustomRichTextEditor extends Component<CustomRichTextEditorProps>{
  constructor(props: CustomRichTextEditorProps) {
    super(props)
  }
  
  render() {
    // Customizable Area Start
    return (
      <ReactQuill
          formats={this.props.formats || formats}
          modules={this.props.modules || modules}
          {...this.props}
      />
    );
  }
}

export default withStyles(styles)(CustomRichTextEditor);