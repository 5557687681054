import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import * as Yup from 'yup';
import { FormikValues } from "formik";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    txtInputValue: string;
    txtSavedValue: string;
    enableField: boolean;
    // Customizable Area Start
    email: string | null;
    isSent: boolean;
    tokenFromBe: string | null;
    showPassword1: boolean;
    showPassword2: boolean;
    password1: string;
    password2:string;
    receivedError:string;
    resetPasswordSuccess:boolean;
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class ForgotPasswordController extends BlockComponent<
Props,
  S,
  SS
  > {
    // Customizable Area Start
    sendEmailToBeApiCallId :string = ""; 
    ResetPasswordCallId: string = ""
    // Customizable Area End

    constructor(props: Props) {
      super(props);
      this.receive = this.receive.bind(this);

      this.subScribedMessages = [
        getName(MessageEnum.AccoutLoginSuccess),
        // Customizable Area Start
        getName(MessageEnum.RestAPIResponceMessage),
        getName(MessageEnum.NavigationPayLoadMessage)
        // Customizable Area End
      ];

      this.state = {
        txtInputValue: "",
        txtSavedValue: "A",
        enableField: false,
        // Customizable Area Start
        email:'',
        isSent: false,
        tokenFromBe: '',
        showPassword1: false,
        showPassword2: false,
        password1: '',
        password2:'',
        receivedError:'',
        resetPasswordSuccess: false
        // Customizable Area End
      };
      runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

      // Customizable Area Start
      // Customizable Area End
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
              getName(MessageEnum.RestAPIResponceDataMessage)
            );
      
            const responseJson = message.getData(
              getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if(responseJson.errors && responseJson.errors[0]){ this.setState({receivedError:responseJson.errors[0].account})}
            else if(responseJson.errors){this.setState({receivedError:responseJson
              .errors[0].otp})}

            if (apiRequestCallId === this.sendEmailToBeApiCallId) {
                if (responseJson.data.message === "Reset Password link sent successfully into your email") {
                 this.setState({isSent:true})
                }
            }
            else if(apiRequestCallId===this.ResetPasswordCallId && responseJson.data.message){
              this.setState({resetPasswordSuccess: true})
            }
        }
      // Customizable Area End
    }
    // Customizable Area Start
async componentDidMount(){
    const{location:{search}} = window
    const url = new URLSearchParams(search);
    const token = url.get('token');
    const receivedemail = url.get('email');
    this.setState({ tokenFromBe:token, email: receivedemail });
}
    validationSchema = Yup.object({
        email: Yup.string().email("Invalid email address").required("Email required"),
      });

      validationSchema2 = Yup.object({
        password1: Yup.string()
          .min(8, "Invalid Password")
          .matches(/[a-z]/, "Invalid Password")
          .matches(/[A-Z]/, "Invalid Password")
          .matches(/\d/, "Invalid Password")
          .required("Required"),
        password2: Yup.string()
          .oneOf([Yup.ref('password1'), null], "Password and Confirm Password fields don't match.")
      });

    handleSubmit = (values: FormikValues) => {
     this.setState({
         email: values.email,
     },()=>this.sendEmailToBe())
     }
     
     handleSubmit2 = (values: FormikValues) => {
      this.setState({
          password1: values.password1,
          password2: values.password2
      },()=>this.resetPasswordSetup())
      }

      goTologin = ()=>{
        const msg = new Message(getName(MessageEnum.NavigationMessage));
        msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        msg.addData(getName(MessageEnum.NavigationTargetMessage), 'EmailAccountLoginBlock');
        this.send(msg);
      }
      goToTerms = ()=> {
        const messagee: Message = new Message(
          getName(MessageEnum.NavigationTermAndConditionMessage)
        );
        messagee.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(messagee);
      }
      handleNotYou = ()=>{
        this.setState({isSent:false, receivedError:'', tokenFromBe:null})
      }

     isErrorborder = (touched:boolean | undefined, error:string | undefined, color1: string, color2: string)=>{
        if(touched && error){
        return color1
        }
        return color2
       }

       
    handleShowPass = (value: string) => {
        switch (value) {
          case "password1":
            this.setState({showPassword1: !this.state.showPassword1})
            break;
          case "password2":
            this.setState({showPassword2: !this.state.showPassword2})
            break;
        }  
      }; 

      passwordCriteria = [
        { label: 'At least one uppercase character (A-Z)', test: (value:string) => /[A-Z]/.test(value) },
        { label: 'At least one numerical (0-9)', test: (value:string) => /\d/.test(value) },
        { label: 'At least one lowercase character (a-z)', test: (value:string) => /[a-z]/.test(value) },
        { label: 'Minimum 8 characters long', test: (value :string) => value && value.length >= 8 },
      ];

    sendEmailToBe = ()=>{
          const Webheader = {
            "Content-Type": configJSON.contenttype
          };
          const Webdata = {
            email: this.state.email
          };
          const WebrequestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
          );
          this.sendEmailToBeApiCallId = WebrequestMessage.messageId;
          WebrequestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.sendEmailToBeEndPoint
          );
          WebrequestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(Webheader)
          );
          WebrequestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(Webdata)
          );
          WebrequestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.sendEmailToBeMethod
          );
          runEngine.sendMessage(WebrequestMessage.id, WebrequestMessage);
          return true;
    }

    resetPasswordSetup = (): boolean => {
      const Webheader = {
        "Content-Type": configJSON.contenttype
      };
      const Webattrs = {
        token: this.state.tokenFromBe,
        new_password:this.state.password1,
        confirm_password: this.state.password2
      };
      const WebhttpBody = {
        data: Webattrs,
      };
      const WebrequestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.ResetPasswordCallId = WebrequestMessage.messageId;
      WebrequestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.ResetPasswordEndPoint
      );
      WebrequestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(Webheader)
      );
      WebrequestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(WebhttpBody)
      );
      WebrequestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.ResetPasswordMethod
      );
      runEngine.sendMessage(WebrequestMessage.id, WebrequestMessage);
      return true;
    }
    // Customizable Area End
  }
