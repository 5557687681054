// Customizable Area Start
export const backArrow = require("../assets/backArrow.png");
export const filter = require("../assets/filter.png");
export const edit = require("../assets/edit.png");
export const logo = require("../assets/logo.png");
export const emptyCheckbox = require("../assets/checkbox.svg");
export const filledCheckbox = require("../assets/filled_checkbox.svg");
export const userDesign =require("../assets/view_.png")
export const likeButton =require("../assets/button_icon.svg")
export const likeButtonChecked =require("../assets/button_icon_checked.svg")
export const dislikeButton =require("../assets/dislike_button_icon.svg")
export const dislikeButtonChecked =require("../assets/dislike_button_icon_checked.svg")
export const helpButton = require("../assets/textbutton_.svg")


// Customizable Area End
