import React from "react";
// Customizable Area Start
import { Box, ButtonBase, Drawer, Typography,} from "@material-ui/core";
import MenuRoundedIcon from '@material-ui/icons/MenuRounded';

// Customizable Area End

import HamburgerMenuController, {
  Props,
} from "./HamburgerMenuController";
import { arrowTop, car, hamburger_icon_selected, home, logo, people } from "./assets";

export default class HamburgerMenu extends HamburgerMenuController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  allDealsContent = () => {
    return (
      <ButtonBase style={{ width: '100%' }}>
                <Box style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  width: '260px', 
                  height: '45px',
                  justifyContent: 'space-between',
                  transition: 'all 0.9s ease',
                }}>
                  <Box style={{
                    display: 'flex',
                    flexDirection: this.state.isOpen ? 'row' : 'column',
                    alignItems: 'center',
                    height: '45px',
                    justifyContent: 'start',
                    transition: 'all 0.9s ease',
                    overflow: 'hidden', 
                    width: this.state.isOpen ? '228px' : '80px', 
                  }}>
                    <img src={car} style={{
                      width: '24px',
                      height: '24px',
                      marginLeft: '10px',
                      flexShrink: 0, 
                    }} />
                    <Typography style={{
                      fontSize: '14px',
                      fontWeight: 500,
                      color: '#013D4F',
                      marginLeft: '10px',
                      whiteSpace: 'nowrap', 
                    }}>All Deals</Typography>
                  </Box>
                  <Box style={{
                    width: "32px",
                    height: "32px",
                    display: this.state.isOpen ? "flex" : "none",
                    justifyContent: "center",
                    alignItems: "center",
                    flexShrink: 0, 
                  }} onClick={this.toggleExpand} data-test-id="accordion">
                    <img src={arrowTop} style={{
                      width: "24px",
                      height: "24px",
                      transform: this.state.isExpanded ? 'rotate(0deg)' : 'rotate(180deg)',
                      transition: 'transform 0.5s ease-in-out'
                    }} />
                  </Box>
                </Box>
              </ButtonBase>
    )
  }

  mainSideBarContent = () => {
    return (
      <Box style={{width:'100%', display:'flex', justifyContent:'center'}}>
              <Box style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginTop: '40px',
                width: this.state.isOpen ? '260px' : '80px',
                height: this.state.isOpen ? '205px' : '259px',
                maxHeight: '460px',
                transition: 'all 0.9s ease',
              }}>
                <ButtonBase style={{ width: '100%' }}>
                  <Box style={{
                    display: 'flex',
                    flexDirection: this.state.isOpen ? 'row' : 'column',
                    alignItems: 'center',
                    width: this.state.isOpen ? '260px' : '330px',
                    height: this.state.isOpen ? '45px' : '65px',
                    justifyContent: this.state.isOpen ? 'start' : 'center',
                    transition: 'all 0.9s ease',
                  }}>
                    <img src={home} style={{
                      width: '24px',
                      height: '24px',
                      paddingLeft: this.state.isOpen ? '10px' : '0px',
                      transition: 'padding-left 0.9s ease',
                    }} />
                    <Typography style={{
                      fontSize: '14px',
                      fontWeight: 500,
                      color: '#013D4F',
                      marginLeft: this.state.isOpen ? '10px' : '0px',
                      transition: 'margin-left 0.9s ease',
                    }}>Home</Typography>
                  </Box>
                </ButtonBase>
                {this.allDealsContent()}
                <ButtonBase style={{ width: '100%' }}>
                  <Box style={{
                    display: 'flex',
                    flexDirection: this.state.isOpen ? 'row' : 'column',
                    alignItems: 'center',
                    width: this.state.isOpen ? '260px' : '330px',
                    height: this.state.isOpen ? '45px' : '65px',
                    justifyContent: this.state.isOpen ? 'start' : 'center',
                    transition: 'all 0.9s ease',
                  }}>
                    <img src={people} style={{
                      width: '24px',
                      height: '24px',
                      paddingLeft: this.state.isOpen ? '10px' : '0px',
                      transition: 'padding-left 0.9s ease',
                    }} />
                    <Typography style={{
                      fontSize: '14px',
                      fontWeight: 500,
                      color: '#013D4F',
                      marginLeft: this.state.isOpen ? '10px' : '0px',
                      transition: 'margin-left 0.9s ease',
                    }}>My Teams</Typography>
                  </Box>
                </ButtonBase>
              </Box>
            </Box>
    )
  }

  renderHamburgerMenu = () => {
    return (
      <Box>
        <Drawer
          anchor={"left"}
          open={true}
          style={{ zIndex: 1200 }}
          onClose={this.isOpen}
          variant="persistent"
          PaperProps={{
            style: {
              width: this.state.isOpen ? '330px' : '120px',
              transition: 'width 0.9s',
              overflowX: 'hidden',
              borderRight: '1px solid #F0FAFA',
            },
          }}
        >
          <Box style={{
            width: `${this.state.isOpen ? '330px' : '120px'}`,
            height: "100%",
            display: 'flex',
            flexDirection: 'column',
            justifyContent: "flex-start",
            alignItems: "flex-start",
            transition: 'width 0.9s ease',
          }}>
            <Box style={{width:'100%', display:'flex', justifyContent:'center',}}>
              <Box style={{
                display: 'flex',
                justifyContent: 'start',
                alignItems: 'center',
                marginTop: this.state.isOpen ? '25px' : '8px',
                marginRight: this.state.isOpen ? '21px' : '0px',
                width: this.state.isOpen ? '239px' : '83.69px',
                height: this.state.isOpen ? '40px' : '53px',
                transition: 'all 0.9s ease',
              }}>
                <ButtonBase style={{ width: '40px' }} >
                  <Box onClick={this.isOpen} data-test-id="hamburgerId" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    {this.state.isOpen
                      ? <img src={hamburger_icon_selected} style={{ width: '40px', height: '40px' }} />
                      : <Box onClick={this.isOpen} style={{ width: '24px', height: '24px' }}>
                        <MenuRoundedIcon style={{ color: '#C5CBC9' }} />
                      </Box>
                    }
                  </Box>
                </ButtonBase>
                <Box>
                  <img src={logo} style={{ width: '51.69px', height: '24px' }} />
                </Box>
              </Box>
            </Box>
            {this.mainSideBarContent()}
          </Box>
        </Drawer>
      </Box>
    )
  }
  // Customizable Area End

  render() {
    return (
        // Customizable Area Start
        <Box>
          {this.renderHamburgerMenu()}
        </Box>
        // Customizable Area End
        );
  }
}

// Customizable Area Start
// Customizable Area End
