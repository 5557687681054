import React from "react";
// Customizable Area Start
import { 
    Box,
  Button,
  Divider,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  createTheme,
  styled
 } from "@material-ui/core";
 import CloseIcon from '@material-ui/icons/Close';
 import ThumbUpAltOutlinedIcon from '@material-ui/icons/ThumbUpAltOutlined';
import 'react-toastify/dist/ReactToastify.css';
import { GlobalToast } from "../../../components/src/Loader.web";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});
// Customizable Area End

import BlockController, {
  Props,
} from "./ServiceProviderNotificationController";

export default class Block extends BlockController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

acceptModel = () =>{
    return(
        <Modal
        open={this.state.isOpenAcceptModal}
        onClose={this.toggleAcceptModal}
        aria-describedby="simple-modal-description"
        aria-labelledby="simple-modal-title"
        style={{  display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    }}
    >
    <StyledModalBoxAccept >
      <Box display={'flex'} justifyContent={'end'}>
        <Button style={{marginRight:'-20px'}} onClick={this.toggleAcceptModal} data-test-id="closebtn"><CloseIcon/></Button>
      </Box>

     <Box>
      <Box><Typography style={{color:'#013D4F', fontWeight:700, fontSize:'24px',marginBottom:'12px', fontFamily:'Roboto'}}>Accept the Deal? Please verify you wish to continue</Typography></Box>
      <Typography style={{color:'#4B4C4B',fontSize:'18px',fontWeight:300, fontFamily:'Gotham light'}}>By accepting the deal, You commit to delivering the work for <br/> the dealer with the highest level of dedication and expertise.</Typography>
     </Box>

     <Divider style={{ width:'666px', marginLeft:'-33px',backgroundColor:'#E2E8F0', marginTop:'30px'}}/>
     
     <Box style={{display:'flex', marginTop:'20px',justifyContent:'end', }}>
      <Button data-test-id="cancellogout" variant="outlined" onClick={this.toggleAcceptModal} style={{height:'56px', border:'1px solid #4B4C4B', width:'120px', marginRight:'15px', borderRadius:'8px'}}>
        <Typography style={{color:'#4B4C4B',fontSize:'16px', fontWeight:700, textTransform:'none',fontFamily:'Roboto'}}>
          Cancel
        </Typography>
      </Button>
      <Button data-test-id="" variant="contained" onClick={this.toggleAcceptModal} style={{ width:'280px',height:'56px', backgroundColor:'#EAFBFA', borderRadius:'8px', boxShadow:'none'}}>
        <Typography style={{color:'#26BBB3', textTransform:'none', fontSize:'16px', fontFamily:'Roboto', fontWeight:700, }}>
          Accept
        </Typography>
      </Button>
     </Box>
    </StyledModalBoxAccept>
</Modal>
    )
}

rejecttModel = () =>{
    return(
        <Modal
        aria-describedby="simple-modal-description"
        aria-labelledby="simple-modal-title"
        open={this.state.isOpenRejectModal}
        onClose={this.toggleRejectModal}
        style={{  display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    }}
    >
    <StyledModalBoxAccept >
      <Box display={'flex'} justifyContent={'end'}>
        <Button style={{marginRight:'-20px'}} onClick={this.toggleRejectModal} data-test-id="closebtn"><CloseIcon/></Button>
      </Box>

     <Box>
      <Box><Typography style={{color:'#013D4F', fontWeight:700, fontSize:'24px',marginBottom:'12px', fontFamily:'Roboto'}}>Please confirm you want to reject this deal</Typography></Box>
      <Typography style={{color:'#4B4C4B',fontSize:'18px',fontWeight:300, fontFamily:'Gotham light'}}>You cannot alter your response once submitted. Are you certain you want to reject the deal?</Typography>
     </Box>

     <Divider style={{ width:'666px', marginLeft:'-33px',backgroundColor:'#E2E8F0', marginTop:'30px'}}/>
     
     <Box style={{display:'flex', marginTop:'20px',justifyContent:'end', }}>
      <Button data-test-id="cancellogout" variant="outlined" onClick={this.toggleRejectModal} style={{height:'56px', border:'1px solid #4B4C4B', width:'120px', marginRight:'15px', borderRadius:'8px'}}>
        <Typography style={{color:'#4B4C4B',fontSize:'16px', fontWeight:700, textTransform:'none',fontFamily:'Roboto'}}>
          Cancel
        </Typography>
      </Button>
      <Button data-test-id="finallogout" variant="contained" onClick={this.toggleRejectModal} style={{ width:'280px',height:'56px', backgroundColor:'#FEF2F2', borderRadius:'8px', boxShadow:'none'}}>
        <Typography style={{color:'#C43937', textTransform:'none', fontSize:'16px', fontFamily:'Roboto', fontWeight:700, }}>
          Reject
        </Typography>
      </Button>
     </Box>
    </StyledModalBoxAccept>
</Modal>
    )
}

  notifiactionModal = ()=>{
    return(
      <Modal
      open={this.state.isOpenNotificationModal}
      onClose={this.toggleNotificationModal}
      aria-describedby="simple-modal-description"
      aria-labelledby="simple-modal-title"
      style={{  display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
   }}
    >
      <StyledModalBox >
        <Box display={'flex'} justifyContent={'end'}>
          <Button onClick={this.toggleNotificationModal} data-test-id="closebtn" style={{ marginRight:'-20px'}}><CloseIcon/></Button>
        </Box>
  
       <Box>
        <Box><Typography style={{color:'#013D4F', fontWeight:700, fontSize:'24px',marginBottom:'12px', fontFamily:'Roboto'}}>New Request From Great Car Dealership!</Typography></Box>
        <Typography style={{color:'#4B4C4B',fontSize:'18px',fontWeight:300, fontFamily:'Gotham light'}}>
            You have an incoming deal request with the details below. You may accept, reject, or dismiss this deal. <br/>Please bear in mind that other providers may be able to accept this deal
        </Typography>
       </Box>

       <TableContainer component={Paper} style={{ marginTop: '20px', boxShadow:'none'}}>
            <Table>
              <TableHead>
                <StyledTableRowHeader>
                  <StyledTableCell>Dealer</StyledTableCell>
                  <StyledTableCell >Service</StyledTableCell>
                  <StyledTableCell >States</StyledTableCell>
                  <StyledTableCell >Vehicle Type</StyledTableCell>
                  <StyledTableCell >Application</StyledTableCell>
                  <StyledTableCell >Payout</StyledTableCell>
                  <StyledTableCell >Marketplace Fee</StyledTableCell>
                  <StyledTableCell>Dealer Rating</StyledTableCell>
                </StyledTableRowHeader>
              </TableHead>
              <TableBody>
                {this.state.tableData.map((row, index) => (
                  <StyledTableRow key={index}>
                    <StyledTableCelldata>{row.dealer}</StyledTableCelldata>
                    <StyledTableCelldata>{row.service}</StyledTableCelldata>
                    <StyledTableCelldata>{row.states}</StyledTableCelldata>
                    <StyledTableCelldata>{row.vehicleType}</StyledTableCelldata>
                    <StyledTableCelldata>{row.application}</StyledTableCelldata>
                    <StyledTableCelldata>{row.payout}</StyledTableCelldata>
                    <StyledTableCelldata style={{textAlign:'center'}}>{row.marketplaceFee}</StyledTableCelldata>
                    <TableCell style={{paddingTop: '20px'}}>
                        <Box display="flex" alignItems="center" style={{backgroundColor:'#F0FAFA',padding:'5px', borderRadius:'5px'}}>
                            <ThumbUpAltOutlinedIcon style={{ marginRight: '4px', height:'15px' }} />
                            <Typography style={{fontSize:'12px', color:'#4B4C4B', fontWeight:500}}>{row.dealerRating} Positive</Typography>
                        </Box>
                    </TableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
        </TableContainer>


    <Divider style={{backgroundColor:'#E2E8F0', width:'1066px', marginLeft:'-33px', marginTop:'50px'}}/>
       
       <Box style={{display:'flex', marginTop:'25px',justifyContent:'end'}}>
       <Button data-test-id="dismiss" variant="text" onClick={this.handleToast} style={{height:'56px',  marginRight:'15px'}}>
          <Typography style={{color:'#C5CBC9',fontSize:'16px', fontWeight:700, textTransform:'none',fontFamily:'Roboto'}}>
            Dismiss
          </Typography>
        </Button>
        <Button data-test-id="reject" variant="contained" onClick={this.toggleRejectModal} style={{height:'56px', backgroundColor:'#FEF2F2', width:'200px', marginRight:'15px', borderRadius:'8px', boxShadow:'none'}}>
          <Typography style={{color:'#C43937',fontSize:'16px', fontWeight:700, textTransform:'none',fontFamily:'Roboto'}}>
            Reject
          </Typography>
        </Button>
        <Button data-test-id="accept" variant="contained" onClick={this.toggleAcceptModal} style={{ width:'250px',height:'56px', backgroundColor:'#EAFBFA', borderRadius:'8px', boxShadow:'none'}}>
          <Typography style={{color:'#26BBB3', textTransform:'none', fontSize:'16px', fontFamily:'Roboto', fontWeight:700, }}>
            Accept
          </Typography>
        </Button>
       </Box>
      </StyledModalBox>
  </Modal>
    )
  }

  renefdrtoastBodi = ()=>{
    return(
      <>
      <style>{`
      .Toastify__toast-theme--colored.Toastify__toast--default{
        margin: 0px ;
        padding:0px; 
      }
      .Toastify__toast-body{
        min-width: 100%;
        padding:0px;
      }
      .Toastify__toast{
        padding:0px;
        border-radius:12px;
      }
    `}</style>
      <Box
        style={{
          display: 'flex',
          flexDirection: 'row',justifyContent: 'space-between', 
          borderRadius: '12px',  padding: '0px 24px',height: '65px',
          alignItems: 'center',  border: '1px solid #013D4F',color: ' #006064',
          boxShadow: '0px 16px 48px 0px rgba(1, 61, 79, 0.22)'
        }}>

        <Box style={{
          display: 'flex',
           gap: '5px', alignItems: 'center',  flexDirection: 'row',
        }}>
          <Box style={{marginLeft:'10px'}}>
              <Typography style={{  color: '#4B4C4B', fontSize: '16px', fontWeight:400, fontFamily:'Roboto'}}>
                  Deal has been dismissed, you can find it in pending deals in 
                  <span data-test-id="gotoAllDeals" className="unique" onClick={this.gotoAllDeals}style={{color:'#4FC8EC', fontWeight:700, fontSize:'14px', marginLeft: '5px'}}>
                      All Deals
                  </span>
              </Typography>
          </Box>
        </Box>
        <Box><CloseIcon style={{ color: '#013D4F' }} /></Box>
      </Box>
    </>
    )
  }
  // Customizable Area End

  render() {
    return (
        // Customizable Area Start
      <Box>
        <>
        {this.notifiactionModal()}
        {this.acceptModel()}
        {this.rejecttModel()}

        <GlobalToast isOpen={this.state.openToast} toastbody={<this.renefdrtoastBodi/>} handletoast={()=>this.toastFalse()}/>
        </>
      </Box>
        // Customizable Area End
        );
  }
}

// Customizable Area Start
const StyledTableRowHeader = styled(TableRow)({
    '&:last-child td, &:last-child th': {
      borderColor: '#F0FAFA',
    },
});

const StyledTableRow = styled(TableRow)({
    '&:last-child td, &:last-child th': {
      border: 'none',
    },
});

const StyledTableCelldata = styled(TableCell)({
    fontSize:'14px', color:'#4B4C4B', fontWeight:400, fontFamily:'Gotham',paddingTop: '20px'
})

const StyledTableCell = styled(TableCell)({
    fontSize:'14px', fontFamily:'Inter', color:'#C5CBC9', fontWeight:700, 
})
const StyledModalBox = styled(Box)({
     width: '1000px',
    border: '1px solid #FFFFFF',
    boxShadow: theme.shadows[5],
    backgroundColor:'#FFFFFF',
    padding: theme.spacing(2, 4, 3),
    borderRadius:'8px',
  })

  const StyledModalBoxAccept = styled(Box)({
    width: '600px',
   border: '1px solid #FFFFFF',
   boxShadow: theme.shadows[5],
   backgroundColor:'#FFFFFF',
   padding: theme.spacing(2, 4, 3),
   borderRadius:'8px',
 })
  
// Customizable Area End