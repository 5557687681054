import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Box, Typography } from "@material-ui/core";
import { ErrorMessage } from "formik";
import { ToastContainer, ToastOptions, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

interface myProps {
  loading: boolean;
}

const useStyles = makeStyles(() => ({
  root: {
    position: "absolute",
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgb(0, 0, 0, .4)",
    zIndex: 1,
  },
  circularContainer: {
    position: "absolute",
    left: "50%",
    top: "40%",
    transform: "translate(-50%, -50%)",
  },
}));

export default function Loader(props: myProps) {
  const classes = useStyles();
  return props.loading ? (
    <div className={classes.root}>
      <div className={classes.circularContainer}>
        <CircularProgress />
      </div>
    </div>
  ) : (
    <div />
  );
}

export function isError(touched: boolean | undefined, error:string | undefined, name: string) {
  if(touched && error){
    return(
      <Box style={{marginBottom:'20px',backgroundColor:'#FEE2E2', height:'46px', width:'380px', borderLeft:'4px solid #C43937', borderRadius:'8px'}}>
        <Typography>
        <ErrorMessage name={name} component="div" style={{marginLeft:'10px',fontSize:'14px', color: "#DC2626", paddingTop:'15px', fontWeight:500 }} /> 
        </Typography>
      </Box>
    )}
}

const neee : ToastOptions<{}> = {
  position: "top-center",
  hideProgressBar: false,
  closeOnClick: true,
  autoClose: 5000,
  draggable: true,
  pauseOnHover: true,
  theme: "colored",
}

export function GlobalToast({toastbody, isOpen, handletoast}:{toastbody : React.ReactNode, isOpen:boolean, handletoast:any}){
  const notify = ()=>{
    toast(toastbody, neee)
    handletoast(false)
  }
  return(
    <>
      {isOpen && notify()}
      <ToastContainer style={{ width: '600px' }} />
    </>
  )
}