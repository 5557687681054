import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {getName} from "../../../framework/src/Messages/MessageEnum";
import { getStorageData , removeStorageData } from "../../../framework/src/Utilities";

// Customizable Area Start
interface ResponseJSON {
    data: {
      id: string;
      type: string;
      attributes: {
        activated: boolean;
        country_code: string;
        email: string;
        full_phone_number: string;
        phone_number: string;
        type: string;
        full_name: string;
        created_at: string;
        updated_at: string;
        is_dealer_group: boolean;
        dealer_group_name: string | null;
        role: 'dealer' | 'dealer_admin' | 'service_provider' | 'service_provider_admin';
        profile_picture: string | null;
        group_created_on: string | null;
        is_dealership_pending?: boolean;
        is_provider_pending?: boolean;
        dealership_data?: any;
        solo_dealership_id: number | null;
        is_any_dealership: boolean;
        solo_dealership_data?: {
          data: {
            id: string;
            type: string;
            attributes: {
              dealership_name: string | undefined;
              business_credentials: string;
              deal_type: string[];
              created_at: string;
              updated_at: string;
              dealership_users: number;
              addresses: {
                data: Array<{
                  id: string;
                  type: string;
                  attributes: {
                    address: string;
                    address_type: string;
                    country: string;
                    city: string;
                    state: string;
                    zipcode: string;
                    zipcode_ext: string;
                  };
                }>;
              };
              bank_information: {
                data: any[];
              };
              dealership_logo: string | null;
              w9_forms: any[];
            };
          };
        };
        provider_information?: {
          data: {
            id: string;
            type: string;
            attributes: {
              business_name: string | null;
              business_credentials: string | null;
              deal_type: string[];
              created_at: string;
              updated_at: string;
              business_address: {
                address_type: string | null;
                address: string | null;
                city: string | null;
                state: string | null;
                zipcode: string | null;
                zipcode_ext: string | null;
              };
              shipping_address: {
                address_type: string | null;
                address: string | null;
                city: string | null;
                state: string | null;
                zipcode: string | null;
                zipcode_ext: string | null;
              };
              serviceable_locations: {
                data: any[];
              };
              contact_info: {
                name: string;
                email: string;
                phone_number: string;
              };
              banking_info: {
                data: {
                  id: string;
                  type: string;
                  attributes: {
                    bank_name: string;
                    account_number: string;
                    bank_routing_number: string;
                    branch_name: string;
                    name: string;
                    phone_number: string;
                    job_title: string;
                    created_at: string;
                    updated_at: string;
                    agree_e_sign: boolean;
                    authirization_signature: string | null;
                    voided_check: string;
                    full_signature: string;
                    initial_signature: string;
                    address: {
                      data: {
                        id: string;
                        type: string;
                        attributes: {
                          address: string;
                          address_type: string;
                          country: string | null;
                          city: string;
                          state: string;
                          zipcode: string;
                          zipcode_ext: string;
                        };
                      };
                    };
                  };
                };
              };
              service_provider_logo: string | null;
              w9_forms: any[];
            };
          };
        };
        assigned_dealership_ids: any | null;
      };
    };
  }
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
navigation: any;
id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    anchorEl: null | HTMLElement;
    profilePhoto:string | null |undefined;
    initials:string | null;
    fullName:string;
    isOpenLogoutModal:boolean;
    role:string;
    description:string | undefined;
    // Customizable Area End
}

interface SS {
id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class ProfileDropdownController extends BlockComponent<
Props,
  S,
  SS
  > {
    // Customizable Area Start
    getContactInfoCallId: string = "";
    testCallId : string = "";
    // Customizable Area End

    constructor(props: Props) {
      super(props);
      this.receive = this.receive.bind(this);

      this.subScribedMessages = [
        getName(MessageEnum.AccoutLoginSuccess),
        // Customizable Area Start
        getName(MessageEnum.RestAPIResponceMessage),
        // Customizable Area End
      ];

      this.state = {
        // Customizable Area Start
        anchorEl: null,
        profilePhoto:'',
        initials:'',
        fullName:'',
        isOpenLogoutModal:false,
        role : '',
        description: '',
        // Customizable Area End
      };
      runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

      // Customizable Area Start
      // Customizable Area End
    }

    async receive(from: string, message: Message) {
      // Customizable Area Start
      if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
        const responseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        const apiCallId = message.getData(
          getName(MessageEnum.RestAPIResponceDataMessage)
        )

      if (apiCallId === this.getContactInfoCallId)
        {
            console.log("111", responseJson);
            this.setState({
                fullName: responseJson.data.attributes.full_name,
                profilePhoto : responseJson.data.attributes.profile_picture,
                role : responseJson.data.attributes.role,
            }, () => this.getInitials())
            this.handleResponseJson(responseJson)
        }
        this.handleFunc(apiCallId);
    }
      // Customizable Area End
    }

    // Customizable Area Start

    async componentDidMount() {
        super.componentDidMount();
        this.getContactInfo()
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined): void { 
        console.log(this.state.fullName, "fullname")
    }

    handleResponseJson = (responseJson : ResponseJSON) => {
        if (responseJson.data.attributes.role === 'dealer' || responseJson.data.attributes.role === 'dealer_admin')
            {
                this.ifCondFunc(responseJson);
            }
        if (responseJson.data.attributes.role === 'service_provider' || responseJson.data.attributes.role === 'service_provider_admin') 
            {
                this.elseCondFunc(responseJson)
            }
    }

    ifCondFunc = (responseJson : ResponseJSON) => {
        if (responseJson.data.attributes.is_dealer_group && responseJson.data.attributes.dealer_group_name) {
            this.setState({
                description: responseJson.data.attributes.dealer_group_name,
            })
        }
        else if (!responseJson.data.attributes.is_dealer_group && responseJson.data.attributes?.solo_dealership_data?.data.attributes?.dealership_name) {
            this.setState({
                description: responseJson.data.attributes.solo_dealership_data?.data.attributes?.dealership_name,
            })
        }
    }

    elseCondFunc = (responseJson: ResponseJSON) => {
        if (responseJson.data.attributes.is_dealer_group && responseJson.data.attributes.dealer_group_name) {
            this.setState({
                description: responseJson.data.attributes.dealer_group_name,
            })
        }
        else if (!responseJson.data.attributes.is_dealer_group && responseJson.data.attributes.provider_information?.data.attributes.business_name) {
            this.setState({
                description: responseJson.data.attributes.solo_dealership_data?.data.attributes.dealership_name,
            })
        }
    }

    handleFunc = (apiCallId : string) => {
        if (apiCallId === this.testCallId)
            {
                this.handleCloseMenu();
            }
    }

    handleCloseMenu=()=>{
        this.setState({anchorEl:null})
      }

      gotoEditProfile=()=>{
        const routetogo = new Message(getName(MessageEnum.NavigationMessage));
        routetogo.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        routetogo.addData(getName(MessageEnum.NavigationTargetMessage), 'UserProfileBasicBlockWeb')
        this.send(routetogo);
      }

      toggleLogoutModal=()=>{
        this.setState({isOpenLogoutModal: !this.state.isOpenLogoutModal})
      }

      handleOpenMenu=( event: React.MouseEvent<HTMLDivElement, MouseEvent>)=>{
        this.setState({anchorEl:event.currentTarget})
      }

      gotoLogout=()=>{
        removeStorageData('authToken')
        const newmsgg = new Message(getName(MessageEnum.NavigationMessage));
        newmsgg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        newmsgg.addData(getName(MessageEnum.NavigationTargetMessage), 'EmailAccountLoginBlock')
        this.send(newmsgg);
      }

      gotoContactUs=()=>{
        const routetogo = new Message(getName(MessageEnum.NavigationMessage));
        routetogo.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        routetogo.addData(getName(MessageEnum.NavigationTargetMessage), 'Contactus')
        this.send(routetogo);
      }

      getInitials = ()=>{
        if ( this.state.fullName ) {
          const nameArray = this.state.fullName.split(' ');
          const derivedInitials = nameArray.map(name=>name.charAt(0)).join('').slice(0, 2).toUpperCase();
          this.setState({initials:derivedInitials})
        } else {
          this.setState({initials:null})
        }
      }

      getContactInfo = async () => {
        let tokenn = await getStorageData("authToken");
        const Webheader = {"token" : tokenn};
        const WebrequestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.getContactInfoCallId = WebrequestMessage.messageId;
        WebrequestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.getContactInfoAPIEndpoint);
        WebrequestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(Webheader));
        WebrequestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.httpGetMethod);
        runEngine.sendMessage(
          WebrequestMessage.id, 
          WebrequestMessage
        );
      }
    // Customizable Area End
  }