import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  // Customizable Area Start
  screenId: string;
  classes?: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isEditing: boolean;
  feeEditingIndex: number;
  fees: Fee[];
  oldTotalPrice: any;
  reasonChange: string;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  screenId: string;
  // Customizable Area End
}

// Customizable Area Start
export class Fee {
  constructor(
    public name: string,
    public changeReason: string,
    public currentPrice: string,
    public oldPrice: any
  ) {
    this.name = name;
    this.changeReason = changeReason;
    this.currentPrice = currentPrice;
    this.oldPrice = oldPrice;
  }
}
// Customizable Area End

export default class OrderSummaryRegistrationFeeController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];
    // Customizable Area End

    this.state = {
      // Customizable Area Start
      isEditing: false,
      fees: [
        new Fee("County Surtax (1%)", "This is the reason 1", "50.00", null),
        new Fee("Sales & Use Tax (6%)", "", "1830.00", null),
        new Fee("Additional Statutory Fees", "", "10.06", null),
        new Fee("Reg Service Charge", "", "3.00", null),
        new Fee("Plate Registration Fee", "", "32.05", null),
        new Fee("New Metal Plate Fee", "", "28.00", null),
        new Fee("Initial Registration Fee", "This is the reason 2", "225.00", null),
        new Fee("Title Fee", "", "77.03", null)
      ],
      oldTotalPrice: null,
      feeEditingIndex: -1,
      reasonChange: ""
      // Customizable Area End
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    this.handleFeeSave = this.handleFeeSave.bind(this);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start

    // Customizable Area End
  }
  // Customizable Area Start
  handleChangeEditingStatus = () => {
    this.setState({
      isEditing: !this.state.isEditing
    });
  };

  handleSelectFeeChange = (index: number) => {
    this.setState({
      feeEditingIndex: index
    });
    const updatedFees = [...this.state.fees];
    updatedFees[index].oldPrice = updatedFees[index].currentPrice;
    this.setState({
      fees: updatedFees,
      oldTotalPrice: this.getCurrentPrice().toFixed(2)
    });
  };

  handleFeeInputChange = (event: any, index: number) => {
    const { value } = event.target;
    const regex = /^\d*(\.\d{0,2})?$/;

    if (regex.test(value)) {
      const updatedFees = [...this.state.fees];
      updatedFees[index].currentPrice = value;

      this.setState({ fees: updatedFees });
    }
  };

  handleFeeSave(event: any, index: number) {
    if (event.key === "Enter") {
      this.setState({
        feeEditingIndex: -1
      });
      const updatedFees = [...this.state.fees];
      updatedFees[index].currentPrice = parseFloat(
        updatedFees[index].currentPrice
      ).toFixed(2);
      updatedFees[index].changeReason = this.state.reasonChange;
      this.setState({ fees: updatedFees });
    }
  }

  handleInputReason = (event: any) => {
    this.setState({
      reasonChange: event.target.value
    });
  };

  handleChangeReason = (event: any, index: number) => {
    if (event.key === "Enter") {
      let fees = this.state.fees;
      fees[index].changeReason = event.target.value;
      this.setState({
        fees: fees,
        feeEditingIndex: -1
      });
    }
  };

  navigateToDealDetails = () => {
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msg.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "ProjectTemplatesDealDashboard"
    );
    this.send(msg);
  };

  getCurrentPrice = () => {
    let totalPrice = 0;
    this.state.fees.forEach(fee => {
      totalPrice += +fee.currentPrice;
    });
    return totalPrice;
  };

  cardPanelInfos = {
    "DEAL ID": "328493",
    "CUSTOMER'S LAST NAME": "Smith",
    STATES: "CA" + " > " + "NY",
    APPLICATION: "Registration"
  };

  // Customizable Area End
}
