import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {getName} from "../../../framework/src/Messages/MessageEnum";
import { getStorageData } from "../../../framework/src/Utilities";

// Customizable Area Start
interface ResponseJSON {
    data: {
      id: string;
      type: string;
      attributes: {
        activated: boolean;
        country_code: string;
        email: string;
        full_phone_number: string;
        phone_number: string;
        type: string;
        full_name: string;
        created_at: string;
        updated_at: string;
        is_dealer_group: boolean;
        dealer_group_name: string | null;
        role: 'dealer' | 'dealer_admin' | 'service_provider' | 'service_provider_admin';
        profile_picture: string | null;
        group_created_on: string | null;
        is_dealership_pending?: boolean;
        is_provider_pending?: boolean;
        dealership_data?: any;
        solo_dealership_id: number | null;
        is_any_dealership: boolean;
        solo_dealership_data?: {
          data: {
            id: string;
            type: string;
            attributes: {
              dealership_name: string | undefined;
              business_credentials: string;
              deal_type: string[];
              created_at: string;
              updated_at: string;
              dealership_users: number;
              addresses: {
                data: Array<{
                  id: string;
                  type: string;
                  attributes: {
                    address: string;
                    address_type: string;
                    country: string;
                    city: string;
                    state: string;
                    zipcode: string;
                    zipcode_ext: string;
                  };
                }>;
              };
              bank_information: {
                data: any[];
              };
              dealership_logo: string | null;
              w9_forms: any[];
            };
          };
        };
        provider_information?: {
          data: {
            id: string;
            type: string;
            attributes: {
              business_name: string | null;
              business_credentials: string | null;
              deal_type: string[];
              created_at: string;
              updated_at: string;
              business_address: {
                address_type: string | null;
                address: string | null;
                city: string | null;
                state: string | null;
                zipcode: string | null;
                zipcode_ext: string | null;
              };
              shipping_address: {
                address_type: string | null;
                address: string | null;
                city: string | null;
                state: string | null;
                zipcode: string | null;
                zipcode_ext: string | null;
              };
              serviceable_locations: {
                data: any[];
              };
              contact_info: {
                name: string;
                email: string;
                phone_number: string;
              };
              banking_info: {
                data: {
                  id: string;
                  type: string;
                  attributes: {
                    bank_name: string;
                    account_number: string;
                    bank_routing_number: string;
                    branch_name: string;
                    name: string;
                    phone_number: string;
                    job_title: string;
                    created_at: string;
                    updated_at: string;
                    agree_e_sign: boolean;
                    authirization_signature: string | null;
                    voided_check: string;
                    full_signature: string;
                    initial_signature: string;
                    address: {
                      data: {
                        id: string;
                        type: string;
                        attributes: {
                          address: string;
                          address_type: string;
                          country: string | null;
                          city: string;
                          state: string;
                          zipcode: string;
                          zipcode_ext: string;
                        };
                      };
                    };
                  };
                };
              };
              service_provider_logo: string | null;
              w9_forms: any[];
            };
          };
        };
        assigned_dealership_ids: any | null;
      };
    };
  }
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
navigation: any;
id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    anchorEl: null | HTMLElement;
    profilePhoto:string | null |undefined;
    initials:string | null;
    fullName:string;
    isOpenLogoutModal:boolean;
    role:string;
    description:string | undefined;
    isOpen: boolean,
    isExpanded : boolean,
    // Customizable Area End
}

interface SS {
id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class HamburgerMenuController extends BlockComponent<
Props,
  S,
  SS
  > {
    // Customizable Area Start
    getContactInfoCallId: string = "";
    testCallId : string = "";
    // Customizable Area End

    constructor(props: Props) {
      super(props);
      this.receive = this.receive.bind(this);

      this.subScribedMessages = [
        getName(MessageEnum.AccoutLoginSuccess),
        // Customizable Area Start
        getName(MessageEnum.RestAPIResponceMessage),
        // Customizable Area End
      ];

      this.state = {
        // Customizable Area Start
        anchorEl: null,
        profilePhoto:'',
        initials:'',
        fullName:'',
        isOpenLogoutModal:false,
        role : '',
        description: '',
        isOpen : false,
        isExpanded : false,
        // Customizable Area End
      };
      runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

      // Customizable Area Start
      // Customizable Area End
    }

    async receive(from: string, message: Message) {
      // Customizable Area Start
      // Customizable Area End
    }

    // Customizable Area Start

    async componentDidMount() {
        super.componentDidMount();
        this.getContactInfo()
    }

    isOpen = () => {
      this.setState({
        isOpen : !this.state.isOpen,
      })
    }

    toggleExpand = () => {
      this.setState(prevState => ({isExpanded : !prevState.isExpanded}))
    }

      getContactInfo = async () => {
        let tokenn = await getStorageData("authToken");
        const Webheader = {"token" : tokenn};
        const WebrequestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.getContactInfoCallId = WebrequestMessage.messageId;
        WebrequestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.getContactInfoAPIEndpoint);
        WebrequestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(Webheader));
        WebrequestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.httpGetMethod);
        runEngine.sendMessage(
          WebrequestMessage.id, 
          WebrequestMessage
        );
      }
    // Customizable Area End
  }