import React from "react";
// Customizable Area Start
import OrderSummaryBlankFormFeeController, {
  Props,
} from "./OrderSummaryBlankFormFeeController";
import { createStyles, withStyles } from "@material-ui/core";
import OrderSummary from "./OrderSummary.web";

const styles = createStyles({
  container: {},
});
// Customizable Area End

export class OrderSummaryBlankFormFee extends OrderSummaryBlankFormFeeController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End
  render() {
    // Customizable Area Start
    return(
      <OrderSummary id="" navigation={this.props.navigation} formType="blankForm"/>
    )
    // Customizable Area End
  }
}

export default withStyles(styles)(OrderSummaryBlankFormFee);