import React from "react";

// Customizable Area Start
import MenuRoundedIcon from '@material-ui/icons/MenuRounded';
import {dislikeButton, dislikeButtonChecked, helpButton, likeButton, likeButtonChecked, logo } from "./assets";
import {notifications} from "../../../blocks/dashboard/src/assets";
import { Avatar, Box, Button, FormControlLabel, Radio, RadioGroup, Typography, styled } from "@material-ui/core";


 // Merge Engine - import assets - Start
// Merge Engine - import assets - End

// Merge Engine - Artboard Dimension  - Start
// Merge Engine - Artboard Dimension  - End

interface Styles {
  [key: string]: any;
}
 
 
// Customizable Area End

import MultilevelTemplateController, {
  Props,
} from "./MultilevelTemplateController";



export default class MultilevelTemplate extends MultilevelTemplateController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  headerr = () => {
    return (
      <Box sx={webStylesHeader.header}>
        <Box sx={webStylesHeader.headerLeftSide}>
          <Button data-test-id="menu" style={webStylesHeader.menuButton}>
            <MenuRoundedIcon style={{ color: '#C5CBC9' }} />
          </Button>
 
          <Box sx={webStylesHeader.logo} style={{ marginLeft: '-30px' }}>
            <img style={{ marginBottom: '5px', height: '24px', width: '52px' }} src={logo} alt="vitu logo" />
          </Box>
        </Box>
 
        <Box sx={webStylesHeader.headerRightSide}>
          <Box
            data-test-id="notifications"
            sx={webStylesHeader.notificationsButton}
          >
            <img src={notifications} alt="notifications button" />
          </Box>
 
          <Box style={webStylesHeader.userAvatar}>
            <Avatar
              className="avatarBack"
              variant="square"
              src={ '' }
              style={{
                width: "112%",
                height: "102%",
                borderRadius: "24px",
                backgroundColor: 'rgb(240, 250, 250)',
                color: '#013D4F',
                fontSize:"20px"
              }}
            >
              {this.state.initials}
            </Avatar>
          </Box>
        </Box>
      </Box>
    )
  }

  renderIssueReceived = () => {
    return (
      <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%' }}>
        <Box style={{ margin:"55px 0",
          width:'901px',
          display:"flex",
          height:'700px',
          flexDirection:"column",
          justifyContent:"space-between",
          alignItems:'space-between',
          position:"relative",
          
        }}>
          <Box style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", height: '104px', padding: '0 20px', boxSizing: 'border-box', overflow: 'hidden' }}>
            <Typography className="gothamLightText" style={{ fontWeight: 300, fontSize: "30px", color: "#013D4F" }}>Issue Received </Typography>
            <Typography className="gothamText" style={{ marginLeft: '-14px', marginRight: '-14px', fontWeight: 400, fontSize: "18px", color: "#4B4C4B", textAlign: 'center' }}>We received an issue from your dealer. Please address this issue for the Dealer and then resend to the Dealer for confirmation of the resolution.</Typography>
          </Box>
          <Box style={{display:'flex', flexDirection:'column', justifyContent:'space-around', alignItems:'center', width:'901px', height:'573px', border: '1px solid #F0FAFA', borderRadius: '10px'}}>
            <Box style={{width:'757px', height:'86px', display:'flex', flexDirection:'column', justifyContent:'space-around', alignItems:'flex-start', borderRadius:'10px', padding:'35px 24px 35px 24px', boxShadow: '0px 0px 4px 0px #8989895C'
            }}>
              <Typography style={{fontSize:'16px', fontWeight:300, color:'#4B4C4B'}}>Issue Raised by:</Typography>
              <Box style={{display:'flex', width:'350px', justifyContent:'space-around', alignItems:'center'}}>
                <Avatar
                  className="avatarBack"
                  variant="square"
                  src={''}
                  style={{
                    width: "40px",
                    height: "40px",
                    borderRadius: "24px",
                    backgroundColor: '#346472',
                    color: '#FFFFFF',
                    fontSize: "18px",
                    fontWeight:600
                  }}
                >
                  GC
                </Avatar>
                <Typography className="gothamText" style={{fontSize:'28px', fontWeight:400, color:'#013D4F'}}>Great Car Dealership</Typography>
              </Box>
            </Box>
            <Box style={{height:'169px', width:'805px', display:'flex', flexDirection:'column', justifyContent:'space-between', alignItems:'center'}}>
              <Box style={{height:'56px', width:'805px', borderRadius:'8px', backgroundColor:'#ECF9F9', display:'flex', alignItems:'center'}}>
                <Typography style={{fontWeight:400, fontSize:'16px', color:'#4B4C4B', marginLeft:'25px' }}>The car is not registered yet.</Typography>
              </Box>
              <Box style={{height:'98px', width:'805px', borderRadius:'8px', backgroundColor:'#ECF9F9', display:'flex', alignItems:'center'}}>
                <Typography style={{color:'#4B4C4B', fontWeight:400, fontSize:'16px', marginLeft:'25px'}}>Checked with the DMV and found that the car is not yet registered because of missing info in the “Certificate of Title.” Please check and fix.</Typography>
              </Box>
            </Box>
            <Box>
              <Button onClick={this.navigateToDealDashboard} data-test-id="navigateToDealDashboardButtonTestId" style={{ borderRadius: '8px', width: '346px', height: '56px', backgroundColor:'#4FC8EC' }}><Typography style={{ fontSize: '14px', fontWeight: 700, color:'#FFFFFF' }}>Back to Deal Dashboard</Typography></Button>
            </Box>
          </Box>
        </Box>
      </Box>
    )
  }

  renderReviewSpSide = () => {
    return (
      <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%' }}>
        <Box style={{
          margin: "55px 0",
          height: '634px',
          width: '703px',
          display: "flex",
          position: "relative",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: 'space-between',
        }}>
          <Box style={{width: "703px", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", height: '104px', padding: '0 10px', boxSizing: 'border-box', overflow: 'hidden' }}>
            <Typography className="gothamLightText" style={{ fontWeight: 300, fontSize: "36px", color: "#013D4F" }}>Deal completed successfully </Typography>
            <Typography className="gothamText" style={{ marginLeft: '-14px', marginRight: '-14px', fontWeight: 400, fontSize: "18px", color: "#4B4C4B", textAlign: 'center' }}>Thank you for choosing Vitu marketplace! Your feedback is important to us, and we appreciate hearing about your experience with the dealer.</Typography>
          </Box>
          <Box style={{ width: '703px', height: '144px', border: '1px solid #F0FAFA', borderRadius: '24px', display:'flex', justifyContent: 'space-around', alignItems: 'center' }}>
            <Box style={{ width: '381px', height: '48px', display: 'flex' }}>
              <Typography style={{ fontWeight: 400, fontSize: '18px' }}>A total amount of <span style={{ fontWeight: 700, fontSize: '18px', color: '#013D4F' }}>$2010.13</span> will be released to your bank account ending in: xxxxx5121</Typography>
            </Box>
            <Box data-test-id="helpButton" onClick={this.navigateToContactUs}>
            <img src={helpButton} />
            </Box>
          </Box>
          <Box style={{ display: 'flex', flexDirection: 'column', border: '1px solid #F0FAFA', borderRadius: '24px', width: '701px', height: '322px', justifyContent: 'space-around', alignItems: 'center' }}>
            <Box>
              <Typography style={{ color: '#013D4F', fontWeight: 400, fontSize: '18px' }}>How was your experience with Great Car?  </Typography>
            </Box>
            <Box style={{marginBottom:'20px'}}>
              <RadioGroup
                aria-label="dealer-group"
                name="dealerGroup"
                data-test-id="dealerGroup"
                className="radioClass"
                value={this.state.likeButton.toString()}
                onChange={this.handleRadioChange}
                row={true}
                style={{ color: "black", width: '126px', display: 'flex', justifyContent: 'space-between' }}
              >
                <StyledFormControlLabel
                  value="false"
                  data-test-id="radiotest"
                  control={
                    <CustomRadio
                      disableRipple
                      color="default"
                      checkedIcon={<img src={likeButtonChecked} />}
                      icon={<img src={likeButton} />}
                      {...this.props}
                    />} label="Good" />
                <StyledFormControlLabel
                  value="true"
                  control={<CustomRadio
                    disableRipple
                    color="default"
                    checkedIcon={<img src={dislikeButtonChecked} />}
                    icon={<img src={dislikeButton} />}
                    {...this.props}
                  />} label="Bad" />
              </RadioGroup>
            </Box>
            <Box style={{ display: 'flex', width: '549px', justifyContent: 'space-between', alignItems: 'center', height: '56px' }}>
              <Box style={{ width: '100%' }}>
                <Button style={{ border: '1px solid #4C4D4C', borderRadius: '8px', width: '162px', height: '56px' }}><Typography style={{ fontSize: '16px', fontWeight: 700, color: '#4C4D4C' }}>Maybe Later</Typography></Button>
              </Box>
              <Box>
                <Button disabled={this.state.likeButton.toString().length == 0} data-test-id="submitReviewButtonTestId" style={{ borderRadius: '8px', width: '371px', height: '56px', backgroundColor: `${this.state.likeButton.toString().length != 0 ? '#4FC8EC' : '#E8F8FD'}` }}><Typography style={{ fontSize: '16px', fontWeight: 700, color: `${this.state.likeButton.toString().length != 0 ? '#FFFFFF' : '#A3E2F5'}`}}>Submit Review</Typography></Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    )
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    // Merge Engine - render - Start

    return (
      <Box>
        <Box style={{position:'absolute', display:"none"}}>
        </Box>
        <CustomisableUserProfilesStyle>
          {
            this.headerr()
          }
          <Button data-test-id="toggleScreenButton" style={{border:'1px solid #4C4D4C', width:'128.75px', height:'38.5px'}} onClick={this.toggleScreen}>{this.state.toggleNoti ? "Review SP" : "Issue Received"}</Button>
          {!this.state.toggleNoti && this.renderIssueReceived()}
          {this.state.toggleNoti && this.renderReviewSpSide()}
        </CustomisableUserProfilesStyle>
      </Box>
    );
    // Merge Engine - render - End
    // Customizable Area End
  }
}

// Customizable Area Start

const CustomisableUserProfilesStyle = styled(Box)({
  padding: "24px",
  boxSizing: "border-box",
  "& .dealerInfoForm": {
    padding: "0px 20px",
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderRadius: '8px',
    },
  },
  "& .header": {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems:"center"
  },
  "& .logo": {
    width: "54px",
    height: "24px",
  },
  "& .MuiAutocomplete-root": {
    "& .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root']": {
      paddingRight: "6px", 
      height:"56px",
      "& .MuiAutocomplete-input": {
        padding: "0px", 
      },
      "& .MuiInputAdornment-root": {
        marginLeft: "9px", 
      },
      '& .MuiInputAdornment-positionEnd': {
        marginRight: '9px',
      },
      '& + .MuiAutocomplete-popper .MuiAutocomplete-option:hover': {
        backgroundColor: "#013D4F",
      },
      "& .MuiAutocomplete-popper": {
        "& .MuiAutocomplete-listbox": {
          "& .MuiAutocomplete-option": {
            "&[data-focus='true'], &:hover": {
              backgroundColor: "##013D4F",
            },
            "&[aria-selected='true']": {
              backgroundColor: "#013D4F",
            },
          },
        },
      },
    },
  },
  "& .logout, .skip": {
    width: "98px",
    height: "44px",
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "24px",
    textTransform: "none",
    color: "#4B4C4B",
    border: "1px solid #C5CBC9",
    borderRadius: "8px",
  },
  "& .w9FormButton": {
    marginTop:"20px",
    height: "44px",
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "24px",
    textTransform: "none",
    color: "#4B4C4B",
    border: "1px solid #C5CBC9",
    borderRadius: "8px",
  },
  "& .collapsibleContent": {
    transition:"max-height 0.3s ease-in-out",
  },
  "& .heading": {
    width:"100%",
    fontWeight: 600,
  },
  "& .title": {
    fontSize: "30px",
    lineHeight: "40px",
    color: "#013D4F",
    fontWeight: 300
  },
  "& .subtitle": {
    fontSize: "18px",
    lineHeight: "18px",
    color: "#4B4C4B",
    fontWeight: 300,
    marginTop:"10px",
    zIndex: 40,
    position:'relative'
  },
  "& .headingForm": {
    fontFamily: "Gotham, sans-serif",
    fontWeight: 300,
  },
  "& .titleForm": {
    fontSize: "24px",
    lineHeight: "32px",
    letterSpacing: "-0.005em",
    color: "#013D4F",
    fontWeight: 300
  },
  '& .Mui-focused': {
    '& .MuiOutlinedInput-notchedOutline': {
    borderColor: 'black',
    }
  },
  '& label.Mui-focused': {
    color: '#4B4C4B',
    fontWeight:500
  },
  '& .MuiInputLabel-root': {
    paddingLeft: '6px',
    color: '#C5CBC9',
  },
  '& label.MuiInputLabel-shrink': {
    marginLeft: 0,
    paddingLeft: 0,
    color: '#4B4C4B',
    fontWeight:500
  },
  "& .radioStyle": {
    width:"20px", 
    height:"20px"
  },
  "& .dealerGroupBox": {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    padding: "20px 20px",
    border: "2px solid #F0FAFA",
    borderRadius : "16px"
  },
  "& .dealerGroupText": {
    lineHeight: "32px",
    letterSpacing: "-0.005em",
    color: "#013D4F",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    gap: "2px",
    fontSize: "20px",
    fontWeight: 500,
    marginBottom: "20px",
  },
  "& .custom-placeholder": {
     paddingLeft:"10px",
     fontSize:"16px",
     fontWeight:400,
    },
  "& .MuiFormGroup-root": {
    display: "inline",
  },
  "& .MuiFormControlLabel-label": {
    marginTop:"3.5px"
  },
  "& .dealershipDetailsBox": {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    padding: "20px",
    border: "2px solid #F0FAFA",
    borderRadius : "16px",
    height:"auto"
  },
  "& .dealershipDetailsInnerBox": {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    borderRadius : "16px",
    height:"auto",
  },
  "& .dealerGrouptText": {
    color:"#013D4F",
    fontWeight:400,
    marginBottom:"5px",
    fontSize:"16px",
    lineHeight:"24px"
  },
  "& .SubtitleForm":{
      color:"#4B4C4B",
      fontWeight:500,
      lineHeight:"22px",
      fontSize:"14px"
  },
  "& .subtitleForm": {
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "22px",
    color: "#4B4C4B",
  },
  "& .mainContent": {
    margin: "55px 0",
    height:'856px',
    width:'901px',
    display: "flex",
    position: "relative",
    flexDirection: "column",
    justifyContent:"space-between",
    alignItems: 'space-between',
  },
  "& .steps": {
    display:"flex",
    flexDirection:"column",
    marginRight: "24px",
    marginTop:"26px",
    padding:"20px 0px",
    width:"250px"
  },
  "& .stepContent": {
    flex: "3",
    marginTop:"24px",
    width:"400px",
    padding:"20px 0px",
  },
  "& .stepItem": {
    marginBottom: "24px",
    cursor: "pointer",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
    gap: "16px",
    color: "#C5CBC9",
  },
  "& .stepItem.active .stepTitle": {
    color: "#013D4F",
    "& .MuiTypography-body1": {
      color:"#013D4F"
    }
  },
  "& .stepItem.done .stepTitle": {
    color: "#26BBB3",
    "& .MuiTypography-body1": {
      color:"#26BBB3"
    }
  },
  "& .stepTitle": {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
    gap: "2px",
    fontSize: "20px",
    fontWeight: 500,
    lineHeight: "28px",
  },
  "& .stepSubtitle": {
    fontSize: "12px",
    fontWeight: 500,
    lineHeight: "18px",
  },
  "& .stepText": {
    textAlign: "end",
  },
  "& .stepNumber": {
    width: "48px",
    height: "48px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "24px",
    borderRadius:'50%',
    backgroundColor:"rgba(240, 250, 250, 0.78)"
  },
  "& .stepItem.skipped .stepTitle": {
    color: "#F05E22",
    "& .MuiTypography-body1": {
      color:"#F05E22"
    }
  },
  "& .stepItem.clickable": {
    cursor: "pointer",
  },
  "& .stepItem:not(.clickable)": {
    cursor: "not-allowed",
  },
  "& .pac-container.pac-logo": {
    width: "800px !important",
    backgroundColor: "red !important", 
    padding: "10px !important",
    fontSize: "16px",
    boxShadow: "0 2px 6px rgba(0, 0, 0, 0.3)", 
    border: "1px solid #ccc", 
    borderRadius: "4px", 
  },
  "& .pac-item": {
    padding: "10px",
    fontSize: "14px",
    cursor: "pointer",
  },
  "& .pac-item:hover": {
    backgroundColor: "red",
  },
  "& .pac-item-query": {
    fontSize: "14px",
    color: "#red",
  },
  "& .pac-matched": {
    fontWeight: "bold",
  },
  "& .pac-icon": {
    width: "20px",
    height: "20px",
    marginRight: "10px",
  },
  "& .headerForm": {
    marginBottom: "32px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  "& .photoForm": {
    maxWidth: "640px",
    height: "321px",
    backgroundSize: "100% 191px",
    border: "2px solid #F0FAFA",
    borderRadius: "30px",
  },
  "& .photoFormContent": {
    padding: "48px",
    marginTop: "81px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "16px",
  },
  "& .profilePhoto": {
    width: "120px",
    height: "120px",
    borderRadius: "30px",
  },
  "& .updatePhoto": {
    display: "flex",
    flexDirection: "row",
    gap: "12px",
  },
  "& .uploadPhoto": {
    display: "flex",
    flexDirection: "column",
    gap: "12px",
  },
  "& .upload, .delete": {
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
    textTransform: "none",
    borderRadius: "8px",
  },
  "& .upload, .uploadLogo": {
    color: "#013D4F",
    border: "1px solid #013D4F",
  },
  "& .delete": {
    border: "1px solid #C43937",
    color: "#C43937",
  },
  '& .MuiFormHelperText-root.Mui-error': {
    color: '#C43937',
  },
  "& .uploadLogo": {
    position: "absolute",
    top: "12px",
    right: "24px",
    height: "32px",
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "22px",
    textTransform: "none",
    borderRadius: "8px",
  },
  "& .hints": {
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "10px",
    color: "#C5CBC9",
  },
  "& .nextStep": {
    marginTop: "24px",
    textAlign: "center",
  },
  "& .nextStep button": {
    width: "89%",
    height: "56px",
    marginBottom: "8px",
    backgroundColor: "#4FC8EC",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
    color: "#FFFFFF",
    textTransform: "none",
    borderRadius:"8px"
  },
  "& .nextStep p": {
    fontSize: "12px",
    fontWeight: 500,
    lineHeight: "18px",
    color: "#4B4C4B",
  },
  "& .form": {
    display: "flex",
    flexDirection: "column",
    gap: "24px",
  },
  "& .formControl": {
    position: "relative",
    height: "56px",
    padding: "0 24px 10px 24px",
    border: "1px solid #ccc",
    borderRadius: "4px",
  },
  "& .Mui-checked": {
    color:"#4FC8EC",
  },
  "& .info": {
    position: "absolute",
    top: "-35px",
    right: "0",
  },
  "& .infoTextFirst": {
    position: "absolute",
    top: "53px",
    right: "24px",
  },
  "& .infoTextVoidedCheck": {
    position: "absolute",
    top: "0px",
    right: "4px",
  },
  "& .infoTextLast": {
    position:"absolute",
    right:"2px",
    top: "0px",
  },
  '& .MuiInputBase-input': {
    '&::placeholder': {
      color: '#C5CBC9',
      opacity: 1,
    },
  },
  "& .infoText": {
    position: "absolute",
    top: "-23px",
    right: "2px",
  },
  "& .infoTextStep3": {
    position: "absolute",
    top: "72px",
    right: "26px",
  },
  "& .infoTextBankAddress": {
    position:"absolute",
    top:"0px",
    right:"2.5px",
  },
  "& .same": {
    marginTop: "-14px",
    marginLeft: "7px",
    fontWeight:500,
  },
  "& .nextStep button:disabled": {
    color: "#75D3F0",
    backgroundColor: "#E8F8FD",
  },
  "& .MuiAutocomplete-groupUl": {
    backgroundColor:"red"
  },
}
);

const webStylesHeader : Styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    width: "100%",
  },
 
  header: {
    display: "flex",
    flexWrap: "nowrap",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0px 0px",
  },
 
  headerLeftSide: {
    display: "flex",
    flexWrap: "nowrap",
    alignItems: "center",
    gap: "0px",
  },
 
  menuButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    backgroundColor: "transparent",
    width: "24px",
    height: "24px",
    cursor: "pointer",
    padding: "unset",
    border: "none",
  },
 
  logo: {
    width: "52px",
  },
 
  headerRightSide: {
    display: "flex",
    flexWrap: "nowrap",
    alignItems: "center",
    gap: "24px",
  },
 
 
  notificationsButton: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "24px",
    height: "24px",
    cursor: "pointer",
  },
 
  userAvatar: {
    width: "40px",
    height: "40px",
    borderRadius: "50%",
  },
 
  main: {
    display: "flex",
    flexWrap: "nowrap",
    position: "relative",
  },
 
 
  buttonText: {
    fontFamily: "Roboto, sans-serif",
    color: "#013D4F",
    fontSize: "14px",
    fontWeight: 500,
  },
 
  content: {
    width: "100%",
    marginLeft: "120px",
  },
 
}

const StyledFormControlLabel = styled(FormControlLabel)({
  margin: 0,
  flexDirection: 'column',
  '& .MuiFormControlLabel-label': {
    marginTop: '16px',
  },
});

const CustomRadio = styled(Radio)({
  padding: 0,
});
// Customizable Area End