// Customizable Area Start
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { IBlock } from "../../../framework/src/IBlock"; // Assuming this is where IBlock is located

export const configJSON = require("./config");

export enum DashboardView {
  HOME = "HOME",
  ALL_DEALS = "ALL_DEALS",
  MY_TEAM = "MY_TEAM"
}

export interface Props {
  navigation: any;
  id: string;
}

interface S {
  apiToken: string | null;
  anchorEl: HTMLElement | null;
  openModal: boolean;
  open: boolean;
  CreateDealStatus: any;
  loading: boolean;
  errorMsg: string;
  userData: { [key: string]: any } | undefined;
  currentView: DashboardView;
}

interface SS {
  id: any;
}

export default class CreateDealMainController
  extends BlockComponent<Props, S, SS>
  implements IBlock
{
  apiUserDataCallId = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.state = {
      currentView: DashboardView.ALL_DEALS,
      apiToken: null,
      anchorEl: null,
      openModal: false,
      open: false,
      CreateDealStatus: "",
      loading: false,
      errorMsg: "",
      userData: undefined,
    };

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getUserData();
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const webApiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let webErrorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      let webResponseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (!webResponseJson.errors && webResponseJson) {
        if (this.apiUserDataCallId === webApiRequestCallId) {
          const userData = webResponseJson.data;
          const { attributes } = userData;

          this.setState({
            errorMsg: "",
            loading: false,
            userData: {
              ...userData,
              attributes: {
                soloDealershipId: attributes.solo_dealership_id,
                dealData: attributes.deal_data,
                isDealPending: attributes.is_deal_pending,
                groupCreatedOn: attributes.group_created_on,
                profilePicture: attributes.profile_picture,
                role: attributes.role,
                dealerGroupName: attributes.dealer_group_name,
                isDealerGroup: attributes.is_dealer_group,
                updatedAt: attributes.updated_at,
                createdAt: attributes.created_at,
                fullName: attributes.full_name,
                type: attributes.type,
                phoneNumber: attributes.phone_number,
                fullPhoneNumber: attributes.full_phone_number,
                email: attributes.email,
                countryCode: attributes.country_code,
                activated: attributes.activated,
              },
            },
          });
        }
      } else if (webResponseJson.errors && webResponseJson) {
        if (this.apiUserDataCallId === webApiRequestCallId) {
          this.setState({
            loading: false,
            errorMsg: webErrorReponse,
          });
        }
      }
    }
  }

  handleClick = (event: any) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  navigateToDashboard = (view: string) => {
    const msgs = new Message(getName(MessageEnum.NavigationMessage));
      msgs.addData(getName(MessageEnum.NavigationTargetMessage), "Dashboard");
      msgs.addData(
        getName(MessageEnum.NavigationPropsMessage),
        this.props
      );
      const raiseMessage: Message = new Message(
        getName(MessageEnum.NavigationPayLoadMessage)
      );
      raiseMessage.addData(getName(MessageEnum.NavigationPayLoadMessage), {view: view});
      msgs.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
      runEngine.sendMessage("MergeEngineUtilities", msgs);
  };
  
  setCurrentView = (view: DashboardView) => {
    this.setState({
      currentView: view
    })
  }

  getStyle = (condition: boolean, whenTrue: string | number, whenFalse: string | number) => {
    return condition ? whenTrue : whenFalse;
  }

  navigateToMyteam = ()=>{
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msg.addData(getName(MessageEnum.NavigationTargetMessage), 'Dashboard');
    this.send(msg);
  }

  getUserData(): boolean {
    this.setState({ loading: true });

    const webHeader = {
      "Content-Type": configJSON.createDealContentType,
      token: localStorage.getItem("authToken"),
    };
    const webRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiUserDataCallId = webRequestMessage.messageId;
    webRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getUserUrl
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(webHeader)
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getDealInformationMethod
    );
    runEngine.sendMessage(webRequestMessage.id, webRequestMessage);
    return true;
  }
}
// Customizable Area End
