export const getCurrentDate = () => {
  const currentDate = new Date();

  let day = currentDate.getDate();
  let month = currentDate.getMonth() + 1; // January is 0, so we add 1
  let year = currentDate.getFullYear();

  if (day < 10) {
    day = '0' + day;
  }
  if (month < 10) {
    month = '0' + month;
  }

  const formattedDate = day + '.' + month + '.' + year;

  return formattedDate;
}

export const getCurrentTime = () => {
  const currentDate = new Date();
  let hours = currentDate.getHours();
  let minutes = currentDate.getMinutes();
  let ampm = hours >= 12 ? 'PM' : 'AM';

  hours = hours % 12;
  hours = hours ? hours : 12;

  minutes = minutes < 10 ? '0' + minutes : minutes;

  const formattedTime = hours + ':' + minutes + ampm;

  return formattedTime;
}