import React from "react";
// Customizable Area Start
import ServiceProviderDashboardController, { Props } from "./ServiceProviderDashboardController";
import ExpandMore from '@material-ui/icons/ExpandMore';
import CloseIcon from '@material-ui/icons/Close';
import { Box, Grid, Button, Typography, Card, CardContent, IconButton, Select, MenuItem, TextField, Drawer } from '@material-ui/core';
import { styled } from "@material-ui/core/styles";
import SearchIcon from '@material-ui/icons/Search';
import FormControl from "@material-ui/core/FormControl";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ThumbUpAltOutlinedIcon from '@material-ui/icons/ThumbUpAltOutlined';
import ThumbDownOutlinedIcon from '@material-ui/icons/ThumbDownOutlined';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import Avatar from '@material-ui/core/Avatar';
import ListItemText from "@material-ui/core/ListItemText";
import Checkbox from '@material-ui/core/Checkbox';
export const configJSON = require("./config");
// Customizable Area End


export default class ServiceProviderDashboard extends ServiceProviderDashboardController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    renderBlockContent = (item: any, index: any) => {
        return (
          <Box
            key={index}
            width="30%"
            p={2}
            className="serviceSelectChildCard"
            style={{
              padding: '16px',
              borderRadius: '16px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              position: 'relative',
            }}
          >
            <Box
              style={{
                position: 'absolute',
                left: '-15px',
                color: '#0D4659',
                width: '24px',
                height: '24px',
                borderRadius: '50%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                fontWeight: 'bold',
                border: '2px dashed #F0FAFA',
                backgroundColor: 'white'
              }}
            >
              {index + 1}
            </Box>
            <Box style={{ width: '100%' }}>
              <Typography onClick={this.closeDrawerHandler} variant="body2" color="textSecondary" style={{ gap: '10px', display: "flex" }}>
                <span className="reviewsDiv" style={{ fontSize: "16px", border: 'none', fontWeight: "400", padding: "0px", marginLeft: "0px" }}>{item.heading}</span>
                <span className="ratingDiv">
                  <ThumbUpAltOutlinedIcon /> 86% Positive
                </span>
              </Typography>
              <Box style={{ display: "flex", justifyContent: "space-between" }}>
                <Typography className="childCardHeading">{item.serviceName}</Typography>
                <Box style={{ display: 'flex', gap: '10px' }}>
                  <Typography className="childCardHeading">{item.servicePrice}</Typography>
                  <Button
                    variant="outlined"
                    fullWidth
                    className="removeButton"
                    onClick={() => this.removeSelectedCardsFromServiceBlock(index, item)}
                  >
                    Remove
                    <span style={{ marginLeft: '5px' }}><CloseIcon /> </span>
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        );
      }

      renderEmptyBlock = (index: any) => {
        return (
          <Box
            key={index}
            width="30%"
            p={2}
            className="serviceNotSelectChildCard"
          >
            <Typography>No Service Selected</Typography>
          </Box>
        );
      }

      renderBlocks = () => {
        const { selectedCardService } = this.state;
        return Array.from({ length: 3 }, (_, i) => 
          i < selectedCardService.length 
            ? this.renderBlockContent(selectedCardService[i], i) 
            : this.renderEmptyBlock(i)
        );
      };

      cardRender = () => {
        return (
            <Grid container spacing={3}>
                    {this.state.data.map((provider, index) => (
                            <Grid item xs={12} sm={6} md={4} key={index}>
                                <Card variant="outlined" className="cardMainDiv" style={{ border: this.state.selectedService ? "1px solid #013D4F" : "1px solid #F0FAFA" }}>
                                    <CardContent key={index}>
                                        <Typography onClick={this.closeDrawerHandler} variant="body2" style={{ display: "flex", }}>
                                            <span className="ratingDiv"><ThumbUpAltOutlinedIcon />86%</span>
                                            <span className="reviewsDiv">10000+</span>
                                        </Typography>
                                        <Typography className="cardHeading">{provider.name}</Typography>
                                        {
                                            configJSON.cardsServiceOption.map((item: any, buttonIndex: number) => (
                                                
                                                <Box mt={2} className="selectServiceCard" alignItems="center">
                                                    <Typography variant="body2" className="cardChildDiv">{item.type}</Typography>
                                                    <Box style={{ border: "1px dashed #F2F3F2" }}></Box>
                                                    <Box className="cardChildDiv" style={{ justifyContent: "flex-end" }}>
                                                        <Typography variant="body2">{item.price || "NA"}</Typography>
                                                        {
                                                            this.state.selectedCardIndex.includes(index) && provider.buttonIndex === buttonIndex ?
                                                                <Box className="showButton">
                                                                    <Button
                                                                        variant="outlined"
                                                                        fullWidth
                                                                        className="selectedButton"
                                                                    >
                                                                        Selected
                                                                        <span style={{ marginLeft: '5px' }}><CheckCircleOutlineIcon /> </span>
                                                                    </Button>
                                                                    <Button
                                                                        variant="outlined"
                                                                        fullWidth
                                                                        className="removeButton"
                                                                        onClick={() => this.removeSelectedServiceHandler({
                                                                            index: index, buttonIndex: buttonIndex, heading: item.name, serviceName: 'Runner Service', servicePrice: item.price
                                                                        })}
                                                                    >
                                                                        Remove
                                                                        <span style={{ marginLeft: '5px' }}><CloseIcon /> </span>
                                                                    </Button>
                                                                </Box>
                                                                :
                                                                <Button
                                                                    variant="outlined"
                                                                    onClick={() => this.selectServiceHandler({
                                                                        index: index, buttonIndex: buttonIndex, heading: item.name, serviceName: 'Runner Service', servicePrice: item.price
                                                                    })}
                                                                    fullWidth
                                                                    className={!item.price || (this.state.selectedCardIndex.includes(index) && provider.buttonIndex && provider.buttonIndex !== buttonIndex) ? "diasbleSelectButton" : "selectButton"}
                                                                    disabled={this.state.selectedCardIndex.length >= 3 || !item.price || (this.state.selectedCardIndex.includes(index) && provider.buttonIndex && provider.buttonIndex !== buttonIndex)}
                                                                >
                                                                    Select
                                                                </Button>
                                                        }
                                                    </Box>
                                                </Box>
                                            ))
                                        }
                                    </CardContent>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
        )
      }
    
    render() {
        // Customizable Area Start
        return (
            <Box>
                <Drawer anchor={"right"} open={this.state.closeDrawer} style={{ zIndex: 1200 }}>
                    <Box style={{ padding: '72px', width: '768px' }}>
                        <Box>
                            <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                <Box style={webStyles.reviewSectionHeading}>
                                    LockSolutions Expert
                                </Box>
                                <span onClick={this.closeDrawerHandler}><CloseIcon style={{ width: '32px', height: "32px" }} /></span>
                            </Box>
                            <Typography style={webStyles.reviewSectionPara}>The following review shared by Dealers who have worked with this Provider</Typography>
                        </Box>
                        <Typography variant="body2" style={{ display: "flex", paddingTop: '72px' }}>
                            <span style={webStyles.ratingDiv}><ThumbUpAltOutlinedIcon />750 <ThumbDownOutlinedIcon />450</span>
                            <span style={webStyles.reviewsDiv}>1000+ Reviews</span>
                        </Typography>
                        <Box style={{ border: "1px solid #F0FAFA", margin: "30px 0px" }}></Box>
                        <Box>
                            <Box style={{ display: 'flex', gap: "16px" }}>
                                <Box>
                                    <Avatar variant="square" style={{ borderRadius: '12px', width: '56px', height: '56px' }}>
                                        N
                                    </Avatar>
                                </Box>
                                <Box>
                                    <Box style={{ display: 'flex', alignItems: 'center', paddingBottom: '10px', gap: '10px' }}><span style={webStyles.reviewerNameStyle}>Kiss Dorka</span><span style={webStyles.ratingDiv}><ThumbUpAltOutlinedIcon /></span></Box>
                                    <Box style={webStyles.reviewDateStyle}>Mar 25, 2024</Box>
                                </Box>
                            </Box>
                            <Typography style={webStyles.reviewCommentPara}>
                                Impressive results! Their Dealership went above and beyond to ensure my satisfaction. I couldn't be happier with the outcome.
                            </Typography>
                        </Box>
                    </Box>
                </Drawer>
                <div style={{ padding: '16px' }}>
                    <Grid container alignItems="center">
                        <Grid item>
                            <IconButton edge="start">
                                <ArrowBackIosIcon />
                            </IconButton>
                        </Grid>
                        <Grid item>
                            <Typography variant="body1" style={{ color: "#4B4C4B", fontWeight: "700", fontFamily: "Roboto", fontSize: "16px" }}>
                                Back
                            </Typography>
                        </Grid>
                    </Grid>
                </div>
                <Box className="borderDiv" style={{ border: "1px solid #F0FAFA" }}></Box>
                <ServiceProviderDashboardStyle>
                    <Box className="serviceDashboardHeader">
                        <Box className="headerChildDiv">
                            <Box className="hadingText">{this.state.selectedCardService.length > 0 && this.state.selectedCardService.length < 3 && `${this.state.selectedCardService.length} Select Service Provider` || this.state.selectedCardService.length === 3 ? `All 3 Service Selected.` : "Select Service Provider"}</Box>
                            <Box className="paraText">{this.state.selectedCardService.length ? `Select up to 3 options, but choose only one service per provider.` : `Please choose the service provider(s) you would like to work with from the list below.`}</Box>
                        </Box>
                        <Button className={`${this.state.selectedCardService.length > 0 ? 'selectedNextButton' : 'nextButton'}`}>Next Step</Button>
                    </Box>
                    <Box className="borderDiv"></Box>
                    <Box mt={4} mb={4} style={{ display: "flex", justifyContent: "space-between", gap: "26px" }}>
                        {this.renderBlocks()}
                    </Box>
                    <Box className="borderDiv" style={{ marginBottom: "0px" }}></Box>
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <Typography variant="subtitle1">
                            <span style={{ fontFamily: "Roboto", fontWeight: "400", fontSize: "14px", color: "#4B4C4B" }}> 250 Service Providers</span> <span style={{ fontFamily: "Roboto", fontWeight: "500", fontSize: "14px", color: "#4B4C4B" }}>(Select up to 3 options, but choose only one service per provider.)</span>
                        </Typography>
                        <div>
                            <Grid container alignItems="center" spacing={2}>
                                <Grid item>
                                    <TextField
                                        variant="outlined"
                                        placeholder="Start typing to search..."
                                        InputProps={{
                                            startAdornment: <SearchIcon />,
                                        }}
                                        className="searchInput"
                                        onChange={(e) => {}}
                                    />
                                </Grid>
                                <div>
                                    <FormControl style={dropdownCheckbox.formControl}>
                                        <Select
                                            labelId="demo-mutiple-checkbox-label"
                                            id="demo-mutiple-checkbox"
                                            IconComponent={ExpandMore}
                                            multiple
                                            value={this.state.dropdownData}
                                            renderValue={(selected: any) =>
                                                selected.map((key: any) => {
                                                    const selectedItem = configJSON.cardsServiceOption.find((item: any) => item.key === key);
                                                    return selectedItem ? selectedItem.type : '';
                                                })
                                                    .join(', ')
                                            }
                                            MenuProps={MenuProps}
                                        >
                                            {configJSON.cardsServiceOption.map((name: any) => (
                                                <MenuItem key={name.key} value={name.key}>
                                                    <Box
                                                        display="flex" justifyContent="space-between" alignItems="center" width="100%">
                                                        <ListItemText primary={name.type} />
                                                        <Checkbox
                                                            checked={this.state.dropdownData.indexOf(name.key) > -1} />
                                                    </Box>
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </div>
                                <FormControl >
                                    <Select
                                        labelId="demo-simple-select-helper-label"
                                        id="demo-simple-select-helper"
                                        displayEmpty={false}
                                        defaultValue={"None"}
                                        value={"None"}
                                        IconComponent={ExpandMore}
                                        onChange={(e) => {}}
                                        variant="outlined"
                                        className="selectInput"
                                    >
                                        <MenuItem
                                            style={dropdownCheckbox.dropdown}
                                            value="A-Z">A to Z</MenuItem>
                                        <MenuItem value="Z-A">Z To A</MenuItem>
                                        <MenuItem value="highly rated">Highly Rated</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        </div>
                    </div>
                    <Box className="borderDiv" style={{ marginTop: "0px" }}></Box>
                    {this.cardRender()}
                </ServiceProviderDashboardStyle>
            </Box>
        );
        // Customizable Area End
    }
}

// Customizable Area Start
const ServiceProviderDashboardStyle = styled(Box)(() => ({
    padding: '0 10%',
    paddingTop: '80px',
    backgroundColor: '#FFFFFF',
    '& .selectedItems': {
        backgroundColor: 'red'
    },
    "& .serviceDashboardHeader": {
        display: 'flex'
    },
    "& .ratingDiv": {
        backgroundColor: "#F0FAFA",
        borderRadius: "5px",
        padding: "5px 10px",
        fontFamily: "Roboto",
        fontWeight: "500",
        fontSize: "12px",
        color: "#4B4C4B",
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: "5px"
    },
    '& .reviewsDiv': {
        padding: "5px 10px",
        borderBottom: "1px dashed #4B4C4B",
        fontFamily: "Roboto",
        fontWeight: "500",
        fontSize: "12px",
        color: "#4B4C4B",
        marginLeft: "5px",
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    "& .hadingText": {
        fontFamily: 'Gotham',
        fontWeight: 300,
        fontSize: '30px',
        lineHeight: '40px',
        color: '#013D4F',
        paddingBottom: '10px'
    },
    "& .paraText": {
        fontFamily: 'Gotham',
        fontWeight: 300,
        fontSize: '18px',
        lineHeight: '27px',
        color: '#4B4C4B'
    },
    "& .nextButton": {
        background: "#E8F8FD",
        color: "#75D3F0",
        fontFamily: "Roboto",
        fontWeight: 700,
        fontSize: "16px",
        lineHeight: "24px",
        width: "200px",
        height: "56px",
        borderRadius: "8px"
    },
    "& .selectedNextButton": {
        background: "#4FC8EC",
        color: "#FFFFFF",
        fontFamily: "Roboto",
        fontWeight: 700,
        fontSize: "16px",
        lineHeight: "24px",
        width: "200px",
        height: "56px",
        borderRadius: "8px"
    },
    "& .selectServiceCard": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: 'center'
    },
    "& .headerChildDiv": {
        width: "100%"
    },
    "& .borderDiv": {
        border: '1px solid #F0FAFA',
        margin: "30px 0px"
    },
    '& .removeButton': {
        width: "auto",
        border: "1px solid #C43937",
        borderRadius: "8px",
        color: "#FFFFFF",
        fontWeight: "700",
        fontFamily: "Roboto",
        backgroundColor: "#C43937",
        cursor: "pointer",
        display: 'none',
        padding: '6px 4px 6px 8px',
        '& .MuiButton-label': {
            display: 'flex'
        }
    },
    '& .showButton:hover .selectedButton': {
        display: 'none'
    },
    '& .showButton:hover .removeButton': {
        display: 'block'
    },
    "& .serviceSelectChildCard": {
        border: "2px dashed #F0FAFA",
        padding: "20px",
        borderRadius: "12px",
        color: "#C5CBC9",
        fontSize: "18px",
        fontFamily: "Roboto",
        fontWeight: 400,
        display: "flex",
        width: "100%"
    },
    "& .serviceSelectChildCard:hover": {
        border: "2px solid #013D4F",
        padding: "20px",
        borderRadius: "12px",
        color: "#C5CBC9",
        fontSize: "18px",
        backgroundColor: "#013D4F",
        fontFamily: "Roboto",
        fontWeight: 400,
        display: "flex",
        width: "100%",
        '& .reviewsDiv': {
            color: "#FFFFFF"
        },
        '& .ratingDiv': {
            color: "#013D4F",
            backgroundColor: "#FFFFFF"
        },
        '& .cardHeading': {
            color: "#FFFFFF"
        },
        '& .childCardHeading': {
            color: "#FFFFFF"
        },
        '& .removeButton': {
            display: 'block'
        }
    },
    "& .serviceNotSelectChildCard": {
        border: "2px dashed #F0FAFA",
        padding: "40px",
        borderRadius: "12px",
        color: "#C5CBC9",
        fontSize: "18px",
        fontFamily: "Roboto",
        fontWeight: 400,
        display: "flex",
        width: "100%",
        justifyContent: "center"
    },
    "& .headerOptionDiv": {
        padding: "15px 0px",
        borderTop: "1px solid black",
        borderBottom: "1px solid black",
        fontSize: "14px",
        fontFamily: "Roboto",
        color: "#4B4C4B",
        lineHeight: "22px",
        margin: "15px 0px"
    },
    "& .cardChildDiv": {
        display: "flex",
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: "24px",
        fontFamily: "Roboto",
        color: "#4B4C4B",
        alignItems: "center",
        gap: "10px"
    },
    "& .serviceProviderCard": {
        border: '1px solid black',
        padding: '32px',
        borderRadius: "12px",
        width: "410px"
    },
    "& .serviceProviderCardDiv": {
        display: "flex",
        justifyContent: "space-between"
    },
    "& .cardHeading": {
        fontWeight: 500,
        fontSize: "20px",
        lineHeight: "32px",
        fontFamily: "Roboto",
        letterSpacing: "-0.5%",
        color: "#4B4C4B",
        marginTop: "15px"
    },
    '& .childCardHeading': {
        fontWeight: 500,
        lineHeight: "32px",
        fontFamily: "Roboto",
        letterSpacing: "-0.5%",
        fontSize: "18px",
        color: "#013D4F"
    },
    "& .selectButton": {
        width: "auto",
        border: "1px solid #013D4F",
        borderRadius: "8px",
        color: "#013D4F",
        fontWeight: "700",
        fontFamily: "Roboto",
        cursor: "pointer"
    },
    "& .selectButton:hover": {
        backgroundColor: "#4FC8EC",
        border: '1px solid #4FC8EC !important'
    },
    "& .selectedButton": {
        width: "auto",
        border: "1px solid #26BBB3",
        borderRadius: "8px",
        color: "#FFFFFF",
        fontWeight: "700",
        fontFamily: "Roboto",
        backgroundColor: "#26BBB3",
        cursor: "pointer",
        display: 'block',
        padding: '6px 4px 6px 8px',
        '& .MuiButton-label': {
            display: 'flex'
        }
    },
    "& .diasbleSelectButton": {
        width: "auto",
        border: "1px solid #DEE4E2",
        borderRadius: "8px",
        color: "#DEE4E2",
        fontWeight: "700",
        fontFamily: "Roboto"
    },
    "& .searchInput": {
        "& .MuiInputBase-root": {
            "& .MuiOutlinedInput-notchedOutline": {
                border: "none"
            },
            "& .MuiSvgIcon-root": {
                fontSize: "16px",
                marginRight: "5px"
            },
            "& .MuiInputBase-input": {
                color: "#C5CBC9"
            }
        }
    },
    "& .selectInput": {
        "& .MuiOutlinedInput-notchedOutline": {
            border: "none"
        }
    },

    "& .arrowIcon": {
        fontSize: "16px"
    },
    "& .cardMainDiv": {
        borderRadius: "12px",
    },
    '& .cardMainDiv:hover': {
        backgroundColor: "#013D4F",
        '& .selectButton': {
            border: "1px solid #DEE4E2",
            color: '#FFFFFF'
        },
        '& .cardChildDiv': {
            color: "#FFFFFF"
        },
        '& .ratingDiv': {
            backgroundColor: "#FFFFFF",
            color: "#013D4F"
        },
        '& .reviewsDiv': {
            color: "#FFFFFF",
            borderBottom: "1px dashed #FFFFFF"
        },
        '& .cardHeading': {
            color: "#FFFFFF"
        },
        '& .diasbleSelectButton': {
            border: '1px solid #F0FAFA',
            color: "#F0FAFA"
        },
        '& .serviceName': {
            color: 'white !important'
        },
    }
}))

const webStyles = {
    reviewsDiv: {
        padding: "5px 10px",
        borderBottom: "1px dashed #4B4C4B",
        fontFamily: "Roboto",
        fontWeight: "500",
        fontSize: "12px",
        color: "#4B4C4B",
        marginLeft: "5px",
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    ratingDiv: {
        backgroundColor: "#F0FAFA",
        borderRadius: "5px",
        padding: "5px 10px",
        fontFamily: "Roboto",
        fontWeight: "500",
        fontSize: "12px",
        color: "#4B4C4B",
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: "5px"
    },
    reviewSectionHeading: {
        color: "#013D4F",
        fontFamily: "Gotham",
        Weight: '300',
        fontSize: '24px',
    },
    reviewerNameStyle: {
        color: "#4B4C4B",
        fontFamily: "Roboto",
        Weight: '600',
        fontSize: '18px'
    },
    reviewDateStyle: {
        color: "#C5CBC9",
        fontFamily: "Roboto",
        Weight: '500',
        fontSize: '14px'
    },
    reviewCommentPara: {
        color: "#4B4C4B",
        fontFamily: "Roboto",
        Weight: '400',
        fontSize: '16px',
        paddingTop: '20px'
    },
    reviewSectionPara: {
        color: "#4B4C4B",
        fontFamily: "Roboto",
        Weight: '500',
        fontSize: '14px',
        paddingTop: '10px'
    },
    serviceItem: {
        '&:hover': {
            backgroundColor: '#F0FAFA',
            cursor: 'pointer',
        },
    }
}

const dropdownCheckbox = {
    formControl: {
        minWidth: 120,
        maxWidth: 300,
    },
    dropdown: {
        margin: "10px",
        backgroundColor: "#F0FAFA"
    },
}

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: "224px",
            width: "300px",
            overflow: 'auto',
        },
    },
};
// Customizable Area End