import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { WithStyles } from "@material-ui/core";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { spAvatar } from "./assets";

export const configJSON = require("./config");
interface Vehicle {
  vehicleVin: string,
  vehicleType: string,
  vehicleYear: string,
  vehicleWeightEmpty: string,
  vehicleWeightGross: string,
  make: string,
  odometer: string,
  bodyType: string,
}

export interface ServiceProvider{
  id: string,
  type: string,
  selectedService: string,
  createdAt: string,
  updatedAt: string,
  serviceProviderName: string | undefined,
  serviceProviderLogo: string | undefined,
  hiredOn: string,
}
interface User{
  id: string;
  avatar: string;
}

interface DealInfo {
  dealId: string,
  fromState: string,
  toState: string,
  applicationType: string,
  registrationType: string,
  vehicle: Vehicle,
  saleType: string,
  soldAs: string,
  msrp: string,
  tsp: string,
  sellerZip: string,
  purchaserZip: string,
  ownerFirstName: string,
  ownerLastname: string,
  firstOwner: string,
  secondOwner: string,
}
// Customizable Area End

export interface Props extends WithStyles {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  selectedStatus: string,
  registrationFee: string,
  isRegistrationFeeSaved: boolean,
  dealInfo: DealInfo,
  user: User,
  sidebar: boolean,
  hiredSPInfo: ServiceProvider,
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class ProjectTemplatesDealDashboardController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiGetDealInformationId: string = '';
  apiGetUserInformationId: string = '';
  apiGetAcceptedSP: string = '';

  handleSaveRegistrationFee = () =>{
    this.setState({
      isRegistrationFeeSaved: !this.state.isRegistrationFeeSaved
    })
  }

  handleChangeSelectedStatus = (newStatus: string)=> {
    this.setState({selectedStatus: newStatus});
  }

  navigateToScreen = (screen: string) => {
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msg.addData(getName(MessageEnum.NavigationTargetMessage), screen);
    this.send(msg);
  }

  navigateToOrderSummary = () => {
    this.navigateToScreen('OrderSummary');
  }

  navigateToOrderSummaryBlankFormFee = () => {
    this.navigateToScreen('OrderSummaryBlankFormFee');
  }
  
  navigateToOrderSummaryChecklistFee = () => {
    this.navigateToScreen('OrderSummaryChecklistFee');
  }

  navigateToOrderSummaryCalculateRegistrationFee = () => {
    this.navigateToScreen('OrderSummaryCalculateRegistrationFee');
  }
  
  changeRegistrationFeeHandler = (event: any) => {
    const input = event.target.value;
    let value = String(input || '');

    value = value.match(/[0-9.]+/g)?.join('') || '';

    
    if (value.length > 9) {
      value = value.slice(0, 9);
    }

    this.setState({
      registrationFee: value
    })
  }

  getAcceptedServiceProvider = () => {
    const webHeader = {
      'Content-Type': 'application/json',
      token: localStorage.getItem('authToken'),
    };
    const webRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiGetAcceptedSP = webRequestMessage.messageId;
    webRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      'bx_block_profile/select_service_providers/fetch_accepted_services?deal_id=' + localStorage.getItem("createdDealId")
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(webHeader)
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );
    runEngine.sendMessage(webRequestMessage.id, webRequestMessage);
    return true;
  }

  getDealInformation = () =>{
    const webHeader = {
      'Content-Type': 'application/json',
      token: localStorage.getItem('authToken'),
    };
    const webRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiGetDealInformationId = webRequestMessage.messageId;
    webRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      'bx_block_posts/deals/' + localStorage.getItem("createdDealId")
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(webHeader)
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );
    runEngine.sendMessage(webRequestMessage.id, webRequestMessage);
    return true;
  }

  getUserInformation = () =>{
    const webHeader = {
      'Content-Type': 'application/json',
      token: localStorage.getItem('authToken'),
    };
    const webRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiGetUserInformationId = webRequestMessage.messageId;
    webRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      'account_block/accounts/get_user'
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(webHeader)
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );
    runEngine.sendMessage(webRequestMessage.id, webRequestMessage);
    return true;
  }

  mapToDealInfo = (response: any) => {
    const vehicle : Vehicle = {
      vehicleVin : response.vehicle_vin,
      vehicleType: response.vehicle_type,
      vehicleYear: response.vehicle_year,
      vehicleWeightEmpty: response.vehicle_weight_empty,
      vehicleWeightGross: response.vehicle_weight_gross,
      make: response.make,
      odometer: response.odometer,
      bodyType: response.body_type
    }

    const dealInfo : DealInfo = {
      dealId: response.id,
      ownerFirstName: response.owner_first_name,
      ownerLastname: response.owner_last_name,
      fromState: response.from_state,
      toState: response.to_state,
      applicationType: response.application_type,
      registrationType: response.registration_type,
      saleType: response.sale_type,
      soldAs: response.sold_as,
      msrp: response.msrp,
      tsp: response.tsp,
      sellerZip: response.seller_zip,
      purchaserZip: response.purchaser_zip,
      firstOwner: response.first_owner,
      secondOwner: response.second_owner,
      vehicle: vehicle
    }
    return dealInfo;
  }

  closeDrawerHandler = () =>{
    this.setState({sidebar: false});
  }

  mapToHiredSPInfo = (response: any) => {
    const {id, type} = response.data[0];
    const {selected_services, created_at, updated_at, service_provider_name, service_provider_logo, hired_on} = response.data[0].attributes;
    return{
      id,
      type,
      selectedService: selected_services,
      createdAt: created_at,
      updatedAt: updated_at,
      serviceProviderName: service_provider_name || 'Blue Tree Services',
      serviceProviderLogo: service_provider_logo || spAvatar,
      hiredOn: hired_on,
    }
  }
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);


    // Customizable Area Start
    this.state = {
      selectedStatus: "In Progress",
      registrationFee: '',
      isRegistrationFeeSaved: false,
      dealInfo: {} as DealInfo,
      user: {} as User,
      sidebar: false,
      hiredSPInfo: {} as ServiceProvider,
    };

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    this.getDealInformation();
    this.getUserInformation();
    this.getAcceptedServiceProvider();
    const url = new URL(window.location.toString());
    const sidebar = url.searchParams.get("sidebar");
    if(sidebar){
      this.setState({sidebar: sidebar === "true"});
    }
    // Customizable Area End
  }

  // Customizable Area Start
  sentServiceProviders = [
    {
      'name': 'Blue Tree Services',
      'postivePercent': 86,
      'service': 'Full Service',
      'price': '45.00',
      'status': 'Hiring'
    },
    {
      'name': 'SmartLock Solutions',
      'postivePercent': 90,
      'service': 'Runner Service',
      'price': '63.00',
      'status': 'Hiring'
    },
    {
      'name': 'ClimateCare Heating Services',
      'postivePercent': 93,
      'service': 'Onsite',
      'price': '85.00',
      'status': 'Hiring'
    }
  ];
  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let response = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (response && !response.errors) {
        if (apiId === this.apiGetDealInformationId) {
          this.setState({
            dealInfo: this.mapToDealInfo(response.data.attributes),
          });
        }
        if (apiId === this.apiGetUserInformationId) {
          this.setState({
            user: {id: response.data.id, avatar: response.data.attributes.profile_picture}
          })
        }

        if (apiId === this.apiGetAcceptedSP) {
          this.setState({
            hiredSPInfo: this.mapToHiredSPInfo(response),
          })
        }
      }
    }
    // Customizable Area End
  }
}