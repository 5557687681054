import {
  Box,
  createStyles,
  withStyles,
  Typography,
  WithStyles,
  Button,
} from "@material-ui/core";
import React from "react";
import { spAvatar } from "./assets";
import { HelpOutline, Lock } from "@material-ui/icons";
import { ServiceProvider } from "./ProjectTemplatesDealDashboardController.web";
const classes = createStyles({
  hiredSP: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    gap: "20px",
    paddingTop: "20px",
  },

  header: {
    display: "flex",
    alignItems: "center",
  },

  SPName: {
    fontFamily: "Gotham",
    fontSize: "28px",
    fontWeight: 400,
    lineHeight: "28px",
    textAlign: "left",
    color: "#013D4F",
    marginLeft: "10px",
  },

  row1: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },

  row2: {
    marginTop: "10px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },

  serviceStatus: {
    padding: "6px 12px 6px 8px",
    gap: "4px",
    borderRadius: "26px",
    background: "#EAFBFA",
    color: "#26BBB3",
    fontFamily: "Roboto",
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "22px",
    display: "flex",
    alignItems: "center",
    textWrap: "nowrap",
  },

  SPPrice: {
    fontFamily: "Roboto",
    fontSize: "18px",
    fontWeight: 500,
    lineHeight: "32px",
    letterSpacing: "-0.005em",
    color: "#4B4C4B",

    "& span": {
      color: "#C5CBC9",
      display: 'inline-block'
    },
  },

  hiredDetails: {
    fontFamily: "Gotham",
    fontSize: "16px",
    fontWeight: 300,
    lineHeight: "26px",
  },

  endContract: {
    fontFamily: "Roboto",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
    display: "flex",
    gap: "5px",
    textWrap: "nowrap",
  },

  chatNow: {
    borderRadius: "8px",
    background: "#013D4F",
    padding: "16px 0px",

    "&:hover": {
      background: "#0F6D8A",
    },

    "& > span": {
      color: "#FFFFFF",
      textTransform: "initial",
      fontFamily: "Roboto",
      fontSize: "16px",
      fontWeight: 700,
      lineHeight: "24px",
    },
  },
});

export interface Props extends WithStyles {
  serviceProvider: ServiceProvider;
}
export class ServiceProviderHired extends React.Component<Props, {}> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  render(): React.ReactNode {
    const { classes, serviceProvider } = this.props;
    return (
      <Box className={classes.hiredSP}>
        <Box className={classes.header}>
          <img src={serviceProvider.serviceProviderLogo}></img>
          <Typography className={classes.SPName}>
            {serviceProvider.serviceProviderName}
          </Typography>
        </Box>
        <Box className={classes.content}>
          <Box className={classes.row1}>
            <Typography className={classes.SPPrice}>
              {serviceProvider.selectedService}
              <span> (Vitu Fee 3%) </span>
            </Typography>
            <Box className={classes.serviceStatus}>
              <Lock /> In Holding
            </Box>
          </Box>
          <Box className={classes.row2}>
            <Typography className={classes.hiredDetails}>
              They were hired on {serviceProvider.hiredOn}
            </Typography>
            <Typography className={classes.endContract}>
              End contract <HelpOutline></HelpOutline>
            </Typography>
          </Box>
        </Box>
        <Button className={classes.chatNow}>Chat Now</Button>
      </Box>
    );
  }
}

export default withStyles(classes)(ServiceProviderHired);
