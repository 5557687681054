// Customizable Area Start
import React, { CSSProperties } from "react";
import moment from "moment";
import {
  Avatar,
  Box,
  Typography,
  Tabs,
  Tab,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Snackbar,
  Backdrop,
  TextField,
  FormControl,
  MenuItem,
  Select,
  InputLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Checkbox,
  ListItemText,
  Button,
} from "@material-ui/core";
import { styled } from "@material-ui/core/styles";

import MyTeamController, { Props, ContentView } from "./MyTeamController.web";
import TableHead from "./TableHead.web";
import DealershipsTable from "./DealershipsTable.web";
import UsersTable from "./UsersTable.web";
import {
  circleCheckmark,
  dealershipGroup,
  group,
  pencilGrey,
  trash,
  trashGrey,
  invitationSuccess,
  editUserSuccess,
  checkmark
} from "./assets";
import { CloseRounded, ExpandMoreRounded } from "@material-ui/icons";

export default class MyTeam extends MyTeamController {
  constructor(props: Props) {
    super(props);
  }

  HeaderRightSide = () => {
    const { userData } = this.props;
    const { showGroupNameEdit, updatedGroupName } = this.state;

    return (
      <Box sx={webStyles.headerRightSide}>
        <Label>Dealer Group</Label>
        {typeof userData?.attributes.dealerGroupName === "string" && (
          <>
            <Box sx={webStyles.dealerGroupUpperBox}>
              {showGroupNameEdit ? (
                <>
                  <StyledTextField
                    value={updatedGroupName}
                    onChange={this.setUpdatedGroupName}
                    inputProps={{
                      maxLength: 50,
                    }}
                  />
                  <Typography
                    style={webStyles.editGroup}
                    onClick={this.updateDealerGroupName}
                  >
                    Update
                  </Typography>
                </>
              ) : (
                <>
                  <Typography style={webStyles.groupName}>
                    {userData.attributes.dealerGroupName || ""}
                  </Typography>
                  <Typography
                    style={webStyles.editGroup}
                    onClick={this.handleGroupEdit}
                  >
                    Edit
                  </Typography>
                </>
              )}
            </Box>
            <Box style={webStyles.creationDate}>
              {userData.attributes.groupCreatedOn &&
                `Created on ${moment(
                  new Date(userData.attributes.groupCreatedOn)
                ).format("MMMM D, YYYY")}`}
            </Box>
          </>
        )}
      </Box>
    );
  };

  DealershipsList = () => {
    const { dealershipsData, addUsersToDealershipsIds } = this.state;

    return (
      <>
        <Typography className="tableTitle">Dealerships</Typography>
        <Box className="dealershipsContainer">
          {dealershipsData.map((dealership) => (
            <Box className="dealershipItem" key={dealership.id}>
              <Box className="leftSide">
                <Typography className="dealershipTitle">
                  {dealership.name}
                </Typography>
                <Typography className="dealershipUsers">
                  {dealership.users} Users
                </Typography>
              </Box>
              <Box className="rightSide">
                {addUsersToDealershipsIds.includes(dealership.id) ? (
                  <>
                    <button
                      onClick={(e) =>
                        this.handleAddUserToDealershipIds(e, dealership.id)
                      }
                      data-test-id="user-added-button"
                      type="button"
                      className="userAddedButton"
                    >
                      Added
                      <img src={circleCheckmark} alt="checkmark" />
                    </button>

                    <button
                      onClick={(e) =>
                        this.handleAddUserToDealershipIds(e, dealership.id)
                      }
                      data-test-id="user-added-button-secondary"
                      type="button"
                      className="userAddedButtonSecondary"
                    >
                      Remove
                      <CloseRounded width={14} style={{ color: "#FFF" }} />
                    </button>
                  </>
                ) : (
                  <button
                    onClick={(e) =>
                      this.handleAddUserToDealershipIds(e, dealership.id)
                    }
                    data-test-id="add-user-button"
                    type="button"
                    className="addUserButton"
                  >
                    Add to Dealership
                  </button>
                )}
              </Box>
            </Box>
          ))}
        </Box>
      </>
    );
  };

  AddUserModal = () => {
    const { isDealershipsModalOpened } = this.state;

    return (
      <AddUserBackdrop
        open={isDealershipsModalOpened}
        onClick={this.handleDealershipsModal}
      >
        <Box className="modalContainer" onClick={this.stopPropagation}>
          <CloseRounded
            onClick={this.handleDealershipsModal}
            width={18}
            style={{ position: "absolute", right: "60px", cursor: "pointer" }}
          />
          <Box className="modalHeader">
            <Typography className="modalTitle">Add Dealerships</Typography>
            <Typography className="modalDescription">
              Select the dealership you would like to add these user(s) to
            </Typography>
          </Box>

          <this.DealershipsList />

          <Button
            onClick={this.handleAddUserToDealership}
            data-test-id="add-user-button"
            className="updateDealershipsUsers"
          >
            Update
          </Button>
        </Box>
      </AddUserBackdrop>
    );
  };

  DeleteUsersPrompt = () => {
    const { isDeletePromptOpened, usersData } = this.state;

    return (
      <StyledDialog
        open={isDeletePromptOpened}
        onClose={this.setDeletePromptState}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Delete users</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {usersData.filter((user) => user.checked).length > 1
              ? `Are you sure you want to delete these ${
                  usersData.filter((user) => user.checked).length
                } users? They will no longer have access to Vitu Marketplace.`
              : `Are you sure you want to delete ${
                  usersData.find((user) => user.checked)?.name
                }? He/She will no longer have access to Vitu Marketplace.`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <button
            onClick={this.setDeletePromptState}
            data-test-id="create-dealership"
            type="button"
            style={webStyles.cancelDeleteButton}
          >
            Cancel
          </button>

          <button
            onClick={this.handleDeleteUsers}
            data-test-id="invite-user"
            type="button"
            style={webStyles.deleteUserButton}
          >
            Delete
          </button>
        </DialogActions>
      </StyledDialog>
    );
  };

  InviteUserModal = () => {
    const { showInviteUser, userData } = this.props;
    const {
      invitationSuccessScreen,
      roles,
      invitedUsers,
      dealershipsData,
      inviteToDealership,
      invitedUserRole,
      singleDealershipInfo,
      invitedUserEmail,
      inviteUserEmailError
    } = this.state;

    return (
      <InviteUserBackdrop
        open={showInviteUser}
        onClick={this.onShowInviteUserClose}
      >
        <Box className="inviteContainer" onClick={this.stopPropagation}>
          <CloseRounded
            onClick={this.onShowInviteUserClose}
            width={18}
            style={{ position: "absolute", right: "60px", cursor: "pointer" }}
          />
          {invitationSuccessScreen ? (
            <Box className="successInvite">
              <img
                className="successInviteImage"
                src={invitationSuccess}
                alt="invite success"
              />
              <Box className="successMessageBlock">
                <Typography className="successTitle">
                  Invitation Sent
                </Typography>
                <Typography className="successDescription">
                  The user will get an email with a link to join.
                </Typography>
              </Box>

              <Box className="successButtonsContainer">
                <button
                  className="successViewDealership"
                  onClick={this.onShowInviteUserClose}
                >
                  View Dealership
                </button>
                <button
                  onClick={this.closeInvitationSuccess}
                  className="successInviteMore"
                >
                  Invite More Users
                </button>
              </Box>
            </Box>
          ) : (
            <>
              <Box className="inviteHeader">
                <Typography className="inviteTitle">Invite User</Typography>
                <Typography className="inviteDescription">
                  Please fill the information below to add this user.
                </Typography>
              </Box>

              {Boolean(invitedUsers.length) && (
                <Box className="inviteUsersContainer">
                  <Typography className="addedUserTitle">Added User</Typography>

                  <Box className="usersList">
                    {invitedUsers.map((user, i) => (
                      <Box className="userItem" key={user.email + i}>
                        <Box className="userBlankAvatar">
                          {user.email.slice(0, 2).toUpperCase()}
                        </Box>
                        <Box className="userInfoContainer">
                          <Typography className="userInfoEmail">
                            {user.email}
                          </Typography>
                          <Typography className="userInfoDealerships">
                            {dealershipsData
                              .filter((dealership) =>
                                user.dealer_information_ids.includes(
                                  dealership.id
                                )
                              )
                              .map((dealership) => dealership.name)
                              .join(", ")}
                          </Typography>
                        </Box>
                        <Box className="userActionsContainer">
                          <Typography className="userRole">
                            {user.dealership_role_id === "1" ? "Admin" : "User"}
                          </Typography>
                          <img
                            onClick={() => this.editInvitedUser(user)}
                            className="editUser"
                            src={pencilGrey}
                            alt="edit user"
                          />
                          <img
                            onClick={() => this.deleteInvitedUser(user.email)}
                            className="deleteUser"
                            src={trashGrey}
                            alt="delete user"
                          />
                        </Box>
                      </Box>
                    ))}
                  </Box>
                </Box>
              )}

              <Box className="inviteFormContainer">
                {Boolean(invitedUsers.length) && (
                  <Typography className="formTitle">
                    Add Another User
                  </Typography>
                )}
                <FormControl variant="outlined" fullWidth>
                  <InputLabel id="dealership-select-label">
                    Choose Dealership
                  </InputLabel>
                  <Select
                    labelId="dealership-select-label"
                    value={inviteToDealership}
                    renderValue={() =>
                      inviteToDealership
                        .map(
                          (dealershipId) =>
                            dealershipsData.find(
                              (dealershipToFind) =>
                                dealershipToFind.id === dealershipId
                            )?.name
                        )
                        .join(", ")
                    }
                    onChange={this.handleDealershipSelect}
                    disabled={dealershipsData.length === 1}
                    label="Choose Dealership"
                    multiple
                    IconComponent={ExpandMoreRounded}
                    MenuProps={{
                      getContentAnchorEl: null,
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left",
                      },
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                    }}
                  >
                    {userData?.attributes.soloDealershipId ? (
                      <MenuItem value={userData.attributes.soloDealershipId}>
                        {singleDealershipInfo?.name}
                      </MenuItem>
                    ) : (
                      dealershipsData.map((dealership) => (
                        <MenuItem key={dealership.id} value={dealership.id}>
                          <ListItemText primary={dealership.name} />
                          <StyledCheckbox
                            checked={inviteToDealership.includes(dealership.id)}
                            checkedIcon={<CheckedIcon><img src={checkmark} alt="checkmark" /></CheckedIcon>}
                            icon={<CheckboxIcon />}
                          />
                        </MenuItem>
                      ))
                    )}
                  </Select>
                </FormControl>

                <TextField
                  placeholder="Email Address"
                  value={invitedUserEmail}
                  onChange={this.setInviteUserEmail}
                  inputProps={{
                    maxLength: 80,
                  }}
                  variant="outlined"
                  label="Email Address"
                  error={Boolean(inviteUserEmailError)}
                  helperText={inviteUserEmailError}
                  onBlur={this.checkEmail}
                  style={{
                    borderRadius: "8px",
                  }}
                />

                <InviteRadioGroupContainer>
                  <Typography className="radioLabel">Choose Role</Typography>
                  <FormControl>
                    <RadioGroup
                      value={invitedUserRole}
                      onChange={this.setInviteUserRole}
                      row
                    >
                      {roles.map((role) => (
                        <FormControlLabel
                          key={role.id}
                          value={role.id}
                          control={
                            <Radio
                              checkedIcon={<RadioIconChecked />}
                              icon={<RadioIcon />}
                            />
                          }
                          label={role.attributes.label}
                        />
                      ))}
                    </RadioGroup>
                  </FormControl>
                </InviteRadioGroupContainer>

                {this.isAllowedToInvite() && (
                  <Button
                    onClick={this.setInviteUsers}
                    className="addAnotherUserButton"
                    style={{
                      alignSelf: "flex-end",
                      width: "160px",
                      height: "44px",
                      color: "#013D4F",
                      border: "1px solid #013D4F",
                      borderRadius: "8px",
                      fontWeight: 700,
                      backgroundColor: "transparent",
                      textTransform: "none",
                    }}
                  >
                    Add Another User
                  </Button>
                )}
              </Box>
              <Button
                variant="contained"
                onClick={this.inviteUsersToDealership}
                data-test-id="invite-user-button"
                className="inviteDealershipsUsers"
                disabled={!this.isAllowedToInvite()}
                style={{
                  width: "100%",
                  minHeight: "56px",
                  fontSize: "16px",
                  fontWeight: 700,
                  lineHeight: "24px",
                  textTransform: "none",
                  borderRadius: "8px",
                  background: this.isAllowedToInvite() ? "#4FC8EC" : "#E8F8FD",
                  color: this.isAllowedToInvite() ? "#FFFFFF" : "#75D3F0",
                  fontFamily: "Roboto, sans-serif",
                }}
              >
                Invite User
              </Button>
            </>
          )}
        </Box>
      </InviteUserBackdrop>
    );
  };

  EditUserModal = () => {
    const { userData } = this.props;
    const {
      roles,
      showEditUser,
      editedUserName,
      editedUserEmail,
      editedUserRole,
      addUsersToDealershipsIds,
    } = this.state;

    return (
      <EditUserBackdrop
        open={showEditUser}
        onClick={this.onShowEditUserClose}
      >
        <Box className="editContainer" onClick={this.stopPropagation}>
          <CloseRounded
            onClick={this.onShowEditUserClose}
            width={18}
            style={{ position: "absolute", right: "60px", cursor: "pointer" }}
          />
          <Box className="editHeader">
            <Typography className="editTitle">{`Edit ${editedUserName}`}</Typography>
            <Typography className="editDescription">
              Adjust the email, role, and Dealership(s) of this user
            </Typography>
          </Box>

          <Box className="editFormContainer">
            <Typography className="formTitle">Basic Info</Typography>

            <TextField
              placeholder="Email Address"
              value={editedUserEmail}
              onChange={this.setEditUserEmail}
              inputProps={{
                maxLength: 80,
              }}
              variant="outlined"
              label="Email Address"
            />

            <InviteRadioGroupContainer>
              <Typography className="radioLabel">Choose Role</Typography>
              <FormControl>
                <RadioGroup
                  className="radioGroup"
                  value={editedUserRole}
                  onChange={this.setEditUserRole}
                  row
                >
                  {roles.map((role) => (
                    <FormControlLabel
                      key={role.id}
                      value={role.id}
                      control={
                        <Radio
                          checkedIcon={<RadioIconChecked />}
                          icon={<RadioIcon />}
                        />
                      }
                      label={role.attributes.label}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
            </InviteRadioGroupContainer>

            {userData?.attributes.isDealerGroup && <this.DealershipsList />}
          </Box>

          <button
            onClick={this.editUser}
            data-test-id="invite-user-button"
            type="button"
            className="editDealershipsUsers"
            disabled={!addUsersToDealershipsIds.length || !editedUserEmail}
          >
            Update
          </button>
        </Box>
      </EditUserBackdrop>
    );
  };

  CustomSnackbar = () => {
    const { snackbarMessage } = this.state;

    return (
      <StyledSnackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        open={Boolean(snackbarMessage)}
        autoHideDuration={3000}
        onClose={this.onSnackbarClose}
        style={{ backgroundColor: "#FFF" }}
      >
        <Box style={{ display: "flex", alignItems: "center" }}>
          {(snackbarMessage.includes("Deleted") && (
            <img src={trash} alt="delete icon" />
          )) ||
            (snackbarMessage.includes("added") && (
              <img src={group} alt="dealership icon" />
            )) ||
            (snackbarMessage.includes("Group Updated") && (
              <img src={dealershipGroup} alt="group icon" />
            )) ||
            (snackbarMessage.includes("User Updated") && (
              <img src={editUserSuccess} alt="user icon" />
            ))}

          <Typography>{snackbarMessage}</Typography>
        </Box>
      </StyledSnackbar>
    );
  };

  render() {
    const { userData } = this.props;
    const {
      usersData,
      roles,
      dealershipsData,
      singleDealershipInfo,
      currentTab,
      sort,
      search,
      page,
      role,
      totalDealershipsPages,
      totalUsersPages,
      itemsPerPage,
      isAllChecked,
      isDeletePromptOpened,
    } = this.state;

    return (
      <>
        {userData?.attributes.isDealerGroup ? (
          <Container>
            <Box sx={webStyles.header}>
              <Box sx={webStyles.headerLeftSide}>
                <Typography className="title">Dealerships & Users</Typography>
                <Typography className="description">
                  Manage your Dealerships or invite more users.
                </Typography>
              </Box>
              {this.HeaderRightSide()}
            </Box>
            <Box sx={webStyles.content}>
              <CustomizedTabs value={currentTab} onChange={this.setTab}>
                <Tab
                  data-test-id="dealerships-tab"
                  label="Dealerships"
                  value={ContentView.DEALERSHIPS}
                />
                <Tab
                  data-test-id="users-tab"
                  label="Users"
                  value={ContentView.USERS}
                />
              </CustomizedTabs>

              {currentTab === ContentView.DEALERSHIPS ? (
                <>
                  <TableHead
                    currentView={currentTab}
                    sort={sort}
                    search={search}
                    page={page}
                    totalItems={dealershipsData.length}
                    itemsPerPage={itemsPerPage}
                    totalPages={totalDealershipsPages}
                    setSort={this.setSort}
                    setSearch={this.setSearch}
                    setPage={this.setPage}
                    checkedItems={
                      dealershipsData.filter((dealership) => dealership.checked)
                        .length
                    }
                    handleCheckAll={this.handleCheckAll}
                    isAllChecked={isAllChecked}
                  />
                  <DealershipsTable
                    data={dealershipsData}
                    itemsPerPage={itemsPerPage}
                    page={page}
                    handleCheck={this.handleCheck}
                  />
                </>
              ) : (
                <>
                  <TableHead
                    currentView={currentTab}
                    sort={sort}
                    search={search}
                    page={page}
                    totalPages={totalUsersPages}
                    role={role}
                    roles={roles}
                    totalItems={usersData.length}
                    itemsPerPage={itemsPerPage}
                    setSort={this.setSort}
                    setRole={this.setRole}
                    setSearch={this.setSearch}
                    setPage={this.setPage}
                    checkedItems={
                      usersData.filter((user) => user.checked).length
                    }
                    handleCheckAll={this.handleCheckAll}
                    isAllChecked={isAllChecked}
                    isDeletePromptOpened={isDeletePromptOpened}
                    setDeletePromptState={this.setDeletePromptState}
                    handleDealershipsModal={this.handleDealershipsModal}
                  />
                  <UsersTable
                    usersData={usersData}
                    itemsPerPage={itemsPerPage}
                    page={page}
                    handleCheck={this.handleCheck}
                    setDeletePromptState={this.setDeletePromptState}
                    handleEditUser={this.handleEditUser}
                  />
                </>
              )}
            </Box>

            {this.DeleteUsersPrompt()}
            {this.AddUserModal()}
            {this.CustomSnackbar()}
          </Container>
        ) : (
          <Container>
            <Box style={webStyles.singleDealershipHeader}>
              <SingleDealershipAvatar
                src={singleDealershipInfo?.logo}
                alt={singleDealershipInfo?.name}
                variant="rounded"
              >
                {singleDealershipInfo?.name &&
                  singleDealershipInfo.name
                    .split(" ")
                    .slice(0, 2)
                    .map((string) => string[0])
                    .join("")
                    .toUpperCase()}
              </SingleDealershipAvatar>
              <Box style={webStyles.singleDealershipContainer}>
                <Typography style={webStyles.singleDealershipTitle}>
                  {singleDealershipInfo?.name}
                </Typography>
                <Typography style={webStyles.singleDealershipDescription}>
                  Manage this Dealership or invite more users.
                </Typography>
              </Box>
            </Box>
            <TableHead
              currentView={ContentView.SINGLE_DEALERSHIP_USERS}
              sort={sort}
              search={search}
              page={page}
              totalPages={totalUsersPages}
              role={role}
              roles={roles}
              totalItems={usersData.length}
              itemsPerPage={itemsPerPage}
              setSort={this.setSort}
              setRole={this.setRole}
              setSearch={this.setSearch}
              setPage={this.setPage}
              checkedItems={usersData.filter((user) => user.checked).length}
              handleCheckAll={this.handleCheckAll}
              isAllChecked={isAllChecked}
              isDeletePromptOpened={isDeletePromptOpened}
              setDeletePromptState={this.setDeletePromptState}
              handleDealershipsModal={this.handleDealershipsModal}
            />
            <UsersTable
              usersData={usersData}
              itemsPerPage={itemsPerPage}
              page={page}
              handleCheck={this.handleCheck}
              setDeletePromptState={this.setDeletePromptState}
              handleEditUser={this.handleEditUser}
            />
          </Container>
        )}

        {this.DeleteUsersPrompt()}
        {this.InviteUserModal()}
        {this.EditUserModal()}
      </>
    );
  }
}

const Container = styled(Box)(({theme}) => ({
  display: "flex",
  flexDirection: "column",
  height: "100%",
  width: "100%",
  boxSizing: "border-box",
  padding: "36px 24px 36px 32px",

  [theme.breakpoints.down('sm')]: {
    padding: "24px 10px",
  },

  "& .title": {
    fontFamily: "Gotham, sans-serif",
    fontSize: "30px",
    lineHeight: "40px",
    fontWeight: 300,
    color: "#013D4F",

    [theme.breakpoints.down('sm')]: {
      fontSize: "20px",
      lineHeight: "26px",
    },
  },

  "& .description": {
    fontFamily: "Gotham, sans-serif",
    fontSize: "18px",
    lineHeight: "18px",
    fontWeight: 300,
    color: "#4B4C4B",

    [theme.breakpoints.down('sm')]: {
      fontSize: "14px",
      lineHeight: "18px",
    },
  }
}));

const StyledCheckbox = styled(Checkbox)({
  "&.Mui-checked": {
    color: "#4FC8EC",
    width: "24px",
    height: "24px",
    borderRadius: "6px"
  },

  "&:hover": {
    backgroundColor: "transparent",
  },
});

const CheckboxIcon = styled("div")({
  boxSizing: "border-box",
  width: "24px",
  height: "24px",
  border: "1px solid #C5CBC9",
  borderRadius: "6px"
})

const CheckedIcon = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "24px",
  height: "24px",
  borderRadius: "6px",
  backgroundColor: "#4FC8EC",
})

const InviteRadioGroupContainer = styled(Box)({
  display: "flex",
  position: "relative",
  alignItems: "center",
  border: "1px solid #C5CBC9",
  borderRadius: "8px",
  padding: "0 24px",
  height: "56px",

  "& .MuiFormControlLabel-root": {
    marginRight: "48px"
  },

  "& p.radioLabel": {
    position: "absolute",
    left: "6px",
    top: "-9px",
    fontFamily: "Roboto, sans-serif",
    padding: "1px 8px",
    fontSize: "12px",
    fontWeight: 500,
    backgroundColor: "#FFF",
    lineHeight: "18px",
    color: "rgba(0, 0, 0, 0.4)",
  },

  "& .MuiTypography-body1": {
    fontWeight: 500,
    fontFamily: "Roboto, sans-serif",
    lineHeight: "24px",
    fontSize: "16px",
  },

  "& .MuiSvgIcon-root": {
    fontSize: "23px",
  },

  "& .MuiRadio-colorSecondary.Mui-checked": {
    color: "#4FC8EC",
  },

  "& .MuiRadio-colorSecondary.Mui-checked:hover": {
    backgroundColor: "transparent",
  },

  "& .MuiIconButton-colorSecondary:hover": {
    backgroundColor: "transparent",
  },
});

const RadioIcon = styled('span')({
  borderRadius: '50%',
  width: '20px',
  height: '20px',
  boxShadow: 'inset 0 0 0 1px gray, inset 0 -1px 0 rgba(16,22,26,.1)',
  backgroundColor: '#fff',
});

 const RadioIconChecked = styled(RadioIcon)({
  backgroundColor: '#4FC8EC',
  backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
  boxShadow: 'inset 0 0 0 2px #4FC8EC, inset 0 -2px 0 rgba(16,22,26,.1)',
  '&::before': {
    display: 'block',
    width: '20px',
    height: '20px',
    backgroundImage: 'radial-gradient(#FFFFFF,#FFFFFF 28%,transparent 32%)',
    content: '""',
  },
  'input:hover ~ &': {
    backgroundColor: '#4FC8EC',
  },
});

const Label = styled(Typography)({
  position: "absolute",
  top: "-9px",
  left: "7px",
  padding: "0 3px",
  fontFamily: "Roboto, sans-serif",
  fontSize: "12px",
  lineHeight: "18px",
  fontWeight: 500,
  color: "#4B4C4B",
  backgroundColor: "#FFF",
});

const CustomizedTabs = styled(Tabs)({
  borderBottom: "1px solid #F0FAFA",

  "& span": {
    fontFamily: "Roboto, sans-serif",
    fontSize: "16px",
    fontWeight: "600",
    lineHeight: "24px",
    textTransform: "capitalize",
    color: "#C5CBC9",
  },

  "& .Mui-selected span": {
    color: "#0F172A",
  },

  "& .MuiTabs-fixed > span": {
    backgroundColor: "#0F172A",
  },
});

const StyledDialog = styled(Dialog)({
  "& .MuiBackdrop-root": {
    backgroundColor: "rgba(1, 61, 79, 0.4)",
  },

  "& .MuiDialog-paper": {
    padding: "60px 40px 24px",
  },

  "& .MuiDialogTitle-root": {
    color: "#0F172A",

    "& .MuiTypography-root": {
      fontFamily: "Roboto, sans-serif",
      fontSize: "24px",
      fontWeight: 500,
    },
  },

  "& .MuiDialogContent-root": {
    color: "#013D4F",

    "& .MuiTypography-root": {
      fontFamily: "Gotham, sans-serif",
      fontSize: "18px",
      fontWeight: 300,
    },
  },
});

const StyledTextField = styled(TextField)({
  "& input": {
    color: "#C5CBC9",
    fontFamily: "Roboto, sans-serif",
    fontSize: "20px",
    fontWeight: 500,
  },

  "& .MuiInput-underline:before": {
    borderBottom: "none",
  },

  "& .MuiInput-underline:after": {
    borderBottom: "none",
  },

  "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
    borderBottom: "none",
  },
});

const EditUserBackdrop = styled(Backdrop)({
  display: "flex",
  justifyContent: "flex-end",
  backgroundColor: "rgba(1, 61, 79, 0.4)",
  height: "100vh",
  zIndex: 1,
  alignItems: "unset",

  "& .editContainer": {
    overflow: "auto",
    backgroundColor: "#FFF",
    gap: "48px",
    padding: "48px 64px",
    flexShrink: 1,
    flexBasis: "768px",
    height: "100vh",
    flexDirection: "column",
    boxSizing: "border-box",
    display: "flex",
    position: "relative",

    "&::-webkit-scrollbar-thumb": {
      background: "#D9D9D9",
    },

    "&::-webkit-scrollbar": {
      width: "6px",
      borderRadius: "39px",
    },

    "&::-webkit-scrollbar-track": {
      visibility: "hidden",
    },    
  },

  "& .editDescription": {
    fontFamily: "Roboto, sans-serif",
    fontSize: "14px",
    lineHeight: "22px",
    fontWeight: 500,
    color: "#4B4C4B",
  },

  "& .editTitle": {
    fontFamily: "Gotham, sans-serif",
    lineHeight: "32px",
    fontSize: "24px",
    color: "#013D4F",
    fontWeight: 300,
  },

  "& .formTitle": {
    fontFamily: "Roboto, sans-serif",
    fontWeight: 500,
    fontSize: "20px",
    color: "#013D4F",
  },

  "& .editFormContainer": {
    display: "flex",
    flexDirection: "column",
    gap: "16px",

    "& .MuiCheckbox-colorSecondary.Mui-checked": {
      color: "#4FC8EC",
    },

    "& ul > li .MuiIconButton-colorSecondary:hover": {
      backgroundColor: "transparent",
    },
  },

  "& .tableTitle": {
    marginTop: "32px",
    fontFamily: "Roboto, sans-serif",
    fontSize: "20px",
    lineHeight: "28px",
    fontWeight: 500,
    color: "#013D4F",
  },

  "& .dealershipsContainer": {
    display: "flex",
    flexDirection: "column",
    overflowY: "auto",
    gap: "10px",

    "&::-webkit-scrollbar": {
      width: "6px",
      borderRadius: "39px",
    },

    "&::-webkit-scrollbar-track": {
      visibility: "hidden",
    },

    "&::-webkit-scrollbar-thumb": {
      background: "#D9D9D9",
    },
  },

  "& .dealershipItem": {
    display: "flex",
    flexWrap: "nowrap",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "20px 24px",
    border: "1px solid #F0FAFA",
    borderRadius: "12px",

    "&:hover": {
      backgroundColor: "#013D4F",

      "& .dealershipTitle": {
        color: "#FFF",
      },

      "& .dealershipUsers": {
        color: "#ABBFC5"
      },

      "& .addUserButton": {
        backgroundColor: "#4FC8EC",
        color: "#FFF"
      },

      "& .userAddedButtonSecondary": {
        visibility: "visible"
      }
    }
  },

  "& .leftSide": {
    display: "flex",
    flexDirection: "column",
  },

  "& .dealershipTitle": {
    fontFamily: "Roboto, sans-serif",
    fontSize: "20px",
    lineHeight: "24px",
    fontWeight: 500,
    color: "#4B4C4B",
  },

  "& .dealershipUsers": {
    fontFamily: "Roboto, sans-serif",
    fontSize: "14px",
    lineHeight: "24px",
    fontWeight: 400,
    color: "#868786",
  },

  "& .rightSide": {
    position: "relative",
    display: "flex",
    justifyContent: "flex-end",
    alignSelf: "flex-start",
  },

  "& .userAddedButton, & .userAddedButtonSecondary": {
    position: "absolute",
    display: "flex",
    padding: "0 16px",
    height: "44px",
    alignItems: "center",
    justifyContent: "center",
    gap: "8px",
    border: "none",
    borderRadius: "8px",
    cursor: "pointer",
    backgroundColor: "#26BBB3",
    color: "#FFF",
    fontFamily: "Roboto, sans-serif",
    fontSize: "16px",
    fontWeight: 700,
  },

  "& .userAddedButtonSecondary": {
    backgroundColor: "#C43937",
    visibility: "hidden"
  },

  "& .addUserButton": {
    display: "flex",
    width: "161px",
    height: "44px",
    alignItems: "center",
    justifyContent: "center",
    border: "1px solid #013D4F",
    borderRadius: "8px",
    cursor: "pointer",
    backgroundColor: "transparent",
    color: "#013D4F",
    fontFamily: "Roboto, sans-serif",
    fontSize: "16px",
    fontWeight: 700,
  },

  "& .editDealershipsUsers": {
    height: "56px",
    flexShrink: 0,
    fontFamily: "Roboto, sans-serif",
    fontSize: "16px",
    fontWeight: 700,
    color: "#FFF",
    backgroundColor: "#4FC8EC",
    border: "none",
    borderRadius: "8px",
    cursor: "pointer",
  },
})

const AddUserBackdrop = styled(Backdrop)({
  display: "flex",
  justifyContent: "flex-end",
  backgroundColor: "rgba(1, 61, 79, 0.4)",
  height: "100vh",
  zIndex: 1,
  alignItems: "unset",

  "& .modalContainer": {
    position: "relative",
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "column",
    height: "100vh",
    flexBasis: "768px",
    flexShrink: 1,
    padding: "48px 64px",
    gap: "48px",
    backgroundColor: "#FFF",

    "& .updateDealershipsUsers": {
      display: "flex",
      flexGrow: 1,
      maxWidth: "640px",
      maxHeight: "56px",
      minHeight: "56px",
      width: "100%",
      fontWeight: 700,
      fontSize: "16px",
      textTransform: "none",
      lineHeight: "24px",
      borderRadius: "8px",
      color: "#FFFFFF",
      background: "#4FC8EC",
      fontFamily: "Roboto, sans-serif",
    },
  },

  "& .modalTitle": {
    fontFamily: "Gotham, sans-serif",
    fontSize: "24px",
    lineHeight: "32px",
    fontWeight: 300,
    color: "#013D4F",
  },

  "& .modalDescription": {
    fontFamily: "Roboto, sans-serif",
    fontSize: "14px",
    lineHeight: "22px",
    fontWeight: 500,
    color: "#4B4C4B",
  },

  "& .tableTitle": {
    fontFamily: "Roboto, sans-serif",
    fontSize: "20px",
    lineHeight: "28px",
    fontWeight: 500,
    color: "#013D4F",
  },

  "& .dealershipsContainer": {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    overflowY: "auto",

    "&::-webkit-scrollbar": {
      width: "6px",
      borderRadius: "39px",
    },

    "&::-webkit-scrollbar-track": {
      visibility: "hidden",
    },

    "&::-webkit-scrollbar-thumb": {
      background: "#D9D9D9",
    },
  },

  "& .dealershipItem": {
    display: "flex",
    flexWrap: "nowrap",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "20px 24px",
    border: "1px solid #F0FAFA",
    borderRadius: "12px",
  },

  "& .leftSide": {
    display: "flex",
    flexDirection: "column",
  },

  "& .dealershipTitle": {
    fontFamily: "Roboto, sans-serif",
    fontSize: "20px",
    lineHeight: "24px",
    fontWeight: 500,
    color: "#4B4C4B",
  },

  "& .dealershipUsers": {
    fontFamily: "Roboto, sans-serif",
    fontSize: "14px",
    lineHeight: "24px",
    fontWeight: 400,
    color: "#868786",
  },

  "& .userAddedButton": {
    display: "flex",
    width: "110px",
    height: "44px",
    alignItems: "center",
    justifyContent: "center",
    gap: "8px",
    border: "none",
    borderRadius: "8px",
    cursor: "pointer",
    backgroundColor: "#26BBB3",
    color: "#FFF",
    fontFamily: "Roboto, sans-serif",
    fontSize: "16px",
    fontWeight: 700,
  },

  "& .addUserButton": {
    display: "flex",
    width: "161px",
    height: "44px",
    alignItems: "center",
    justifyContent: "center",
    border: "1px solid #013D4F",
    borderRadius: "8px",
    cursor: "pointer",
    backgroundColor: "transparent",
    color: "#013D4F",
    fontFamily: "Roboto, sans-serif",
    fontSize: "16px",
    fontWeight: 700,
  },

  "& .userAddedButtonSecondary": {
    display: "none"
  },
});

const InviteUserBackdrop = styled(Backdrop)({
  display: "flex",
  justifyContent: "flex-end",
  backgroundColor: "rgba(1, 61, 79, 0.4)",
  height: "100vh",
  zIndex: 1,
  alignItems: "unset",

  "& .successInvite": {
    position: "absolute",
    top: "50%",
    transform: "translate(0, -50%)",
    display: "flex",
    alignSelf: "center",
    justifySelf: "center",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
  },

  "& .successInviteImage": {
    width: "180px",
    height: "227px",
    marginBottom: "76px",
  },

  "& .successMessageBlock": {
    display: "flex",
    flexDirection: "column",
    gap: "8px",
    marginBottom: "48px",
  },

  "& .successTitle": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontFamily: "Gotham, sans-serif",
    fontSize: "24px",
    lineHeight: "32px",
    fontWeight: 300,
    color: "#013D4F",
  },

  "& .successDescription": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontFamily: "Roboto, sans-serif",
    fontSize: "14px",
    lineHeight: "22px",
    fontWeight: 500,
    color: "#4B4C4B",
  },

  "& .successButtonsContainer": {
    display: "flex",
    flexWrap: "nowrap",
    gap: "24px",
    width: "512px"
  },

  "& .successViewDealership": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "56px",
    padding: "0 10px",
    flexGrow: 1,
    backgroundColor: "transparent",
    border: "1px solid #C5CBC9",
    borderRadius: "8px",
    cursor: "pointer",
    color: "#4B4C4B",
    fontFamily: "Roboto, sans-serif",
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: 500,
  },

  "& .successInviteMore": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "56px",
    padding: "0 10px",
    flexGrow: 1,
    backgroundColor: "#4FC8EC",
    border: "none",
    borderRadius: "8px",
    cursor: "pointer",
    color: "#FFF",
    fontFamily: "Roboto, sans-serif",
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: 500,
  },

  "& .inviteContainer": {
    position: "relative",
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "column",
    height: "100vh",
    flexBasis: "768px",
    flexShrink: 1,
    padding: "48px 64px",
    gap: "48px",
    backgroundColor: "#FFF",
    overflow: "auto",

    "&::-webkit-scrollbar": {
      width: "6px",
      borderRadius: "39px",
    },

    "&::-webkit-scrollbar-track": {
      visibility: "hidden",
    },

    "&::-webkit-scrollbar-thumb": {
      background: "#D9D9D9",
    },
  },

  "& .inviteTitle": {
    fontFamily: "Gotham, sans-serif",
    lineHeight: "32px",
    fontSize: "24px",
    color: "#013D4F",
    fontWeight: 300,
  },

  "& .inviteDescription": {
    fontFamily: "Roboto, sans-serif",
    lineHeight: "22px",
    fontSize: "14px",
    color: "#4B4C4B",
    fontWeight: 500,
  },

  "& .formTitle": {
    fontFamily: "Roboto, sans-serif",
    fontWeight: 500,
    fontSize: "20px",
    color: "#013D4F",
  },

  "& .inviteUsersContainer": {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "24px",
  },

  "& .addedUserTitle": {
    fontFamily: "Roboto, sans-serif",
    color: "#013D4F",
    fontSize: "20px",
    fontWeight: 500,
  },

  "& .usersList": {
    display: "flex",
    flexDirection: "column",
    gap: "15px",
    width: "100%",
  },

  "& .userItem": {
    display: "flex",
    flexWrap: "nowrap",
    alignItems: "center",
    gap: "8px",
  },

  "& .userBlankAvatar": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "48px",
    height: "48px",
    fontFamily: "Roboto, sans-serif",
    fontSize: "18px",
    fontWeight: 500,
    color: "#013D4F",
    backgroundColor: "#F0FAFA",
    borderRadius: "12px",
  },

  "& .userInfoContainer": {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
  },

  "& .userInfoEmail": {
    fontFamily: "Roboto, sans-serif",
    fontSize: "18px",
    fontWeight: 500,
    color: "#4B4C4B",
  },

  "& .userInfoDealerships": {
    fontFamily: "Roboto, sans-serif",
    color: "#4B4C4B",
    fontWeight: 400,
    fontSize: "14px",
  },

  "& .userActionsContainer": {
    display: "flex",
    height: "100%",
    flexWrap: "nowrap",
    alignSelf: "flex-start",
    gap: "24px",
  },

  "& .userRole": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "41px",
    height: "24px",
    fontFamily: "Roboto, sans-serif",
    fontWeight: 600,
    fontSize: "12px",
    color: "013D4F",
    borderRadius: "26px",
    backgroundColor: "#F0FAFA",
  },

  "& .editUser": {
    width: "24px",
    height: "24px",
    cursor: "pointer",
  },

  "& .deleteUser": {
    width: "24px",
    height: "24px",
    cursor: "pointer",
  },

  "& .inviteFormContainer": {
    display: "flex",
    flexDirection: "column",
    gap: "16px",

    "& .MuiSelect-iconOutlined": {
      right: "20px",
    },

    "& .MuiCheckbox-colorSecondary.Mui-checked": {
      color: "#4FC8EC",
    },

    "& ul > li .MuiIconButton-colorSecondary:hover": {
      backgroundColor: "transparent",
    },
  },

  "& .MuiInputLabel-formControl": {
    transform: "translate(16px, 21px) scale(1)",
  },

  "& .MuiInputLabel-shrink": {
    transform: "translate(12px, -6px) scale(0.75)",
  },
});

const StyledSnackbar = styled(Snackbar)({
  height: "72px",
  width: "420px",
  padding: "0 24px",
  justifyContent: "flex-start",
  border: "1px solid #013D4F",
  borderRadius: "12px",
  boxShadow: "0px 16px 48px 0px rgba(1, 61, 79, 0.22)",

  "& .MuiBox-root": {
    gap: "10px",
  },
});

const SingleDealershipAvatar = styled(Avatar)({
  width: "70px",
  height: "70px",
  color: "#013D4F",
  border: "1px dashed #013D4F",
  backgroundColor: "rgba(240, 250, 250, 0.3)",
  borderRadius: "17px",
  fontWeight: 500,
  fontFamily: "Roboto, sans-serif",
  fontSize: "23px",
});

interface Styles {
  [Key: string]: CSSProperties;
}

const webStyles: Styles = {
  header: {
    display: "flex",
    flexWrap: "nowrap",
    alignItems: "center",
    justifyContent: "space-between",
  },

  headerLeftSide: {
    display: "flex",
    flexDirection: "column",
    gap: "12px",
  },

  headerRightSide: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    width: "272px",
    height: "70px",
    gap: "8 px",
    padding: "5px 10px",
    border: "1px solid #F0FAFA",
    borderRadius: "12px",
  },

  dealerGroupUpperBox: {
    display: "flex",
    flexWrap: "nowrap",
    alignItems: "center",
    justifyContent: "space-between",
  },

  groupName: {
    fontFamily: "Roboto, sans-serif",
    fontSize: "20px",
    lineHeight: "28px",
    fontWeight: 500,
    color: "#4B4C4B",
  },

  editGroup: {
    fontFamily: "Roboto, sans-serif",
    fontSize: "14px",
    lineHeight: "22px",
    fontWeight: 500,
    color: "#4B4C4B",
    cursor: "pointer",
  },

  creationDate: {
    fontFamily: "Roboto, sans-serif",
    fontSize: "12px",
    lineHeight: "18px",
    fontWeight: 500,
    color: "#C5CBC9",
  },

  content: {
    padding: "48px 24px 24px",
  },

  cancelDeleteButton: {
    display: "flex",
    width: "161px",
    height: "44px",
    alignItems: "center",
    justifyContent: "center",
    border: "1px solid #013D4F",
    borderRadius: "8px",
    cursor: "pointer",
    backgroundColor: "transparent",
    color: "#013D4F",
    fontFamily: "Roboto, sans-serif",
    fontSize: "16px",
    fontWeight: 700,
  },

  deleteUserButton: {
    display: "flex",
    width: "110px",
    height: "44px",
    alignItems: "center",
    justifyContent: "center",
    border: "none",
    borderRadius: "8px",
    cursor: "pointer",
    backgroundColor: "#4FC8EC",
    color: "#FFF",
    fontFamily: "Roboto, sans-serif",
    fontSize: "16px",
    fontWeight: 700,
  },

  singleDealershipHeader: {
    display: "flex",
    flexWrap: "nowrap",
    alignItems: "center",
    gap: "12px",
    marginBottom: "48px",
  },

  singleDealershipContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "12px",
  },

  singleDealershipTitle: {
    fontFamily: "Gotham, sans-serif",
    fontSize: "30px",
    lineHeight: "40px",
    fontWeight: 300,
    color: "#013D4F",
  },

  singleDealershipDescription: {
    fontFamily: "Gotham, sans-serif",
    fontSize: "18px",
    lineHeight: "18px",
    fontWeight: 300,
    color: "#4B4C4B",
  },
};
// Customizable Area End
