import React from "react";
// Customizable Area Start
import { 
    Avatar, 
    Box, 
    Button,
    Card,
    CardContent,
    Container,
    Divider,
    Menu,
    MenuItem,
    Modal,
    Typography,
    createTheme,
    styled
 } from "@material-ui/core";
 import MenuRoundedIcon from '@material-ui/icons/MenuRounded';
 import {
  notifications
} from "../../dashboard/src/assets";
import { logo } from "../../user-profile-basic/src/assets"
import ThumbUpAltOutlinedIcon from '@material-ui/icons/ThumbUpAltOutlined';
import {userDesign} from "../../multilevelapproval/src/assets"
import { CloseOutlined, MailOutlineOutlined } from "@material-ui/icons";
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import ExitToAppOutlinedIcon from '@material-ui/icons/ExitToAppOutlined';
const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});
// Customizable Area End

import BlockController, {
  Props,
} from "./ConfirmationController";

export default class Block extends BlockController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  headerr = () => {
    return (
      <Box sx={webStyles.header}>
        <Box sx={webStyles.headerLeftSide}>
          <Button data-test-id="menu" style={webStyles.menuButton}>
            <MenuRoundedIcon style={{ color: '#C5CBC9' }} />
          </Button>

          <Box sx={webStyles.logo} style={{ marginLeft: '-10px' }}>
            <img style={{ marginBottom: '5px', height:'24px',width:'52px', }} src={logo} alt="vitu logo" />
          </Box>
        </Box>

        <Box sx={webStyles.headerRightSide}>
          <Box
            sx={webStyles.notificationsButton}
          >
            <img alt="notifications button" src={notifications}  />
          </Box>

          <Box style={webStyles.userAvatar}>
            <Avatar
              className="avatar"
              variant="square"
            onClick={(event)=>this.handleOpenMenu(event)}
              src={this.state.profilePhoto ? this.state.profilePhoto : ''}
              style={{
                height: "102%",
                width: "102%",
                backgroundColor: 'rgb(240, 250, 250)', 
                borderRadius: "24px",   
                color: '#013D4F'
              }}
            >
               {this.state.initials}
            </Avatar>
          </Box>
        </Box>
      </Box>
    )}

  profileMenuDropdownn=()=>{
    return(
      <Menu
      
      anchorEl={this.state.anchorEl}
      id="simple-menu"
      keepMounted
      onClose={this.handleCloseMenu}
      open={Boolean(this.state.anchorEl)}
      PaperProps={{
        style: {
          borderRadius: 8, 
          width: '270px', 

          padding: '10px 0'
        },
      }}
    >
     
      <Box display="flex" alignItems="center"   p={2}  pt={0} >
        <Avatar 
          style={{
           height:'57px',
    
           backgroundColor: 'rgb(240, 250, 250)',
           color: '#013D4F',
           width:'57px',
           }} 
           src={this.state.profilePhoto ? this.state.profilePhoto : ''}>
          {this.state.initials}
        </Avatar>
  
        <Box ml={2}>
          <Typography style={{ fontSize:'20px',  color:'#4B4C4B', fontWeight:400,fontFamily:'Roboto' }}>
            {this.state.fullName}
          </Typography>
          <Typography style={{ fontWeight: 400,  fontFamily:'Roboto', fontSize:'16px', color:'#C5CBC9', }}>
            Dealer/sp Group Name
          </Typography>
        </Box>
      </Box>
  
    <Box style={{marginTop:'7px',gap:'15px',}}>
      <MenuItem data-test-id="editprofile" onClick={this.gotoEditProfilee} style={{gap:'15px'}} >
        <PersonOutlineOutlinedIcon style={{color:'#013D4F'}}/>
        <Typography style={{fontFamily:'Roboto',fontWeight:400, color:'#4B4C4B', fontSize:'16px'}}>Edit Profile</Typography>
      </MenuItem>
  
      <MenuItem  data-test-id="contactusprofile" style={{marginTop:'15px', gap:'15px'}} onClick={this.gotocontactUs}>
        <MailOutlineOutlined style={{color:'#013D4F'}}/>
        <Typography style={{fontFamily:'Roboto',fontSize:'16px', color:'#4B4C4B',  fontWeight:400}} >Contact Us</Typography>
      </MenuItem>
  
      <MenuItem style={{marginTop:'15px',gap:'15px'}}>
        <SettingsOutlinedIcon style={{color:'#013D4F'}}/>
        <Typography style={{fontFamily:'Roboto',fontSize:'16px', color:'#4B4C4B', fontWeight:400}}>Settings</Typography>
      </MenuItem>
  
      <Divider style={{marginTop:'10px',   width:'230px',marginLeft:'20px'}}/>
  
      <MenuItem data-test-id="logoutprofile"  style={{gap:'15px',marginTop:'25px'}} onClick={this.toggleLogoutModal}>
        <ExitToAppOutlinedIcon style={{color:'#013D4F',transform: 'rotateY(180deg)'}}/>
        <Typography style={{fontWeight:400, fontSize:'16px', color:'#4B4C4B', fontFamily:'Roboto'}}>Log Out</Typography>
      </MenuItem>
    </Box>
    </Menu>
    )
  }

  renderServiceCard = (serviceName:string, serviceType:string, servicePrice:string, rating:string)=> {
    return (
      <Card variant="outlined" style={{ marginBottom: '16px', maxWidth:'640px', borderColor:'#F0FAFA', borderRadius:'12px' }}>
        <CardContent style={{padding:'20px'}}>
            <Box style={{display:'flex', alignItems:'center', alignContent:'center'}}>
                <Typography style={{fontSize:'16px', color:'#4B4C4B', fontWeight:400}}>{serviceName}</Typography>
                <Box display="flex" alignItems="center" style={{backgroundColor:'#F0FAFA', marginLeft:'15px', padding:'5px', borderRadius:'5px'}}>
                    <ThumbUpAltOutlinedIcon style={{ marginRight: '4px', height:'15px' }} />
                    <Typography style={{fontSize:'12px', color:'#4B4C4B', fontWeight:500}}>{rating} Positive</Typography>
                </Box>
            </Box>
            <Box style={{display:'flex', flexDirection:'row', justifyContent:'space-between', alignItems:'center'}}>
                <Typography style={{fontSize:'18px', fontWeight:500, fontFamily:'Roboto', color:'#4B4C4B'}}>{serviceType}</Typography>
                <Typography style={{ fontSize:'18px', fontWeight:500 , color:'#4B4C4B', fontFamily:'Roboto'}}>
                    {servicePrice}
                </Typography>
            </Box>
        </CardContent>
      </Card>
    );
  }

  logoutModalrender = ()=>{
    return(
      <Modal
      open={this.state.isOpenLogoutModal}
      onClose={this.toggleLogoutModal}
      aria-describedby="simple-modal-description"
      aria-labelledby="simple-modal-title"
      style={{  
        display: 'flex',
      
      justifyContent: 'center',
      alignItems: 'center',
   }}
    >
      <StyledModalBoxx >

        <Box display={'flex'}  justifyContent={'end'}style={{width:'545px'}} >
          <Button onClick={this.toggleLogoutModal} data-test-id="closebtnn"><CloseOutlined/></Button>
        </Box>
  
       <Box>
        <Box>
          <Typography style={{color:'#013D4F', fontSize:'24px', fontWeight:500, marginBottom:'12px', fontFamily:'Roboto'}}>
            Log Out
          </Typography>
        </Box>
        <Typography style={{color:'#4B4C4B',fontFamily:'Gotham light',fontSize:'18px',fontWeight:300, }}>Are you sure you want to log out?</Typography>
       </Box>
  
       <Divider style={{ height:'2px', width:'112%', marginLeft:'-33px',backgroundColor:'#F0FAFA', marginTop:'30px'}}/>
       
       <Box style={{display:'flex', marginTop:'20px',justifyContent:'end'}}>
        <Button variant="outlined"  data-test-id="cancellogout" onClick={this.toggleLogoutModal} style={{height:'56px', border:'1px solid #013D4F', width:'156px', marginRight:'15px', borderRadius:'8px'}}>
          <Typography style={{color:'#013D4F',fontSize:'16px', fontWeight:700, textTransform:'none',fontFamily:'Roboto'}}>
            Cancel
          </Typography>
        </Button>
        <Button variant="contained"  data-test-id="finallogout" onClick={this.gotologout} style={{ width:'156px',height:'56px', backgroundColor:'#4FC8EC', borderRadius:'8px'}}>
          <Typography style={{color:'white',  fontSize:'16px', textTransform:'none', fontFamily:'Roboto', fontWeight:700, }}>
            Log Out
          </Typography>
        </Button>
       </Box>
      </StyledModalBoxx>
  </Modal>
    )
  }
  // Customizable Area End

  render() {
    return (
        // Customizable Area Start
        <Box height={'100vh'} style={{ overflowX: 'hidden', position: 'relative' , display:'flex'}}>
        <Box style={{display:'flex', flexDirection:'column', width:'100%'}}>
            {this.headerr()}
            {this.profileMenuDropdownn()}
            {this.logoutModalrender()}
          
            <Box style={{display:'flex', justifyContent:'space-between', width:'100%'}}>
                <Box style={{width:'25%'}}></Box>

                <Box className="mainbox" style={{width:'50%'}}>
                    <Container style={{display:'flex', flexDirection:'column', maxWidth:'640px', justifyContent:'center'}}>
                            <Typography  gutterBottom align="center" style={{ marginTop: '40px', color:'#013D4F', fontSize:'30px', fontFamily:'Gotham light' }}>
                                Request Sent Successfully
                            </Typography>
                            <Typography align="center" style={{ marginBottom: '40px', color:'#4B4C4B', fontSize:'18px', fontFamily:'Gotham'  }}>
                                Your request has been sent to the selected Service Provider(s). You'll be notified when one has accepted your deal.
                            </Typography>


                            {this.renderServiceCard('Blue Tree Services', 'Full Service', '$45.00', '86%')}
                            {this.renderServiceCard('SmartLock Solutions', 'Runner Service', '$36.00', '90%')}
                            {this.renderServiceCard('ClimateCare Heating Services', 'Onsite', '$85.00', '93%')}

        
                            <Box display="flex" justifyContent="center" mt={3} style={{gap:'10px'}}>
                                <Button data-test-id='gotocreatedeal' variant="outlined" fullWidth style={{borderRadius:'8px', height:'56px'}} onClick={this.gotocreateDeal}>
                                    <Typography style={{textTransform:'none', fontSize:'16px', fontWeight:500, color:'#4B4C4B'}}>
                                        Create Another Deal
                                    </Typography>
                                </Button>
                                <Button data-test-id='gotodealdashboard' onClick={this.gotoDashboard} fullWidth variant="contained" style={{backgroundColor:'#4FC8EC', borderRadius:'8px', boxShadow:'none'}}>
                                    <Typography style={{textTransform:'none', fontSize:'16px', fontWeight:700, color:'#FFFFFF'}}>
                                        Return to Deal Dashboard
                                    </Typography>
                                </Button>
                            </Box>

                    <Box
                        borderColor="#F0FAFA"
                        border={1}
                        borderRadius={8}
                        padding={"40px"}
                        marginTop="40px"
                    >
                        <Typography style={{fontSize:'18px', color:'#013D4F', fontWeight:400}}>
                            Attention Please
                        </Typography>
                        <Typography style={{fontSize:'14px', color:'#C5CBC9', fontWeight:400}}>
                            When a Service Provider accepts this request, we will debit your account and hold the service fees. The fee details will be available for you to see in the details of this deal.
                        </Typography>
                    </Box>
                </Container>
                </Box>
            
                <Box
                style={{
                height:'100%',
                    display:'flex',
                    alignItems:'flex-end',
                    justifyContent:'flex-end',
                    right: 0,
                    zIndex: 1, 
                    pointerEvents: 'none',
                    width:'25%'
                }}
                >
                <img
                    src={userDesign}
                    alt="Design"
                    style={{
                    height: '400px',
                    width:'400px',
                    }}
                />
                </Box>
            </Box>
    </Box>

      </Box>
        // Customizable Area End
    );
  }
}

// Customizable Area Start
const StyledModalBoxx = styled(Box)({
  width: '512px',
  
  boxShadow: theme.shadows[5],
  backgroundColor:'#FFFFFF',
  border: '1px solid #FFFFFF',
  padding: theme.spacing(2, 4, 3),
  borderRadius:'8px',
})
interface Styles {
    [key: string]: any;
  }
const webStyles: Styles = {
    
    header: {
      flexWrap: "nowrap",
      alignItems: "center",
      display: "flex",
      borderBottom: "1px solid #F0FAFA",
      justifyContent: "space-between",
      
      padding: "12px 32px",
    },
  
    container: {
        flexDirection: "column",
        height: "100%",
        
        width: "100%",
        display: "flex",
      },
  
    menuButton: {
      display: "flex",
      alignItems: "center",
      height: "24px",
      justifyContent: "center",
      backgroundColor: "transparent",
      
      width: "24px",
      cursor: "pointer",
      border: "none",
      padding: "unset",
    },
  
    headerLeftSide: { display: "flex",
      flexWrap: "nowrap",
      gap: "0px",
      alignItems: "center",
    },
  
    logo: {width: "52px"},
  
    notificationsButton: {
      
      position: "relative",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
     
      height: "24px",
      width: "24px",
      cursor: "pointer",
    },
  
    headerRightSide: {display: "flex",
      flexWrap: "nowrap",
      gap: "24px",
      alignItems: "center"
    },
  
    userAvatar: {
      height: "40px",
      
      borderRadius: "50%",
      width: "40px",
    },
  
    content: {
     
      marginLeft: "120px",
      width: "100%",
    },

    buttonText: {
        fontFamily: "Roboto, sans-serif",
        fontSize: "14px",
        color: "#013D4F",
        fontWeight: 500,
      },
  
    main: {
      display: "flex",
      position: "relative",
      flexWrap: "nowrap"
    },
  
  }
// Customizable Area End