// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { UserNormalized } from "./MyTeamController.web";

export const webConfigJSON = require("./config.js");

export interface Props {
  usersData: UserNormalized[]
  page: number
  itemsPerPage: number
  handleCheck: (itemId: string) => void
  handleEditUser: (userId: string) => void
  setDeletePromptState: () => void
}
interface S {
  users: UserNormalized[]
}
interface SS {}

export default class UsersTableController extends BlockComponent<Props, S, SS> {
  constructor(props: Props) {
    super(props);

    this.state = {
      users: []
    };
  }

  componentDidUpdate(prevProps: Readonly<Props>): void {
    const {usersData, page, itemsPerPage, } = this.props;

    if(prevProps.itemsPerPage !== itemsPerPage || prevProps.usersData !== usersData || prevProps.page !== page) {
      this.setCurrentPage()
    }
  }

  setCurrentPage = () => {
    const {page, itemsPerPage} = this.props;
    const currentPageUsers = this.props.usersData.slice(page * itemsPerPage - itemsPerPage, page * itemsPerPage)

    this.setState({users: currentPageUsers})
  }

  handleDeleteUser = (id: string) => {
    this.props.handleCheck(id)
    this.props.setDeletePromptState()
  }

}
// Customizable Area End