import {
  Box,
  IconButton,
  Menu,
  MenuItem,
  WithStyles,
  createStyles,
  withStyles,
  Typography,
} from "@material-ui/core";
import { MoreHoriz } from "@material-ui/icons";
import clsx from "clsx";
import React from "react";
import { v4 as uuidv4 } from "uuid";
import { groupAdd, groupRemove } from "./assets";

export enum ServiceProviderMenuAction {
  WithdrawAll = "Withdraw All",
  HireAllNew = "Hire All New",
}

export interface ServiceProviderMenuOption {
  id: string;
  name: string;
  value: ServiceProviderMenuAction;
  icon: React.ReactNode;
}

export interface Props extends WithStyles {
  onSelectAction(value: ServiceProviderMenuAction): void;
}

const styles = createStyles({
  statusExpand: {
    marginLeft: "auto",
  },

  menu: {
    "& .MuiPaper-elevation8": {
      boxShadow: "0px 8px 32px 0px #0000000F",
    },

    "& > div:nth-child(3)": {
      borderRadius: "8px",
    },

    "& ul": {
      width: 180,
      padding: "15px 10px",
    },
  },

  actionLink: {
    display: "flex",
    alignItems: "center",
    textDecoration: "none",
    padding: "8px 16px",
    width: "100%",

    "&:hover": {
      backgroundColor: "#E8F8FD",
      borderLeft: "3px solid #4FC8EC",
      color: "#FFFFFF",
    },

    "& svg": {
      fill: "#333333",
    },
  },

  actionItemIcon: {
    width: 18,
    height: 18,
  },

  actionItemName: {
    marginLeft: 8,
    color: "#333333",
  },

  actionItem: {
    height: "36px",
    padding: 0,
    borderBottom: "1px solid #F0FAFA",
  },

  menuTypo: {
    marginLeft: 10,
    fontFamily: "Roboto",
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "22px",
    textAlign: "left",
  },

  catalogMenuButton: {
    padding: 0,
  },

  hireAllNewText: {
    color: "#4FC8EC",
  },
});

export class ServiceProviderMenu extends React.Component<
  Props,
  {
    statusMenu: HTMLElement | null;
  }
> {
  constructor(props: Props) {
    super(props);

    this.state = {
      statusMenu: null,
    };
  }

  menu = [
    {
      id: uuidv4(),
      name: "Withdraw All",
      value: ServiceProviderMenuAction.WithdrawAll,
      icon: <img src={groupRemove}></img>,
      dataTestID: "withdraw-all-menu-item",
    },

    {
      id: uuidv4(),
      name: "Hire All New",
      value: ServiceProviderMenuAction.HireAllNew,
      icon: <img src={groupAdd}></img>,
      dataTestID: "hire-all-new-menu-item",
    },
  ];

  openFilter = (event: any) => {
    this.setState({
      statusMenu: event.currentTarget,
    });
  };

  closeFilter = () => {
    this.setState({
      statusMenu: null,
    });
  };

  onChangeStatus = (value: ServiceProviderMenuAction) => {
    this.props.onSelectAction(value);

    this.closeFilter();
  };

  render(): React.ReactNode {
    const { classes } = this.props;
    const { statusMenu } = this.state;

    const id = uuidv4();

    return (
      <Box className={classes.detailStatus}>
        <Box
          id={`filter-button-${id}`}
          aria-controls={statusMenu ? `filter-menu-${id}` : undefined}
          aria-haspopup="true"
          aria-expanded={statusMenu ? "true" : undefined}
          onClick={this.openFilter}
        >
          <IconButton
            className={classes.catalogMenuButton}
            data-test-id="more-icon"
          >
            <MoreHoriz />
          </IconButton>
        </Box>

        <Menu
          className={classes.menu}
          id={`filter-menu-${id}`}
          anchorEl={statusMenu}
          open={Boolean(statusMenu)}
          getContentAnchorEl={null}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          transformOrigin={{ vertical: "top", horizontal: "right" }}
          onClose={this.closeFilter}
          MenuListProps={{
            className: classes.detailStatus,
            "aria-labelledby": `filter-button-${id}`,
          }}
        >
          {this.menu.map((option) => {
            return (
              <MenuItem className={clsx(classes.actionItem)} key={option.id}>
                <Box
                  className={clsx(classes.actionLink)}
                  data-test-id={option.dataTestID}
                  onClick={() => this.onChangeStatus(option.value)}
                >
                  {option.icon}
                  <Typography
                    className={clsx(classes.menuTypo, {
                      [classes.hireAllNewText]: option.name === "Hire All New",
                    })}
                  >
                    {option.name}
                  </Typography>
                </Box>
              </MenuItem>
            );
          })}
        </Menu>
      </Box>
    );
  }
}

export default withStyles(styles)(ServiceProviderMenu);
