Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.PostAPiMethod = "POST";
exports.exampleApiContentType = "application/json";

exports.PostApiMethodType = "GET";
exports.patchPostAPiMethod = "PATCH";
exports.postGetUrl = "posts/posts";
exports.postContentType = "application/json";
exports.deletePostAPiMethod = "DELETE";
exports.CategoryGetUrl = "categories/categories";
exports.btnExampleTitle = "CLICK ME";
exports.getAllCatergoryEndPoint = "categories/categories";

exports.Category = "Category";
exports.Description = "Description";
exports.Price = "Price";
exports.ProductName = "Product Name";
exports.CreatePost = "Create Post";
exports.UpdatePost = "Update Post";
exports.Error = "Error";
exports.FieldsErrorMassage = "Please enter all mandatory fields";
exports.Warning = "Warning";
exports.MessageForDelete = "Are you sure for delete this post?";
exports.PostName = "Post Name";
exports.Cost = "Cost";
exports.PostID = "Post ID";
exports.Delete = "Delete";
exports.Edit = "Edit";
exports.NewPost = "Add new Post";
exports.PostUpdated = "Post is updated!";
exports.PostCreated = "Post is created!";

exports.createDealContentType = "application/json";
exports.createDealAPiEndPoint="/bx_block_posts/deals"
exports.createDealAPiMethod="POST"
exports.getDecodeAPiMethod="GET"
exports.getDecodeApiEndPoint="/bx_block_posts/deals/decode_vin?vin="
exports.getSavedDealApiMethod="GET"
exports.getSavedDealApiEndPoint="/bx_block_posts/deals/get_saved_deals"
exports.getDealInformationApi="/bx_block_posts/deals/"
exports.getDealInformationMethod="GET"
exports.getMultipleDealershipApiMethod="GET"
exports.getMultipleDealershipApiEndPoint="/bx_block_profile/dealer_informations/get_dealerships?page=1&per_page=10"
exports.autopopulateSellerZipApi="/bx_block_profile/dealer_informations/get_seller_zip/";

exports.getUserUrl = "account_block/accounts/get_user"
// Customizable Area End