import React, { Component } from 'react';
import {
    WithStyles,
    createStyles,
    withStyles,
    Box,
    Typography,
    Popover,
    Checkbox,
  } from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
export interface Props extends WithStyles {
    children?: React.ReactNode;
}

const styles = createStyles({
      infoIcon: {
        color: "#4B4C4B",
        backgroundColor: "#ffffff"
      },
      
      popover: {
        pointerEvents: 'none',
      },

      paper: {
        borderRadius: "8px",
        marginTop: "5px",
        boxShadow: "0px 8px 32px 0px #0000000F"
        
      },

      infoBox: {
        width: '236px',
        padding: '16px',
        opacity: '0px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      },
});

class CustomInfoComponent extends Component<Props, {
    anchorEl: any
}> {
  constructor(props: any) {
    super(props);

    this.state = {
        anchorEl: false,
    };
  }

  render() {
    const { classes, children } = this.props;
    const { anchorEl } = this.state;
    const handlePopoverOpen = (event: any) => {
      this.setState({ anchorEl: event.currentTarget || false });
    };

    const handlePopoverClose = () => {
      this.setState({ anchorEl: false });
    };

    const open = Boolean(this.state.anchorEl);
    return (
      <>
        <Box>
          <Typography
            aria-owns={open ? 'mouse-over-popover' : undefined}
            aria-haspopup="true"
            onMouseEnter={handlePopoverOpen}
            onMouseLeave={handlePopoverClose}
          >
            <InfoOutlinedIcon className={classes.infoIcon} />
          </Typography>
        </Box>
        <Popover
          id="mouse-over-popover"
          className={classes.popover}
          classes={{
            paper: classes.paper,
          }}
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus
        >
            <Box className={classes.infoBox}>
            {children}
            </Box>
        </Popover>
      </>
    );
  }
}
export default withStyles(styles)(CustomInfoComponent);
