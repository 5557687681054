export const edit = require("../assets/edit.png");
export const vitu = require("../assets/vitu_logo.png");
export const home = require("../assets/home.svg");
export const bar = require("../assets/bar.svg");

export const allDeals = require("../assets/all_deals.svg");
export const bell = require("../assets/notifications.svg");
export const menu = require("../assets/menu.svg");
export const avatar = require("../assets/avatar.png");
export const myTeam = require("../assets/my_team.svg");
export const deal = require("../assets/deal.png");

export const doller = require("../assets/doller.svg");
export const calendar = require("../assets/calendar.jpeg");




