export const logo = require("../assets/logo.png");
export const profileBackground = require("../assets/profile_background.png");
export const emptyPhoto = require("../assets/empty_photo.png");
export const arrow = require("../assets/arrow.svg");
export const doneStep = require("../assets/image_check_circle_outline_24px.png");
export const infoIcon = require("../assets/Info.svg");
export const mapIcon = require("../assets/map.png");
export const closeBtn = require("../assets/image_close_24px.png");
export const searchIcon = require("../assets/image_search.png");
export const customMarker = require("../assets/Spotlight Marker.png");
export const detailsArrow = require("../assets/image_keyboard_arrow_down_24px.png");
export const emptyProfile = require("../assets/profile_empty.svg");
export const emptyCheckbox = require("../assets/checkbox.svg");
export const filledCheckbox = require("../assets/filled_checkbox.svg");
export const flagUsa = require("../assets/flagusa.svg");
export const arrowTop = require("../assets/arrowtop.svg");
export const backupImage = require("../assets/backupImage.png");
export const fileUpload = require("../assets/view_upload.svg");
export const pdfFile = require("../assets/pdf_file.svg");
export const finalbg = require("../assets/finalbg.svg");
export const jpegFile = require("../assets/jpegFile.svg") 
export const pngFile = require("../assets/pngFile.svg")
export const invitationSuccess = require("../assets/invitation_success.svg");
export const pencilGrey = require("../assets/pencil_grey.svg");
export const trashGrey = require("../assets/trash_grey.svg");
export const blackClose = require("../assets/new_close_icon.svg")
export const closeIcon = require("../assets/close_icon_for_doc.svg")
export const hamburger_icon_selected = require("../assets/hamburger_icon_selected.png")
export const home = require("../assets/home.png")
export const car = require("../assets/car.png")
export const people = require("../assets/people.png")