import React, { useEffect, useRef, useState } from "react";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { useRunEngine } from "../../utilities/src/hooks/useRunEngine";
import { useBlockHelpers } from "../../utilities/src/hooks/useBlockHelpers";

import OrderManagementView from "./OrderManagementView";

// Customizable Area Start
import { Order } from "./types";
import { truncate } from "fs";
interface Statename {
  name: string;
  code: string;
}
// Customizable Area End

export const configJSON = require("./config");

export interface ViewProps {
  testID: string;
  // Customizable Area Start
  orders: Order[];
  loading: boolean;
  hideKeyboard: () => void;
  openCreateModal: () => void;
  isVisibleCreateModal: boolean;
  catalogueId: string;
  setCatalogueId: (text: string) => void;
  catalogueVariantId: string;
  setCatalogueVariantId: (text: string) => void;
  quantity: string;
  setQuantity: (text: string) => void;
  onSubmitCreateOrder: () => void;
  navigateToOrderDetail: (orderId: string) => void;
  openAddItemModalHandler: (orderId: number) => void;
  resetCreateOrderModal: () => void;
  selectedOrderId: null | number;

  activeStep: number;
  setActiveStep: (activeStep: number) => void;
  agreed: boolean;
  setAgreed: (agreed: boolean)=>void;
  handleNext: () => void;
  handleBack: () => void;
  handleFillMyself: () => void;
  openStepper: boolean;
  setOpenStepper: (agreed: boolean)=>void;
  createDeal: boolean;
  setCreateDeal: (agrred: boolean)=>void;

  fromState: string | null;
  toState: string | null;
  setFromState: (a:string | null)=>void;
  setToState: (a: string | null)=>void;
  handleSubmit: (e: React.FormEvent, fromState:string, toState:string)=>void;
  states2 : Statename[];
  toStateCode: string | null,
  setToStateCode: (a:string | null)=>void
  disable: boolean;
  setDisable: (agrred: boolean)=>void;
  getStatesAPI: ()=>void;
  getColor: (index: number)=>string;
  getFromStateOptions : () =>string[];
  getToStateOptions : () =>string[];
  // Customizable Area End
}

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

const subscribedMessages = [
  // Customizable Area Start
  MessageEnum.RestAPIResponceMessage,
  MessageEnum.SessionResponseMessage,
  // Customizable Area End
];

const OrderManagement: React.FC<Props> = (props) => {
  // Customizable Area Start
  const getOrdersCallId = useRef("");
  const createOrdersCallId = useRef("");

  const [isVisibleCreateModal, setIsVisibleCreateModal] =
    useState<boolean>(false);
  const [catalogueId, setCatalogueId] = useState<string>("");
  const [catalogueVariantId, setCatalogueVariantId] = useState<string>("");
  const [quantity, setQuantity] = useState<string>("");
  const [selectedOrderId, setSelectedOrderId] = useState<null | number>(null);

  const [orders, setOrders] = useState<Order[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const token = useRef<string>("");

  const [activeStep, setActiveStep] = React.useState(1);
  const [agreed, setAgreed] = React.useState(false);
  const [openStepper, setOpenStepper] = React.useState(false)
  const [createDeal, setCreateDeal] = React.useState(true);
  const [states2, setStates2] = React.useState<Statename[]>([])
  const getStatesId = useRef("");
  const [fromState, setFromState] = useState<string | null>(null);
  const [toState, setToState] = useState<string | null>(null);
  const [toStateCode, setToStateCode] = useState<string | null>(null);
  const [disable, setDisable] = useState<boolean>(true);
  // Customizable Area End

  useEffect(() => {
    setReceiveCallback(receive);

    subscribedMessages.forEach((message) => subscribe(message));

    // Customizable Area Start
    getToken();
    getStatesAPI();
    // Customizable Area End

    return () => {
      subscribedMessages.forEach((message) => unsubscribeFromMessage(message));
    };
  }, []);

  const receive = (from: string, message: Message) => {
    // Customizable Area Start
    debugLog("API Message Received by me", message);
    switch (message.id) {
        case getName(MessageEnum.SessionResponseMessage):
            handleSessionResponse(message);
            break;
        case getName(MessageEnum.RestAPIResponceMessage):
            handleRestApiResponse(message);
            break;
        default:
    }
    // Customizable Area End
  };

  // Customizable Area Start
  const handleSessionResponse = (message: Message) => {
    const resToken = message.getData(getName(MessageEnum.SessionResponseToken));
    debugLog("TOKEN", resToken);
    if (resToken) {
        token.current = resToken;
        getListOfOrders();
    } else {
        alert(configJSON.loginAlertMsg);
    }
};

const handleRestApiResponse = (message: Message) => {
    const { apiRequestCallId, responseJson } = extractNetworkResponse(message);
    setLoading(false);
    if (responseJson) {
        switch (apiRequestCallId) {
            case getStatesId.current:
                setStates2(responseJson.states);
                break;
            case getOrdersCallId.current:
                if (responseJson.data) {
                    setOrders(responseJson.data);
                    getOrdersCallId.current = "";
                }
                break;
            case createOrdersCallId.current:
                if (responseJson.data) {
                    resetCreateOrderModal();
                    getListOfOrders();
                } else if (responseJson?.msg) {
                    alert(JSON.stringify(responseJson.msg));
                }
                createOrdersCallId.current = "";
                break;
            default:
        }
    }
    if (responseJson?.errors) {
        alert(JSON.stringify(responseJson.errors));
    }
};

  const getStatesAPI = async() => {
    const headers = {
       //'token': await getStorageData('token'),
       "Content-Type": configJSON.apiContentType,
    };
    
    sendNetworkRequest(
      getStatesId,
      configJSON.getStatesMethod,
      configJSON.getStateEndPoint,
      headers,
    );
  };

  const steps = ['Filing Method', 'Transaction Fee', 'States', 'Form Information', 'Downloadable forms'];
  const handleSubmit = (event: React.FormEvent, fromState:string, toState: string) => {
    event.preventDefault();
    if (fromState && toState) {
      handleNext();
    } else {
      alert('Please select both states');
    }
  };
  const handleNext = () => {
    if (activeStep < steps.length - 1) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    if (activeStep > 0) {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
    if(activeStep<=1){
      setOpenStepper(false);
      setActiveStep(1);
      setCreateDeal(true);
    }
  };

  const handleFillMyself = ()=>{
    setOpenStepper(true);
    setCreateDeal(false);
  }

  const getColor = (index: number) => {
    if (index < activeStep) {
      return '#26BBB3';
    } else if (index == activeStep) {
      return '#013D4F';
    } else {
      return '#C5CBC9';
    }
  };


  const getFromStateOptions = ()=> states2.map(state => state.name).filter(name => name !== toState);
  const getToStateOptions = () => states2.map(state => state.name).filter(name => name !== fromState);

  const {
    sendBlockMessage,
    sendNetworkRequest,
    setReceiveCallback,
    subscribe,
    debugLog,
    unsubscribeFromMessage,
  } = useRunEngine();

  const { extractNetworkResponse, hideKeyboard, isPlatformWeb } =
    useBlockHelpers();

  const getToken = () => {
    const message: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    sendBlockMessage(message);
  };

  useEffect(() => {
    if (props.navigation.getParam("orderDeleted", null) && !isPlatformWeb()) {
      getListOfOrders();
    }
  }, [props.navigation.getParam("orderDeleted", null)]);

  const getListOfOrders = () => {
    setLoading(true);
    const headers = {
      "Content-Type": configJSON.apiContentType,
      token: token.current,
    };

    sendNetworkRequest(
      getOrdersCallId,
      configJSON.listOfOrdersMethod,
      configJSON.listOfOrdersEndPoints,
      headers
    );
  };

  const onSubmitCreateOrder = () => {
    const headers = {
      "Content-Type": configJSON.apiContentType,
      token: token.current,
    };

    const order_item = {
      catalogue_id: Number(catalogueId),
      catalogue_variant_id: Number(catalogueVariantId),
      quantity: Number(quantity),
    };

    const httpBody =
      selectedOrderId === null ? order_item : { order_items: [order_item] };

    const endPoint =
      selectedOrderId === null
        ? configJSON.createOrderEndPoint
        : configJSON.createOrderItemInOrderEndPoint.replace(
            ":id",
            selectedOrderId
          );

    sendNetworkRequest(
      createOrdersCallId,
      configJSON.createOrderMethod,
      endPoint,
      headers,
      httpBody
    );
  };

  const openCreateModal = () => {
    setIsVisibleCreateModal(true);
  };

  const resetCreateOrderModal = () => {
    setCatalogueId("");
    setCatalogueVariantId("");
    setQuantity("");
    setSelectedOrderId(null);
    setIsVisibleCreateModal(false);
  };

  const openAddItemModalHandler = (orderId: number) => {
    setSelectedOrderId(orderId);
    openCreateModal();
  };

  const navigateToOrderDetail = (orderId: string) => {
    props.navigation.navigate("OrderDetails", {
      orderId: orderId,
    });
  };
  // Customizable Area End

  const viewProps: ViewProps = {
    testID: "OrderManagementView",
    // Customizable Area Start
    orders,
    loading,
    hideKeyboard,
    openCreateModal,
    isVisibleCreateModal,
    catalogueId,
    setCatalogueId,
    catalogueVariantId,
    setCatalogueVariantId,
    quantity,
    setQuantity,
    onSubmitCreateOrder,
    navigateToOrderDetail,
    openAddItemModalHandler,
    resetCreateOrderModal,
    selectedOrderId,

    agreed,
    setAgreed,
    activeStep,
    setActiveStep,
    handleNext,
    handleBack,
    handleFillMyself,
    openStepper,
    setOpenStepper,
    createDeal,
    setCreateDeal,

    fromState,
    toState,
    setFromState,
    setToState,
    handleSubmit,
    states2,
    toStateCode,
    setToStateCode,
    disable,setDisable,
    getStatesAPI,
    getColor,
    getFromStateOptions,
    getToStateOptions
    // Customizable Area End
  };

  return <OrderManagementView {...viewProps} />;
};

export default OrderManagement;
