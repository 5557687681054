import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { getStorageData, removeStorageData } from "../../../framework/src/Utilities"
interface GetUserError {
  errors:[
    {
      token:string;
    }
  ]
}
export interface GetUserResponseSuccess {
  data: Data;
}
export interface Data {
  id: string;
  type: string;
  attributes: Attributes;
}
export interface Attributes {
  activated: boolean;
  country_code: string;
  email: string;
  full_phone_number: string;
  phone_number: string;
  type: string;
  full_name: string;
  created_at: string;
  updated_at: string;
  is_dealer_group: boolean;
  dealer_group_name?: null;
  role: string;
  profile_picture?: null;
  group_created_on?: null;
  is_deal_pending: boolean;
  deal_data?: null;
  solo_dealership_id: number;
}
// Customizable Area End

export const configJSON = require("./config");
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  profilePhoto:string | null | undefined;
  initials:string | null;
  anchorEl: null | HTMLElement;
  fullName:string;
    isSoloDealership:boolean;
    isOpenLogoutModal:boolean
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class UserProfileBasicController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  changeNameApiCallId:string="";
  getuserDataApiCallId:string=""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];
   
    this.state = {
      profilePhoto:'',
      initials:'',
      anchorEl: null,
      fullName:'',

      isSoloDealership:false,
      isOpenLogoutModal:false,
    };

    // Customizable Area End
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId && responseJson) {
        if(apiRequestCallId===this.getuserDataApiCallId){
          this.handleUserDataIncoming(responseJson);
        }
      }
    } 
    // Customizable Area End
  }

  // Customizable Area Start
  toggleLogoutModal=()=>{
    this.setState({isOpenLogoutModal: !this.state.isOpenLogoutModal})
    this.handleCloseMenu()
  }

  handleUserDataIncoming=(responseJson:GetUserError & GetUserResponseSuccess)=>{
    if(!responseJson.errors){
      this.setState({profilePhoto:responseJson.data.attributes.profile_picture ,fullName:responseJson.data.attributes.full_name, isSoloDealership: responseJson.data.attributes.is_dealer_group},
        ()=>{ this.getInitials()}
      )
    }
    else{
       this.getInitials()
    }
  }
  handleOpenMenu=( event: React.MouseEvent<HTMLDivElement, MouseEvent>)=>{
    this.setState({anchorEl:event.currentTarget})
  }
  handleCloseMenu=()=>{
    this.setState({anchorEl:null})
  }
  gotoEditProfilee=()=>{
    const routetogo = new Message(getName(MessageEnum.NavigationMessage));
    routetogo.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    routetogo.addData(getName(MessageEnum.NavigationTargetMessage), 'UserProfileBasicBlockWeb')
    this.send(routetogo);
  }
  gotocontactUs=()=>{
    const contact = new Message(getName(MessageEnum.NavigationMessage));
    contact.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    contact.addData(getName(MessageEnum.NavigationTargetMessage), 'Contactus')
    this.send(contact);
  }
  gotologout=()=>{
    removeStorageData('authToken')
    const newmsgg = new Message(getName(MessageEnum.NavigationMessage));
    newmsgg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    newmsgg.addData(getName(MessageEnum.NavigationTargetMessage), 'EmailAccountLoginBlock')
    this.send(newmsgg);
  }
  gotoDashboard=()=>{
    const dashboard = new Message(getName(MessageEnum.NavigationMessage));
    dashboard.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    dashboard.addData(getName(MessageEnum.NavigationTargetMessage), 'ProjectTemplatesDealDashboard')
    this.send(dashboard);
  }
  gotocreateDeal=()=>{
    const creatdeal = new Message(getName(MessageEnum.NavigationMessage));
    creatdeal.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    creatdeal.addData(getName(MessageEnum.NavigationTargetMessage), 'CreateDealForm')
    this.send(creatdeal);
  }


  getInitials = ()=>{
    if (this.state.fullName) {
      const nameArray = this.state.fullName.split(' ');
      const derivedInitials = nameArray.map(name => name.charAt(0)).join('').slice(0, 2).toUpperCase();
      this.setState({initials:derivedInitials})
    } else {
      this.setState({initials:null})
    }
  }

  async componentDidMount(){
    this.getUserDataFromBee();
  }

  getUserDataFromBee=async()=>{
    const newtoken = await getStorageData('authToken')
    const Webheader = {
      "Content-Type": 'application/json',
      "token": newtoken
    };
    const WebrequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getuserDataApiCallId = WebrequestMessage.messageId;   
    WebrequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
     'account_block/accounts/get_user'
    );
    WebrequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(Webheader)
    );
    WebrequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );
    runEngine.sendMessage(WebrequestMessage.id, WebrequestMessage);
  }

  // Customizable Area End

}