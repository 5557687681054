export const vitu = require("../assets/vitu_logo.png");
export const menu = require("../assets/menu.svg");
export const home = require("../assets/home.svg");
export const allDeals = require("../assets/all_deals.svg");
export const myTeam = require("../assets/my_team.svg");
export const notifications = require("../assets/notifications.svg");
export const avatar = require("../assets/avatar.png");
export const checkmark = require("../assets/checkmark.svg");
export const group = require("../assets/group.svg");
export const dealershipGroup = require("../assets/group_2.svg");
export const trash = require("../assets/trash.svg");
export const circleCheckmark = require("../assets/checkmark_in_circle.svg");
export const trashGrey = require("../assets/trash_grey.svg");
export const pencilGrey = require("../assets/pencil_grey.svg");
export const invitationSuccess = require("../assets/invitation_success.svg");
export const editUserSuccess = require("../assets/edit_user_success.svg");