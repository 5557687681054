export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const backarrow = require("../assets/backarrow.png");
export const cardblank = require("../assets/cardblank.png");
export const fillstar = require("../assets/fillstar.png");
export const empty = require("../assets/empty.png");
export const vitu = require("../assets/vitu_logo.svg");
export const menu = require("../assets/menu.svg");
export const home = require("../assets/home.svg");
export const allDeals = require("../assets/all_deals.svg");
export const myTeam = require("../assets/my_team.svg");
export const notifications = require("../assets/notifications.svg");
export const avatar = require("../assets/avatar.png");
export const searchIcon = require("../assets/image_search.png");
export const filterIcon = require("../assets/image_filter.png");
export const infoIcon = require("../assets/image_info.jpeg");
export const uploadIcon = require("../assets/image_upload.jpeg");
export const groupAdd = require("../assets/group_add.png");
export const groupRemove = require("../assets/group_remove.png");
export const spAvatar = require("../assets/sp_avatar.jpeg");
