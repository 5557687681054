Object.defineProperty(exports, '__esModule', {
  value: true
})

// Customizable Area Start
exports.httpGetMethod = 'GET'
exports.advancedsearchApiContentType = 'application/json'
exports.getAdvancedSearchApiEndPoint = 'advanced_search/search'
exports.getServiceProviderListApiEndPoint = 'bx_block_profile/service_provider_informations'
exports.errorTitle = 'Error'
exports.cardsServiceOption = [
  {key: 'runner', type: 'Runner Service', price: '$36.00' },
  {key: 'full_service', type: 'Full Service', price: '$45.00' },
  {key: 'on_site', type: 'Onsite', price: '$85.00' },
  {key: 'white_glove', type: 'White Glove', price: '$350.00' },
]
// Customizable Area End
