import React from "react";
// Customizable Area Start
import { Avatar, Box, Button, Divider, IconButton, Menu, MenuItem, Typography } from "@material-ui/core";
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import Badge from '@material-ui/core/Badge';
import CheckIcon from '@material-ui/icons/Check';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { styled } from '@material-ui/core/styles';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
// Customizable Area End

import BlockController, {
  Props,
} from "./PushnotificationsController";

export default class Block extends BlockController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  notificationdropdown=()=>{
    return(
        <Menu
          anchorEl={this.state.anchorEl}
          keepMounted
          open={Boolean(this.state.anchorEl)}
          onClose={this.handleClose}
          style={{ maxHeight: '700px'}}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
        <Box style={{paddingLeft:'20px', paddingRight:'20px'}}>
            <Box style={{display:'flex', justifyContent:'end'}}>
                <Button onClick={this.handleClose} data-test-id="closemodal" size="small" style={{ textTransform: 'none', marginTop:'20px', color:'#4B4C4B', fontSize:'14px', fontWeight:700, fontFamily:'Inter'}}>
                    <CheckIcon style={{marginRight:'5px'}}/> Mark all as read
                </Button>
            </Box>
            <Divider style={{marginTop:'10px'}}/>

         {this.state.notificationsRceived.map((notification, index) => (
            <React.Fragment key={notification.id}>
                <MenuItem
                onClick={this.handleClose}
                style={{ paddingTop: '5px', maxWidth: '500px', display: 'flex', alignItems: 'start', gap:'10px', marginTop:'20px'}}
                >
                <Box style={{ display: 'flex', alignItems: 'center' }}>
                    <Avatar style={{ backgroundColor: notification.avatarColor ?  notification.avatarColor : '#346472', fontFamily:'Roboto', fontSize:'18px', color:'white'}}>
                        {notification.avatarText}
                    </Avatar>
                </Box>

                <Box className="middlecontent"
                    style={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginLeft: '10px',
                    width: '350px',
                    wordWrap: 'break-word',
                    whiteSpace: 'normal',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    }}
                >
                    <Typography variant="body2" color="textPrimary">
                     
                    <span style={{ color: this.getColor(notification.isRead, '#4B4C4B',  '#4FC8EC'), fontWeight:500, fontSize:'16px', fontFamily:'Roboto' }}> {!notification.isRead &&  <FiberManualRecordIcon style={{height:'15px', padding:'0px'}}/>}{notification.title}</span>
                    <span style={{ marginLeft: '5px', color: '#C5CBC9', fontWeight:500, fontSize:'14px', fontFamily:'Roboto'}}>/ subtitle</span>
                    <span style={{ marginLeft: '5px', color: '#C5CBC9', fontWeight:500, fontFamily:'Roboto',fontSize:'14px', }}>/ #3456</span>
                    </Typography>
                    <Typography style={{ marginBottom: '8px', overflow: 'hidden', textOverflow: 'ellipsis', color: this.getColor(notification.isRead, '#989A98',  '#4B4C4B'), fontSize:'14px', fontWeight:400, fontFamily:'Roboto' }}>
                    {notification.description}
                    </Typography>
                </Box>

                <Box className="threedots" style={{ display: 'flex', alignItems: 'center', flexDirection:'column', justifyContent:'start', width:'35px'}}>
                    <MoreVertIcon style={{marginTop:'5px', color:'#4B4C4B'}}/>
                        <Typography variant="caption" color="textSecondary" style={{marginTop:'10px', color:'#989A98', fontSize:'14px', fontWeight:400, fontFamily:'Inter'}}>
                            {notification.timestamp}
                        </Typography>
                </Box>
                </MenuItem>
              {index < this.state.notificationsRceived.length - 1 && <Divider />}
            </React.Fragment>
          ))}

        </Box>
        </Menu>
    )
  }
  // Customizable Area End

  render() {
    return (
        // Customizable Area Start
        <Box>
            <IconButton color="inherit" onClick={(event)=>this.handleClick(event)} data-test-id="notificationclick">
                <StyledBadgee badgeContent=''>
                    <NotificationsNoneIcon style={{color:'#4B4C4B'}}/>
                </StyledBadgee>
            </IconButton>
            {this.notificationdropdown()}
        </Box>
        // Customizable Area End
        );
  }
}

// Customizable Area Start
const StyledBadgee = styled(Badge)({
    '& .MuiBadge-anchorOriginTopRightRectangle': {
      transform: 'scale(0.6)',
      transformOrigin: '100% 100%',
      backgroundColor: '#C43937',
      color: 'red',
      top: '-10px',
      right: '-1px',
    },
  });
// Customizable Area End
