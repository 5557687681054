// Customizable Area Start
import React, { CSSProperties } from "react";

import { Box, Avatar, Typography, styled } from "@material-ui/core";

import UsersTableController, { Props } from "./UsersTableController.web";
import { UserNormalized } from "./MyTeamController.web";
import { checkmark } from "./assets";
import moment from "moment";

interface Styles {
  [Key: string]: CSSProperties;
}

export default class UsersTable extends UsersTableController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { usersData, handleCheck, handleEditUser } = this.props;
    const currentPageData = usersData.slice();    
    
    return (
      <StyledContainer>
        <Box className="tableRow">
          <Box />
          <Box className="thCell">User</Box>
          <Box className="thCell">Email</Box>
          <Box className="thCell">Contact No.</Box>
          {usersData.some(user => user.dealerships?.split(",").length) ? <Box className="thCell">Dealerships</Box> : <Box />}
          <Box className="thCell">Role</Box>
          <Box className="thCell">Joined on</Box>
          <Box className="thCell">Actions</Box>
        </Box>
        {currentPageData.map((user: UserNormalized, i: number) => (
          <Box
            data-test-id={`user-${i}`}
            key={user.id}
            className="tableRow"
            
          >
            <Box className="trCell">
              <CheckboxContainer onClick={() => handleCheck(user.id)} data-test-id={`user-checkbox-${i}`}>
                  <input
                    type="checkbox"
                    checked={user.checked}
                    className="checkbox"
                  />
                  <span className="checkmark">
                    <img src={checkmark} alt="checkmark" />
                  </span>
                </CheckboxContainer>
            </Box>
            <Box className="trCell" style={{ gap: "12px" }}>
              <Avatar
                src={user.profilePicture}
                alt={user.name}
                variant="rounded"
                style={{ textTransform: "uppercase" }}
              >
                {(user.name || "")
                  .split(" ")
                  .map((string) => string[0])
                  .join("")}
              </Avatar>
              <Typography className="name">{user.name}</Typography>
            </Box>
            <Box className="trCell"><span style={{overflow: "hidden", textOverflow: "ellipsis"}}>{user.email}</span></Box>
            <Box className="trCell"><span style={{overflow: "hidden", textOverflow: "ellipsis"}}>{`+${user.contactNumber}`}</span></Box>
            {usersData.some(user => user.dealerships?.split(",").length) ? <Box className="trCell">{user.dealerships}</Box> : <Box />}
            <Box className="trCell" style={{ textTransform: "capitalize" }}>
              {user.dealerRole}
            </Box>
            <Box className="trCell">
              {moment(user.joinedOn).format("MMMM D, YYYY h:mm a")}
            </Box>
            <Box className="trCell actionsCell">
              <Typography data-test-id={`edit-user-${i}`} className="actionButton" onClick={() => handleEditUser(user.id)}>Edit</Typography>
              <Typography data-test-id={`delete-user-${i}`} className="actionButton" onClick={() => this.handleDeleteUser(user.id)}>Delete</Typography>
            </Box>
          </Box>
        ))}
      </StyledContainer>
    );
  }
}

const CheckboxContainer = styled(Box)({
  position: "relative",

  "& .checkbox": {
    appearance: "none",
    width: "20px",
    height: "20px",
    margin: 0,
    border: "1px solid #C5CBC9",
    borderRadius: "6px",
    cursor: "pointer",
  },

  "& .checkmark": {
    position: "absolute",
    top: "0",
    left: "0",
    display: "none",
    alignItems: "center",
    justifyContent: "center",
    width: "20px",
    height: "20px",
    borderRadius: "6px",
    backgroundColor: "#4FC8EC",
    cursor: "pointer",
  },

  "& input:checked + span": {
    display: "flex",
  },
});

const StyledContainer = styled(Box)(({theme}) => ({
  display: "flex",
  flexDirection: "column",

  "& .thCell": {
    display: "flex",
    flexWrap: "nowrap",
    alignItems: "center",
    justifyContent: "flex-start",
    fontFamily: "Roboto, sans-serif",
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "22px",
    color: "#C5CBC9",

    [theme.breakpoints.down('sm')]: {
      fontSize: "12px",
      lineHeight: "16px",
    },
  },

  "& .tableRow": {
    display: "grid",
    columnGap: "5px",
    gridTemplateColumns: "3% 1fr 1fr 10% 1fr 10% 1fr 10%",
    borderBottom: "1px solid #F0FAFA",
    padding: "12px 0",

    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: "3% 1fr 15% 15% 1fr 10% 1fr 10%",
    },
  },

  "& .trCell": {
    display: "flex",
    flexWrap: "nowrap",
    alignItems: "center",
    justifyContent: "flex-start",
    fontFamily: "Roboto, sans-serif",
    fontSize: "14px",
    lineHeight: "22px",
    fontWeight: 500,
    color: "#4B4C4B",

    [theme.breakpoints.down('sm')]: {
      fontSize: "12px",
      lineHeight: "16px",
    },
  },

  "& .actionsCell": {
    gap: "24px",

    [theme.breakpoints.down('sm')]: {
      gap: "10px"
    },
  },

  "& .name": {
    fontFamily: "Roboto, sans-serif",
    fontSize: "14px",
    lineHeight: "22px",
  },

  "& .actionButton": {
    fontFamily: "Roboto, sans-serif",
    fontSize: "14px",
    lineHeight: "22px",
    cursor: "pointer",
  },
  
}))

// Customizable Area End
