// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { ChangeEvent } from "react";
import { ContentView, Role } from "./MyTeamController.web";

export const webConfigJSON = require("./config.js");

export interface Props {
  currentView: ContentView;
  totalItems?: number;
  itemsPerPage?: number;
  checkedItems?: number;
  sort: string;
  search: string;
  roles?: Role[];
  role?: string;
  page: number;
  totalPages: number;
  isAllChecked: boolean;
  isDeletePromptOpened?: boolean;
  setSearch: (value: string) => void;
  setPage: (page: number) => void;
  setSort: (value: string) => void;
  setRole?: (role: string) => void;
  handleCheckAll: () => void;
  setDeletePromptState?: () => void;
  handleDealershipsModal?: () => void;
}

interface S {
  showPrompt: boolean;
}
interface SS {}

export default class TableHeadController extends BlockComponent<Props, S, SS> {
  constructor(props: Props) {
    super(props);

    this.state = {
      showPrompt: false,
    };
  }

  async componentDidMount() {
    super.componentDidMount();
  }

  handleSetSort = (event: ChangeEvent<{ name?: string; value: unknown }>) => {
    this.props.setSort(event.target.value as string);
  };

  handleSetRole = (event: ChangeEvent<{ name?: string; value: unknown }>) => {
    if (!this.props.setRole) return;
    this.props.setRole(event.target.value as string);
  };

  handleSearch = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const search = event.target.value;

    this.props.setSearch(search);
  };

  findProperItemsName = (itemsNumber: number) => {
    if (this.props.currentView === ContentView.DEALERSHIPS) {
      return itemsNumber > 1 || itemsNumber === 0
        ? "dealerships"
        : "dealership";
    } else {
      return itemsNumber > 1 || itemsNumber === 0 ? "users" : "user";
    }
  };

  nextPage = () => {
    const { page, totalPages, setPage } = this.props;

    if (page < totalPages) {
      setPage(page + 1);
    }
  };

  prevPage = () => {
    const { page, setPage } = this.props;

    if (page > 1) {
      setPage(page + 1);
    }
  };
}
// Customizable Area End
