import {
  Box,
  IconButton,
  Menu,
  MenuItem,
  WithStyles,
  createStyles,
  withStyles,
  Typography
} from "@material-ui/core";
import {
  DeleteOutline,
  Edit as EditIcon,
  MoreHoriz,
} from "@material-ui/icons";
import clsx from "clsx";
import React from "react";
import { v4 as uuidv4 } from "uuid";

export enum TaskMenuAction {
  // View = "view",
  Edit = "edit",
  Delete = "delete",
}

export interface TaskMenuOption {
  id: string;
  name: string;
  value: TaskMenuAction;
  icon: React.ReactNode;
}

export interface Props extends WithStyles {
  onSelectAction(value: TaskMenuAction): void;
}

const styles = createStyles({
  statusExpand: {
    marginLeft: "auto",
  },

  menu: {
    "& .MuiPaper-elevation8":{
      boxShadow: "0px 8px 32px 0px #0000000F"
    },

    "& > div:nth-child(3)": {
      borderRadius: "8px",
    },

    "& ul": {
      width: 180,
      padding: "15px 10px"
    },
  },

  actionLink: {
    display: "flex",
    alignItems: "center",
    textDecoration: "none",
    padding: "8px 16px",
    width: "100%",

    "&:hover": {
      backgroundColor: "#E8F8FD",
      borderLeft: "3px solid #4FC8EC",
      color: "#FFFFFF",
    },

    "& svg": {
      fill: "#333333",
    },
  },

  actionItemIcon: {
    width: 18,
    height: 18,
  },

  actionItemName: {
    marginLeft: 8,
    color: "#333333",
  },

  actionItem: {
    height: "36px",
    padding: 0,
  },

  menuTypo: {
    marginLeft: 10,
    fontFamily: "Roboto",
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "22px",
    textAlign: "left"
  },

  catalogMenuButton:{
    padding: 0
  }
});

export class TaskMenu extends React.Component<
  Props,
  {
    statusMenu: HTMLElement | null;
  }
> {
  constructor(props: Props) {
    super(props);

    this.state = {
      statusMenu: null,
    };
  }

  menu = [
    {
      id: uuidv4(),
      name: "Edit",
      value: TaskMenuAction.Edit,
      icon: EditIcon,
      dataTestID: "edit-btn"
    },

    {
      id: uuidv4(),
      name: "Delete",
      value: TaskMenuAction.Delete,
      icon: DeleteOutline,
      dataTestID: "delete-btn"
    },
  ];

  openFilter = (event: any) => {
    this.setState({
      statusMenu: event.currentTarget,
    });
  };

  closeFilter = () => {
    this.setState({
      statusMenu: null,
    });
  };

  onChangeStatus = (value: TaskMenuAction) => {
    this.props.onSelectAction(value);

    this.closeFilter();
  };

  render(): React.ReactNode {
    const { classes } = this.props;
    const { statusMenu } = this.state;

    const id = uuidv4();

    return (
      <Box className={classes.detailStatus}>
        <Box
          id={`filter-button-${id}`}
          aria-controls={statusMenu ? `filter-menu-${id}` : undefined}
          aria-haspopup="true"
          aria-expanded={statusMenu ? "true" : undefined}
          onClick={this.openFilter}
        >
          <IconButton className={classes.catalogMenuButton}>
            <MoreHoriz />
          </IconButton>
        </Box>

        <Menu
          className={classes.menu}
          id={`filter-menu-${id}`}
          anchorEl={statusMenu}
          open={Boolean(statusMenu)}
          getContentAnchorEl={null}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          onClose={this.closeFilter}    
          MenuListProps={{
            className: classes.detailStatus,
            "aria-labelledby": `filter-button-${id}`,
          }}
        >
          {this.menu.map((option) => {
            return (
              <MenuItem className={clsx(classes.actionItem)} key={option.id}>
                <Box
                  className={clsx(classes.actionLink)}
                  data-test-id={option.dataTestID}
                  onClick={() => this.onChangeStatus(option.value)}
                >
                  <option.icon className={classes.actionItemIcon} style={{fill: option.name === 'Delete'? '#C43937' : '#4B4C4B'}}/>

                  <Typography className={classes.menuTypo} style={{color: option.name === 'Delete'? '#C43937' : '#4B4C4B'}}>{option.name}</Typography>
                </Box>
              </MenuItem>
            );
          })}
        </Menu>
      </Box>
    );
  }
}

export default withStyles(styles)(TaskMenu);