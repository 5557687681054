import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area   Start
export const configJSON = require("./config");
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;

  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start

    token: string,
    dropdownData: any[];                  
    data: any[],
    selectedService: boolean
    closeDrawer: boolean;
    selectedCardService: any;
    selectedServiceStatus: string;
    selectedCardIndex: any[];
    selectedButtonIndex: number,
    searchValue: string,
    sortType: string,
    multipleServiceSort: any[],
    currentPage: number,
    totalpages: number
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ServiceProviderDashboardController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getFetchData = "";
  advancedsearchApiCallId: any;
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
            token: "",
            data: [],
            selectedService: false,
            closeDrawer: false,
            selectedCardService: [],
            selectedServiceStatus: "",
            selectedCardIndex: [],
            selectedButtonIndex: 0,
            searchValue: '',
            sortType: "",
            multipleServiceSort: [],
            dropdownData: [],
            currentPage: 1,
            totalpages: 3
      // Customizable Area End
    };
    // Customizable Area Start
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    // Customizable Area Start
    this.setState({currentPage: 1})
    const header = {
      "Content-Type": configJSON.advancedsearchApiContentType,
      token: this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.advancedsearchApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getServiceProviderListApiEndPoint}?state_code=CA`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    // Customizable Area End
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
   if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      runEngine.debugLog("Message Received", message);

      const token = message.getData(getName(MessageEnum.SessionResponseToken));
      this.setState({token: token})
    }else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      runEngine.debugLog("API Message Recived", message);
      if(responseJson?.service_providers?.data?.length > 0) {
        this.setState({ data: responseJson?.service_providers?.data, totalpages: responseJson?.total_pages });
        if(responseJson?.total_pages <= 0) {
            this.setState({currentPage: 0})
        }
      }
      if (getName(MessageEnum.SessionResponseMessage) === message.id) {
        let token = message.getData(getName(MessageEnum.SessionResponseToken));
        runEngine.debugLog("TOKEN", token);
        this.setState({ token: token });
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  selectServiceHandler = (provider: any) => {
  const finalData = this.state.data && this.state.data.map((item, index)=> {
      if(provider.index === index) {
          return {
            ...item,
            buttonIndex: provider.buttonIndex
          }
      } else {
        return item
      }
    }
    )
    this.setState({data: finalData, selectedCardIndex: [...this.state.selectedCardIndex, provider.index],  selectedCardService: [...this.state.selectedCardService, provider]})
  }

  closeDrawerHandler = () => {
    this.setState({ closeDrawer: !this.state.closeDrawer })
  }

  removeSelectedServiceHandler = (provider: any) => {
    const data = this.state.selectedCardService && this.state.selectedCardService.filter((item: any)=> item && item.buttonIndex !== (provider && provider.buttonIndex) && item )
    const finalData =  this.state.data && this.state.data.map((item, index)=> {
      const {buttonIndex, ...rest} = item
      if(provider.index === index) {
          return {
            ...rest
          }
      } else {
        return item
      }
    }
    )
    this.setState({data: finalData, selectedCardService: data})

  }

  removeSelectedCardsFromServiceBlock = (index: number, cardItem: any) => {
   const finalData = this.state.selectedCardService && this.state.selectedCardService.filter((item: any, i: number)=> index !== i && item )
   const data =  this.state.data && this.state.data.map((item, i)=> {
    const {buttonIndex, ...rest} = item
    if(cardItem.index === i) {
        return {
          ...rest
        }
    } else {
      return item
    }
  }
  )
  this.setState({data: data})
    this.setState({selectedCardService: finalData})
  }
  // Customizable Area End
}