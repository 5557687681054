import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import React from "react";
import { getStorageData } from "../../../framework/src/Utilities";

export interface ResponseJson {
  data: Data;
  meta: Meta;
  messages? : string[];
  errors?: (ErrorsEntity)[] | null;
}
export interface Data {
  id: string;
  type: string;
  attributes: Attributes;
}
export interface Attributes {
  activated: boolean;
  email?: null;
  phone_number?: null;
  account_exists: boolean;
}
export interface Meta {
  message: string;
  token: string;
}
export interface ErrorsEntity {
  pin: string;
  phone: string;
}


// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export interface S {
  // Customizable Area Start
  otp: string;
  otpAuthToken: string;
  userAccountID: string;
  labelInfo: string;
  toMessage: string;
  isFromForgotPassword: boolean;

  timer: number;
  receivedMob: string;
  receivedOtpToken: string;
  confirmation: boolean;
  errorMsg: string;
  accountId: string;
  isComeFromInviteUser: boolean;
  inviteuserurl: string
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: string | number;
  // Customizable Area End
}

export default class OTPInputAuthController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  otpAuthApiCallId: unknown;
  resendOtpAuthApiCallId: unknown;
  btnTxtSubmitOtp: string;
  placeHolderOtp: string;
  labelInfo: string = "";
  submitButtonColor: string = configJSON.submitButtonColor;

  timerID: number | NodeJS.Timeout | null=null
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.NavigationRaiseMessage)
      // Customizable Area End
    ];

    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    // Customizable Area Start
    this.state = {
      otp: "",
      otpAuthToken: "",
      userAccountID: "",
      labelInfo: configJSON.labelInfo,
      toMessage: "",
      isFromForgotPassword: false,

      timer: 120, 
      receivedMob: '',
      receivedOtpToken: '',
      confirmation: false,
      errorMsg: '',
      accountId: '',
      isComeFromInviteUser:false,
      inviteuserurl:''
    };
    this.startTimer();

    this.btnTxtSubmitOtp = configJSON.btnTxtSubmitOtp;
    this.placeHolderOtp = configJSON.placeHolderOtp;
    // Customizable Area End
  }

  async receive(from: String, message: Message) {
    // Customizable Area Start
    if (message.id === getName(MessageEnum.NavigationPayLoadMessage)) {
      const myData = await message.getData(getName(MessageEnum.CustomSubscriptionDataMessage));
      if(myData){
        this.setState({receivedOtpToken:myData.otpToken, receivedMob: myData.phoneNumber, accountId: myData.id, isComeFromInviteUser: true, inviteuserurl: myData.inviteuserurl})
      }
    }


    if (this.isRestApiResponseMessage(message)) {
      const responseJson = await message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (this.isResponseValid(responseJson)) {
        this.handleValidResponse(responseJson);
      } else {
        this.handleErrorResponse(responseJson);
      }
  
      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (errorReponse != null) {
        this.parseApiCatchErrorResponse(errorReponse);
      }
    } else if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      this.handleNavigationPayloadMessage(message);
    }
    // Customizable Area End
  }

  // Customizable Area Start
componentDidMount = async (): Promise<void> => {
  try {
    const otpwebtoken = await getStorageData("otpToken");
    const mob = await getStorageData("mobileNumber");
    this.setState({ receivedMob: mob, receivedOtpToken: otpwebtoken });
  } catch (error) {
    
  }
}

handleNavigate = async()=>{
    const myconfirmation = this.state.confirmation ? "confirmed" : "unconfirmed"
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "EmailAccountRegistration");
    const raiseMessage = new Message(getName(MessageEnum.NavigationPayLoadMessage));
    raiseMessage.addData(getName(MessageEnum.CustomSubscriptionDataMessage),myconfirmation);
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props); 
    this.send(message);
}
handleNavigatetoInviteuser = ()=>{
  const accountidforinviteuser = this.state.accountId
  const message = new Message(getName(MessageEnum.NavigationMessage));
  message.addData(getName(MessageEnum.NavigationTargetMessage), "InviteUserSignup");
  const raiseMessage = new Message(getName(MessageEnum.NavigationPayLoadMessage));
  raiseMessage.addData(getName(MessageEnum.CustomSubscriptionDataMessage),accountidforinviteuser);
  message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
  message.addData(getName(MessageEnum.NavigationPropsMessage), this.props); 
  this.send(message);
}



  formatTime = (timer: number) => {
    const minutes = Math.floor(timer / 60);
    const seconds = timer % 60;
    return `${minutes < 10 ? '0' : ''}${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  };

  startTimer() {
    this.timerID = setInterval(() => {
        if(this.state.timer >0){
          this.setState((prevState) => ({
            timer: prevState.timer - 1,
          }));
        }
      }, 1000);
  }

  handleBack=()=>{
    if(!this.state.isComeFromInviteUser){
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msg.addData(getName(MessageEnum.NavigationTargetMessage), 'EmailAccountRegistration');
    this.send(msg);
    }
  }
  resendOtpp = () =>{
    this.resendOtpApi();
    this.setState({timer:120})
  }

   formatPhoneNumber = (phoneNumber: string) => {
    const cleaned = ('' + phoneNumber).replace(/\D/g, '');
    const match = cleaned.match(/^1?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return `(${match[1]}) ${match[2]}-${match[3]}`;
    }
    return phoneNumber;
  };
  handleChange = (otp: string) => {
    console.log(otp.length, 'rrr')
    if(otp.length<4){this.setState({errorMsg:''})}
    this.setState({ otp });
  }
  isRestApiResponseMessage(message: Message): boolean {
    return (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.otpAuthApiCallId != null &&
      this.otpAuthApiCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    );
  }
  
  isResponseValid(responseJson: ResponseJson): boolean {
    if((responseJson && (responseJson.messages || (responseJson.meta && responseJson.meta.token)))) return true
    return false
  }
  
  handleValidResponse(responseJson: ResponseJson) {
    if (responseJson.meta && responseJson.meta.token) {
      if (responseJson.meta.message === "Phone Number Confirmed Successfully") {
        this.setState({ confirmation: true });
      }
      this.setState({
        otpAuthToken: responseJson.meta.token,
      });
    }
  
    
  }
  
  handleErrorResponse(responseJson: ResponseJson) {
    if (responseJson && responseJson.errors) {
      const errorMessage = responseJson.errors[0]?.phone ?? responseJson.errors[0]?.pin ?? "Unknown error occurred.";
      this.setState({ errorMsg: errorMessage });
    } else {
      this.setState({ errorMsg: "Unknown error occurred." });
    }
  }
  
  handleNavigationPayloadMessage(message: Message) {
    const phoneAuthToken = message.getData(
      getName(MessageEnum.AuthTokenDataMessage)
    );
    const phoneNumber = message.getData(
      getName(MessageEnum.AuthTokenPhoneNumberMessage)
    );
    const forgotPasswordBool = message.getData(
      getName(MessageEnum.EnterOTPAsForgotPasswordMessage)
    );
    const emailValue = message.getData(
      getName(MessageEnum.AuthTokenEmailMessage)
    );
  
    const userAccountID = phoneNumber ? "" + phoneNumber : "" + emailValue;
  
    let updatedLabel = this.state.labelInfo;
    if (userAccountID && userAccountID !== "undefined") {
      updatedLabel = updatedLabel.replace("phone", userAccountID);
    }
  
    this.setState({
      otpAuthToken:
        phoneAuthToken && phoneAuthToken.length > 0
          ? phoneAuthToken
          : this.state.otpAuthToken,
      userAccountID: userAccountID,
      labelInfo: updatedLabel,
      isFromForgotPassword:
        forgotPasswordBool === undefined
          ? this.state.isFromForgotPassword
          : forgotPasswordBool,
    });
  }

  async submitWebOtp() {

    const newotp = this.state.otp;
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
      const myData = {
        token: this.state.receivedOtpToken,
        pin: Number(newotp)
      };

      const headers = {
        "Content-Type": configJSON.apiVerifyOtpContentType,
      };

      this.otpAuthApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.apiVerifyWebOtpEndPoint
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(myData)
      );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiVerifyOtpMethod
    );
    
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  async resendOtpApi(){
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
      const rrrr = {
        full_phone_number: this.state.receivedMob
      }
      const myData = {
        attributes: rrrr
      };
      const httpBody = {
        data: myData,
      };

      const headers = {
        "Content-Type": configJSON.apiVerifyOtpContentType,
      };

      this.resendOtpAuthApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.resendOtpAuthEndPoint
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.resendOtpAuthMethod
    );
    
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  async submitOtp() {
    if (!this.state.otp || this.state.otp.length === 0) {
      this.showAlert(configJSON.errorTitle, configJSON.errorOtpNotValid);
      return;
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    if (this.state.isFromForgotPassword) {
      
      const header = {
        "Content-Type": configJSON.apiVerifyOtpContentType,
      };

      //GO TO REQUEST STATE
      this.otpAuthApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.apiVerifyForgotPasswordOtpEndPoint
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      const data = {
        token: this.state.otpAuthToken ? this.state.otpAuthToken : "",
        otp_code: this.state.otp ? this.state.otp : "",
      };

      const httpBody = {
        data: data,
      };

      //requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "POST");

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );
    } else {
      const headers = {
        "Content-Type": configJSON.apiVerifyOtpContentType,
        token: this.state.otpAuthToken,
      };

      this.otpAuthApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.apiVerifyOtpEndPoint + this.state.otp
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(JSON.stringify({}))
      );
    }

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiVerifyOtpMethod
    );
    
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  btnSubmitOTPProps = {
    onPress: () => this.submitOtp(),
  };

  txtMobilePhoneOTPWebProps = {
    onChangeText: (text: string) => this.setState({ otp: text }),
  };

  txtMobilePhoneOTPMobileProps = {
    ...this.txtMobilePhoneOTPWebProps,
    keyboardType: "numeric",
  };

  txtMobilePhoneOTPProps = this.isPlatformWeb()
    ? this.txtMobilePhoneOTPWebProps
    : this.txtMobilePhoneOTPMobileProps;

  // Customizable Area End
}
