import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { getStorageData } from "../../../framework/src/Utilities"

// Customizable Area Start
export interface GetUserResponseSuccess {
  data: Data;
}
export interface Data {
  id: string;
  type: string;
  attributes: Attributes;
}
export interface Attributes {
  activated: boolean;
  country_code: string;
  email: string;
  full_phone_number: string;
  phone_number: string;
  type: string;
  full_name: string;
  created_at: string;
  updated_at: string;
  is_dealer_group: boolean;
  dealer_group_name?: null;
  role: string;
  profile_picture?: null;
  group_created_on?: null;
  is_deal_pending: boolean;
  deal_data?: null;
  solo_dealership_id: number;
}

interface GetUserError {
  errors:[
    {
      token:string;
    }
  ]
}
interface TableData {
    dealer: string;
    service: string;
    states: string;
    vehicleType: string;
    application: string;
    payout: string;
    marketplaceFee: string;
    dealerRating: string;
  }
// Customizable Area End

export const configJSON = require("./config");
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isOpenNotificationModal: boolean;
  isOpenAcceptModal:boolean;
  tableData: TableData[];
  isOpenRejectModal:boolean;
  openToast:boolean;

  isOpen:boolean;
  // Customizable Area End

}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class ServiceProviderNotificationController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  changeNameApiCallId:string="";
  getuserDataApiCallId:string="";
  sendContactUsApiCallId:string="";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];
   
    this.state = {
        isOpenNotificationModal:false,
        isOpenAcceptModal:false,

        tableData: [
            {
              dealer: 'Great Car',
              service: 'Full Service',
              states: 'CA > NY',
              vehicleType: 'Passenger',
              application: 'Registration',
              payout: '$45.00',
              marketplaceFee: '3%',
              dealerRating: '90%',
            },
          ],

        isOpenRejectModal: false,
        openToast:false,

        isOpen:false,
    };

    // Customizable Area End
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId && responseJson) {
        if(apiRequestCallId===this.getuserDataApiCallId){
          this.handleUserData(responseJson)
        }
      }
    } 

    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount(){
    this.getUser()
  }

  handleUserData=(responseJson:GetUserResponseSuccess & GetUserError)=>{
    if(!responseJson.errors){
      if(responseJson.data.attributes.role === 'service_provider_admin' || responseJson.data.attributes.role === 'service_provider'){
        this.setState({isOpenNotificationModal:true})
      }
    }
  }

  getUser=async()=>{
    const newtokenn = localStorage.getItem('authToken')
    const Webheader = {
      "Content-Type": 'application/json',
      "token": newtokenn
    };
    const WebrequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getuserDataApiCallId = WebrequestMessage.messageId;   
    WebrequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
     'account_block/accounts/get_user'
    );

    WebrequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(Webheader)
    );
    WebrequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );

    runEngine.sendMessage(WebrequestMessage.id, WebrequestMessage);
  }

  toggleNotificationModal=()=>{
    this.setState({isOpenNotificationModal: !this.state.isOpenNotificationModal})
  }

  toggleAcceptModal=()=>{
    this.setState({isOpenAcceptModal: !this.state.isOpenAcceptModal, isOpenNotificationModal:false})
  }

  toggleRejectModal=()=>{
    this.setState({isOpenRejectModal: !this.state.isOpenRejectModal, isOpenNotificationModal:false})
  }

  toastFalse=()=>{
    this.setState({openToast:false})
    this.toggleNotificationModal()
  }

  handleToast=()=>{
    this.setState({openToast:true})
  }

  gotoAllDeals=()=>{
    const newmsgg = new Message(getName(MessageEnum.NavigationMessage));
    newmsgg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    newmsgg.addData(getName(MessageEnum.NavigationTargetMessage), 'Dashboard')
    this.send(newmsgg);
  }
  // Customizable Area End

}