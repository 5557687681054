// Customizable Area Start
import React, { CSSProperties } from "react";

import { Box, Avatar, Typography, styled } from "@material-ui/core";

import DealershipsTableController, {
  Props,
} from "./DealershipsTableController.web";
import { DealershipNormalized } from "./MyTeamController.web";
import { checkmark } from "./assets";
import moment from "moment";

export default class DealershipsTable extends DealershipsTableController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { data, handleCheck } = this.props;
    const currentPageData = data.slice();    

    return (
      <StyledContainer>
        <Box className="tableRow">
          <Box />
          <Box className="thCell" style={{ justifyContent: "flex-start" }}>
            Dealerships
          </Box>
          <Box className="thCell" >Users</Box>
          <Box className="thCell" style={{ justifyContent: "flex-start" }}>
            Created on
          </Box>
          <Box className="thCell">Actions</Box>
        </Box>
        {currentPageData.map((item: DealershipNormalized, i: number) => (
          <Box
            key={item.id}
            data-test-id={`dealership-${i}`}
            className="tableRow"
          >
            <Box className="trCell" style={{ justifyContent: "flex-start" }}>
              <CheckboxContainer onClick={() => handleCheck(item.id)}>
                <input
                  type="checkbox"
                  checked={item.checked}
                  className="checkbox"
                  data-test-id={`checkbox-${i}`}
                />
                <span className="checkmark">
                  <img src={checkmark} alt="checkmark" />
                </span>
              </CheckboxContainer>
            </Box>

            <Box
              className="trCell"
              style={{
                justifyContent: "flex-start",
                gap: "12px",
              }}
            >
              <Avatar src={item.logo} alt={item.name} variant="rounded">
                {item.name[0]}
              </Avatar>
              <Typography className="name">{item.name}</Typography>
            </Box>
            <Box className="trCell">{item.users}</Box>
            <Box className="trCell" style={{ justifyContent: "flex-start" }}>
              {moment(item.createdAt).format("MMMM D, YYYY h:mm a")}
            </Box>
            <Box className="trCell">
              <Typography className="actionButton">Edit</Typography>
            </Box>
          </Box>
        ))}
      </StyledContainer>
    );
  }
}

const CheckboxContainer = styled(Box)({
  position: "relative",
  cursor: "pointer",

  "& .checkbox": {
    appearance: "none",
    width: "20px",
    height: "20px",
    border: "1px solid #C5CBC9",
    borderRadius: "6px",
    margin: 0,
    cursor: "pointer",
  },

  "& .checkmark": {
    position: "absolute",
    top: "0",
    left: "0",
    display: "none",
    alignItems: "center",
    justifyContent: "center",
    width: "20px",
    height: "20px",
    borderRadius: "6px",
    backgroundColor: "#4FC8EC",
    cursor: "pointer",
  },

  "& input:checked + span": {
    display: "flex",
  },
});

const StyledContainer = styled(Box)(({theme}) => ({
  display: "flex",
  flexDirection: "column",

  "& .thCell": {
    display: "flex",
    flexWrap: "nowrap",
    alignItems: "center",
    justifyContent: "center",
    fontFamily: "Roboto, sans-serif",
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "22px",
    color: "#C5CBC9",
    overflow: "hidden",
    textOverflow: "ellipsis",

    [theme.breakpoints.down('sm')]: {
      fontSize: "12px",
      lineHeight: "16px",
    },
  },

  "& .tableRow": {
    display: "grid",
    gridTemplateColumns: "5% 20% 20% 1fr 20%",
    borderBottom: "1px solid #F0FAFA",
    padding: "12px 0",
  },

  "& .trCell": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontFamily: "Roboto, sans-serif",
    fontSize: "14px",
    lineHeight: "22px",
    fontWeight: 500,
    color: "#4B4C4B",

    [theme.breakpoints.down('sm')]: {
      fontSize: "12px",
      lineHeight: "16px",
    },
  },

  "& .name": {
    fontFamily: "Roboto, sans-serif",
    fontSize: "14px",
    lineHeight: "22px",
  },

  "& .actionButton": {
    fontFamily: "Roboto, sans-serif",
    fontSize: "14px",
    lineHeight: "22px",
    cursor: "pointer",
  }
}))


// Customizable Area End
