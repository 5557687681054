import React from 'react';
import CustomisableUserProfilesController from './../../blocks/customisableuserprofiles/src/CustomisableUserProfilesController';

export class PDFViewer extends  CustomisableUserProfilesController {
  
    render() {

    return (
      <div style={{ width: '100%', height: '90vh', overflow: 'auto' ,background:"white" }}>
          <embed
            src={`${this.state.taxInfoDetails.pdfLink}#page=1`}
            type="application/pdf"
            style={{ width: '100%', height: '100vh', background:"white",marginTop:"25px"}}
            id='toolbarViewerCss'
          />
      </div>
    );
  }
}

export default PDFViewer;

