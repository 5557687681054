import {
  Box,
  Button,
  createStyles,
  Drawer,
  IconButton,
  Typography,
  withStyles,
  WithStyles,
} from "@material-ui/core";
import { Fee } from "../../ordermanagement/src/OrderSummaryRegistrationFeeController";
import RegistrationFeeDetails from "../../ordermanagement/src/RegistrationFeeDetails";
import React from "react";
import { Close, Edit } from "@material-ui/icons";
const classes = createStyles({
  drawer: {
    "& .MuiDrawer-paperAnchorRight": {
      width: "40%",
      padding: "33px 57px 67px 57px",
      gap: "40px",
      display: "flex",
      flexDirection: "column",
    },
  },
  closeContainer: {
    textAlign: "end",
  },
  registrationFee: {
    fontFamily: "Gotham",
    fontSize: "32px",
    fontWeight: 400,
    lineHeight: "18px",
    color: "#013D4F",
    display: "flex",
    alignItems: "center",
    gap: "10px",
  },
  calculationGenerated: {
    fontFamily: "Roboto, sans-serif",
    fontSize: "14px",
    fontWeight: 500,
    color: "#4B4C4B",
  },
  registrationFeeContainer: {
    padding: "30px ",
    gap: "38px",
    borderRadius: "20px",
    border: "1px solid #F0FAFA",
  },

  totalFee: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    gap: "10px",
    paddingTop: "15px",
    marginTop: "15px",
    borderTop: "2px solid #F0FAFA",
  },

  totalFeeLabel: {
    fontFamily: "Roboto",
    fontSize: "18px",
    fontWeight: 500,
    lineHeight: "18px",
    color: "#4B4C4B",
  },
  totalFeeValue: {
    fontFamily: "Roboto",
    fontSize: "28px",
    fontWeight: 600,
    lineHeight: "24px",
    color: "#013D4F",
  },

  closeBtn: {
    padding: "16px",
    gap: "8px",
    borderRadius: "8px",
    border: "1px solid #013D4F",
    "& span": {
      fontFamily: "Roboto",
      fontSize: "16px",
      fontWeight: 700,
      lineHeight: "24px",
      color: "#013D4F",
    },
  },
});

export interface Props extends WithStyles {
  open: boolean,
  onCloseDrawer: () => void;
}
export class RegistrationFeeDrawer extends React.Component<
  Props,
  {
    fees: Fee[];
  }
> {
  constructor(props: Props) {
    super(props);
    this.state = {
      fees: [
        new Fee("County Surtax (1%)", "This is the reason 1", "50.00", null),
        new Fee("Sales & Use Tax (6%)", "", "1830.00", null),
        new Fee("Additional Statutory Fees", "", "10.06", null),
        new Fee("Reg Service Charge", "", "3.00", null),
        new Fee("Plate Registration Fee", "", "32.05", null),
        new Fee("New Metal Plate Fee", "", "28.00", null),
        new Fee(
          "Initial Registration Fee",
          "This is the reason 2",
          "225.00",
          null
        ),
        new Fee("Title Fee", "", "77.03", null),
      ],
    };
  }

  render(): React.ReactNode {
    const { classes, open, onCloseDrawer } = this.props;
    const { fees } = this.state;
    return (
      <Drawer className={classes.drawer} open={open} anchor="right">
        <Box className={classes.closeContainer}>
          <IconButton><Close onClick={onCloseDrawer}/></IconButton>
        </Box>
        <Box className={classes.header}>
          <Typography className={classes.registrationFee}>
            Registration Fee
            <Edit></Edit>
          </Typography>
          <Typography className={classes.calculationGenerated}>
            Generated by fee calculation
          </Typography>
        </Box>
        <Box className={classes.registrationFeeContainer}>
          <RegistrationFeeDetails fees={fees} />
          <Box className={classes.totalFee}>
            <Typography className={classes.totalFeeLabel}>Total Fee</Typography>
            <Typography className={classes.totalFeeValue}>$2,020.08</Typography>
            <Typography>$2,255.14</Typography>
          </Box>
        </Box>
        <Button className={classes.closeBtn} onClick={onCloseDrawer}>Close</Button>
      </Drawer>
    );
  }
}

export default withStyles(classes)(RegistrationFeeDrawer);
