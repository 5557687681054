import React from "react";
// Customizable Area Start
import {
  Box,
  Avatar,
  withStyles,
  createStyles,
  Button,
  Badge,
  ThemeProvider,
  createTheme,
  Typography,
  Card,
  Chip,
  TextField
} from "@material-ui/core";
import OrderSummaryRegistrationFeeController, {
  Props
} from "./OrderSummaryRegistrationFeeController";
import { vitu, notifications, menu } from "./assets";
import { Edit } from "@material-ui/icons";
import { clsx } from "clsx";
import RegistrationFeeDetails from "./RegistrationFeeDetails";
const theme = createTheme({
  typography: {
    h1: {
      fontFamily: "Gotham, sans-serif",
      fontSize: "30px",
      fontWeight: 300,
      lineHeight: "40px",
      color: "#013D4F",
      letterSpacing: 0
    },
    h2: {
      fontFamily: "Gotham, sans-serif",
      fontSize: "18px",
      fontWeight: 300,
      lineHeight: "26px",
      textAlign: "center",
      color: "#4B4C4B",
      letterSpacing: 0
    },
    body1: {
      fontFamily: "Roboto",
      fontSize: "14px",
      fontWeight: 500,
      lineHeight: "31px",
      color: "#939F9B"
    },
    body2: {
      fontFamily: "Roboto",
      fontSize: "20px",
      fontWeight: 600,
      lineHeight: "31px",
      textAlign: "left"
    }
  }
});

// Customizable Area End

export class OrderSummaryRegistrationFee extends OrderSummaryRegistrationFeeController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start

    // Customizable Area End
  }
  // Customizable Area Start

  // Customizable Area End
  render() {
    // Customizable Area Start
    const { classes } = this.props;
    const {
      isEditing,
      fees,
      oldTotalPrice,
      feeEditingIndex,
      reasonChange
    } = this.state;
    return (
      <ThemeProvider theme={theme}>
        <Box className={classes.container}>
          <Box className={classes.header}>
            <Box className={classes.headerLeftSide}>
              <Box className={classes.menuLogo}>
                <Button className={classes.hamburgerButton}>
                  <img src={menu} alt="menu button" />
                </Button>

                <Box className={classes.logo}>
                  <img src={vitu} alt="vitu logo" />
                </Box>
              </Box>
            </Box>

            <Box className={classes.headerRightSide}>
              <Box
                data-test-id="notifications"
                className={classes.notificationIcon}
              >
                <Badge
                  color="secondary"
                  variant="dot"
                  invisible={false}
                  overlap="rectangular"
                >
                  <img src={notifications} alt="notifications button" />
                </Badge>
              </Box>

              <Box className={classes.avatarContainer}>
                <Avatar variant="square" className={classes.avatar}>
                  V
                </Avatar>
              </Box>
            </Box>
          </Box>
          <Box className={classes.main}>
            <Box className={classes.formFee}>
              <Box className={classes.formFeeHeader}>
                <Typography variant="h1">Registration Fees</Typography>
                <Box sx={{ marginTop: "12px", marginBottom: "60px" }}>
                  <Typography variant="h2">
                    We calculated the Registration Fees for you. These fees will
                    be stored in your deal details after exiting this page.
                  </Typography>
                </Box>
              </Box>
              <Box className={classes.cardContainer}>
                {Object.entries(this.cardPanelInfos).map(([key, value]) => (
                  <Card className={classes.cardPanel} key={key}>
                    <Typography variant="body1">{key}</Typography>
                    <Typography variant="body2">{value}</Typography>
                  </Card>
                ))}
                <Chip
                  label="In Progress"
                  variant="outlined"
                  className={`${classes.progressMenu} ${classes.inProgress}`}
                />
              </Box>
              <Box className={classes.feeDetailsContainers}>
                <Box className={classes.feeDetailsHeader}>
                  <Typography variant="h1" style={{ fontSize: "32px" }}>
                    {isEditing && "Edit "} Fee Details
                  </Typography>
                  <Button
                    disabled={isEditing}
                    className={clsx(classes.editFeeBtn, {
                      [classes.disabledBorder]: isEditing
                    })}
                    onClick={this.handleChangeEditingStatus}
                    data-test-id="edit-fee-btn"
                  >
                    Edit Fee
                  </Button>
                </Box>
                <RegistrationFeeDetails
                  fees={fees}
                  feeEditingIndex={feeEditingIndex}
                  isEditing={isEditing}
                  reasonChange={reasonChange}
                  handleFeeSave={this.handleFeeSave}
                  handleSelectFeeChange={this.handleSelectFeeChange}
                  handleFeeInputChange={this.handleFeeInputChange}
                  handleInputReason={this.handleInputReason}
                  handleChangeReason={this.handleChangeReason}
                  data-test-id="registration-fee-details"
                />
                <Box className={classes.feeDetailsFooter}>
                  <Box className={classes.feeDetailsButtons}>
                    {isEditing ? (
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between"
                        }}
                      >
                        <span
                          className={classes.cancelBtn}
                          onClick={() => {
                            this.setState({ isEditing: false });
                          }}
                        >
                          Cancel
                        </span>
                        <Button
                          onClick={() =>
                            this.setState({
                              isEditing: false,
                              feeEditingIndex: -1
                            })
                          }
                          disabled={
                            oldTotalPrice === null ||
                            oldTotalPrice.toString() ===
                              this.getCurrentPrice().toString()
                          }
                          className={clsx(
                            [classes.saveBtn, classes.disabledBtn],
                            {
                              [classes.enabledBtn]:
                                reasonChange.length != 0 ||
                                (oldTotalPrice &&
                                  oldTotalPrice.toString() !==
                                    this.getCurrentPrice().toString())
                            }
                          )}
                        >
                          Save
                        </Button>
                      </Box>
                    ) : (
                      <Button
                        data-test-id="back-to-deal-details-btn"
                        className={classes.backToDealDetailsBtn}
                        onClick={this.navigateToDealDetails}
                      >
                        Back to Deal Details
                      </Button>
                    )}
                  </Box>
                  <Box className={classes.totalFee}>
                    <Typography className={classes.totalFeeLabel}>
                      Total Fee
                    </Typography>
                    <Typography className={classes.totalFeeValue}>
                      ${this.getCurrentPrice().toFixed(2)}
                    </Typography>
                    {oldTotalPrice &&
                      oldTotalPrice.toString() !==
                        this.getCurrentPrice().toString() && (
                        <Typography style={{textDecoration: 'line-through'}}>${oldTotalPrice}</Typography>
                      )}
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </ThemeProvider>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const styles = createStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    width: "100%"
  },

  header: {
    display: "flex",
    flexWrap: "nowrap",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "12px 32px",
    borderBottom: "1px solid #F0FAFA"
  },

  headerLeftSide: {
    display: "flex",
    flexWrap: "nowrap",
    alignItems: "center",
    gap: "48px"
  },

  menuLogo: {
    display: "flex",
    flexWrap: "nowrap",
    alignItems: "center",
    gap: "8px"
  },

  hamburgerButton: {
    backgroundColor: "transparent",
    width: "24px",
    height: "24px",
    cursor: "pointer",
    padding: "unset",
    border: "none",
    minWidth: "0",

    "& > img": {
      width: "100%",
      height: "100%"
    }
  },

  logo: {
    width: "52px",

    "& > img": {
      width: "100%"
    }
  },

  headerRightSide: {
    display: "flex",
    flexWrap: "nowrap",
    alignItems: "center",
    gap: "24px"
  },

  notificationIcon: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "24px",
    height: "24px",
    cursor: "pointer",

    "& .MuiBadge-anchorOriginTopRightRectangle": {
      transform: "scale(1) translate(-40%, 30%)"
    }
  },

  avatarContainer: {
    width: "40px",
    height: "40px",
    borderRadius: "50%",

    "& > img": {
      width: "100%"
    }
  },

  avatar: {
    width: "100%",
    height: "100%",
    borderRadius: "24px"
  },

  main: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "50px"
  },

  formFee: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column"
  },

  formFeeHeader: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column"
  },

  cardContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    boxShadow: "0px 0px 4px 0px #8989895C",
    padding: "30px 45px 30px 45px",
    borderRadius: "10px",
    width: "810px"
  },

  cardPanel: {
    boxShadow: "none"
  },

  progressMenu: {
    padding: "6px 2px 6px 2px",
    borderRadius: "26px"
  },

  inProgress: {
    color: "#346472",
    border: "1px solid #346472"
  },

  feeDetailsContainers: {
    padding: "40px 70px",
    gap: "40px",
    width: "760px",
    borderRadius: "10px",
    border: "1px solid #F1F4F3",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: "20px"
  },

  feeDetailsHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%"
  },

  editFeeBtn: {
    padding: "10px 16px 10px 16px",
    borderRadius: "8px",
    border: "1px solid #4C4D4C",
    "& span": {
      fontFamily: "Roboto",
      fontSize: "16px",
      fontWeight: 700,
      lineHeight: "24px",
      textAlign: "left",
      textTransform: "initial"
    }
  },

  feeDetailsFooter: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    paddingTop: "23px",
    borderTop: "1px solid #F1F4F3"
  },
  feeDetailsButtons: {
    width: "560px"
  },
  cancelBtn: {
    fontFamily: "Roboto",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
    cursor: "pointer"
  },
  saveBtn: {
    width: "464px",
    padding: "16px",
    borderRadius: "8px",
    textTransform: "initial"
  },
  enabledBtn: {
    backgroundColor: "#4FC8EC !important",
    color: "#FFFFFF !important",
    "&:hover": {
      backgroundColor: "#4FC8EC"
    }
  },
  disabledBtn: {
    backgroundColor: "#E8F8FD",
    color: "#A3E2F5",
    "&:hover": {
      backgroundColor: "#E8F8FD"
    }
  },
  backToDealDetailsBtn: {
    width: "100%",
    padding: "16px 0px",
    borderRadius: "8px",
    backgroundColor: "#4FC8EC",
    "& span": {
      fontFamily: "Roboto",
      fontSize: "16px",
      fontWeight: 700,
      lineHeight: "24px",
      color: "#FFFFFF",
      textTransform: "initial"
    },
    "&:hover": {
      backgroundColor: "#4FC8EC",
      color: "#FFFFFF"
    }
  },
  totalFee: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    gap: "10px"
  },
  totalFeeLabel: {
    fontFamily: "Roboto",
    fontSize: "18px",
    fontWeight: 500,
    lineHeight: "18px",
    color: "#4B4C4B"
  },
  totalFeeValue: {
    fontFamily: "Roboto",
    fontSize: "28px",
    fontWeight: 600,
    lineHeight: "24px",
    color: "#013D4F"
  },
  disabledBorder: {
    borderColor: "#C5CBC9"
  },
});
// Customizable Area End

export default withStyles(styles)(OrderSummaryRegistrationFee);
