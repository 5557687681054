import React from "react";
// Customizable Area Start
import {
  Box,
  withStyles,
  createStyles,
  Dialog,
  DialogContent,
  DialogActions,
  Typography,
  Button,
  IconButton,
  Select,
  MenuItem,
  Input,
  InputAdornment,
} from "@material-ui/core";
import ProjectTemplatesDocumentsUploadController, {
  Props,
  configJSON,
} from "./ProjectTemplatesDocumentsUploadController";
import ClearIcon from "@material-ui/icons/Clear";
import Dropzone from "react-dropzone";
import { uploadIcon, viewUploadIcon, downloadIcon, downloadProgress, downloadComplete } from "./assets";
import DeleteIcon from "@material-ui/icons//Delete";
import { CheckCircleOutline, MoreVert, SearchOutlined } from "@material-ui/icons";
import clsx from "clsx";
const styles = createStyles({
  documentUploadDialog: {
    "& > div:nth-child(3) > div:nth-child(1)": {
      height: "450px",
      width: "600px",
      borderRadius: "8px",
      background: "#FFFFFF",
      boxShadow: "none",
      margin: 0,
      display: "block",
      flex: "none",
    },
  },

  documentUploadDialogHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "16px 16px 12px 0px",
  },

  closeIcon: {
    width: "32px",
    height: "32px",
  },

  documentUploadDialogTitle: {
    padding: 0,
    fontFamily: "Roboto",
    fontSize: "24px",
    fontWeight: 500,
    lineHeight: "32px",
    textAlign: "left",
    color: "rgba(1, 61, 79, 1)",
    letterSpacing: "-0.005em",
  },

  documentUploadDialogContent: {
    padding: "0px 44.5px",
  },

  browseFiles: {
    marginTop: "26px",
    marginBottom: "32px",
    gap: "8px",
    borderRadius: "8px",
    background: "#FFFFFF",
    border: "1px solid #C5CBC9",
    display: "flex",
    justifyContent: "center",
    padding: "11px 24px",
    cursor: "pointer"
  },

  documentUploadDialogActions: {
    textTransform: "initial",
    padding: "24px 35px",
    borderTop: "1px solid rgba(242, 243, 242, 1)",

    "& > button": {
      padding: "16px 53px",
      gap: "8px",
      borderRadius: "8px",
    },

    "& > button > span": {
      fontFamily: "Roboto",
      fontSize: "16px",
      fontWeight: 700,
      lineHeight: "24px",
      textAlign: "left",
      textTransform: "initial",
    },
  },
  cancelBtn: {
    border: "1px solid rgba(76, 77, 76, 1)",

    "& > span": {
      color: "rgba(76, 77, 76, 1)",
    },
  },

  submitBtn: {
    background: "#4FC8EC",
    "&:hover": {
      background: "#4FC8EC",
    },

    "&.Mui-disabled": {
      background: "#E8F8FD",
      "& > span": {
        color: "#94A3B8",
      },
    },

    "& > span": {
      color: "#FFFFFF",
    },
  },

  backDrop: {
    backdropFilter: "blur(3px)",
    backgroundColor: "rgba(0,0,30,0.4)",
  },

  orStatement: {
    marginTop: "8px",
    fontFamily: "Roboto",
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "22px",
    textAlign: "center",
  },

  dragAndDrop: {
    marginTop: "8px",
    fontFamily: "Gotham, sans-serif",
    fontSize: "24px",
    fontWeight: 300,
    lineHeight: "32px",
    letterSpacing: "-0.005em",
    textAlign: "center",
    color: "rgba(1, 61, 79, 1)",
  },

  allowableStatement: {
    marginTop: "8px",
    fontFamily: "Roboto",
    fontSize: "12px",
    fontWeight: 500,
    lineHeight: "18px",
    textAlign: "left",
    color: "rgba(75, 76, 75, 1)",
  },

  selectedFiles: {
    padding: 0,
  },

  leftSideSection: {
    width: "70%",
    borderRadius: "20px",
    border: "1px solid #F1F4F3",
    cursor: "pointer",
    height: "863px",
  },

  uploadDocumentSection: {
    height: "100%",
    flexDirection: "column",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  uploadTitle: {
    color: "#C5CBC9",
    fontWeight: 300,
    fontSize: screen.width <= 1440 ? "16px" : "20px",
    lineHeight: "18px",
    fontFamily: "Gotham, sans-serif",
    textAlign: "center",
  },

  documentsSection: {
    marginTop: "25px",
    marginBottom: "174px",
    padding: "40px 30px 35px 30px",
    gap: "30px",
    borderRadius: "10px",
    background: "#FFFFFF",
    boxShadow: "0px 0px 4px 0px #8989895C",
  },

  documentSectionContent: {
    marginTop: "30px",
    display: "flex",
    gap: "30px",
  },

  rightSideSection: {
    overflow: "auto",
    width: "30%",
    height: "863px",
  },

  basicInformation: {
    borderRadius: "10px",
    border: "1px solid #E5E6E5",
    padding: "35px 30px",
  },

  readyBox: {
    borderRadius: "10px",
    border: "1px solid #E5E6E5",
    padding: "35px 30px",
    marginBottom: "30px",
    

    "& p": {
      fontFamily: "Gotham",
      fontSize: screen.width <= 1440 ? "18px" : "24px",
      fontWeight: 400,
      lineHeight: "37px",
      textAlign: "left",
      color: "rgba(1, 61, 79, 1)",
    },

    "& button": {
      width: "100%",
      borderRadius: "8px",
      padding: "16px 0px",
    },
  },

  readyBoxHeader: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "40px",
    alignItems: "center",
    gap: '8px'
  },

  readyButton: {
    background: "rgba(234, 251, 250, 1)",
    color: "rgba(38, 187, 179, 1)",
    marginBottom: "20px",
    textTransform: 'initial',
    "&:hover":{
      background: "rgba(234, 251, 250, 1)",
      color: "rgba(38, 187, 179, 1)",
    }
  },

  notReadyButton: {
    background: "rgba(254, 242, 242, 1)",
    color: "rgba(196, 57, 55, 1)",
    textTransform: 'initial',
    "&:hover":{
      background: "rgba(254, 242, 242, 1)",
      color: "rgba(196, 57, 55, 1)",
    }
  },

  basicInfoTypo: {
    fontSize: screen.width <= 1440 ? "12px" : "18px",
    marginBottom: "30px",
    lineHeight: "18px",
  },

  basicInfoValue: {
    fontSize: screen.width <= 1440 ? "14px" : "20px",
    fontWeight: 500,
    marginLeft: "10px",
    color: "#4B4C4B",
  },

  boxInfoText: {
    fontSize: 16,
    color: "#4B4C4B",
    lineHeight: "20px",
    textAlign: "left",
    fontWeight: 500,
    fontFamily: "Roboto",
  },
  files: {
    display: "flex",
    gap: "30px",
    overflow: "auto",
    width: "100%",
    padding: "20px 0px",
  },

  fileBox: {
    padding: "0px 0px 0px 15px",
    borderRadius: "5px",
    border: "1px solid rgba(228, 231, 230, 1)",
    color: "rgba(255, 255, 255, 1)",
    display: "flex",
    alignItems: "center",
    cursor: 'pointer'
  },

  fileName: {
    fontFamily: "Roboto",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    textAlign: "left",
    color: "rgba(75, 76, 75, 1)",
    whiteSpace: "nowrap",
  },

  selectedFileName: {
    fontFamily: "Roboto",
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "12px",
    letterSpacing: "-0.005em",
    textAlign: "center",
    color: "rgba(75, 76, 75, 1)",
  },

  selectedFileContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap: "30px",
  },

  circularProgress: {
    height: "16px",
    width: "16px",
    marginRight: "10px",
  },

  pdfIframe: {
    height: "100%",
    width: "100%",
    borderRadius: "5px",
    background: "rgba(237, 245, 247, 1)",
  },

  documentsHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },

  documentsToolBar: {
    display: "flex",
    "& .MuiInput-underline:before": {
      content: "none",
    },
    alignItems: "center",
    gap: "20px",
  },
  uploadMoreContainer: {
    display: "flex",
    gap: "10px",
    "& img": {
      width: "20px",
      height: "20px",
    },
  },
  uploadMore: {
    fontFamily: "Roboto",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
    textAlign: "left",
    color: "rgba(79, 200, 236, 1)",
  },
  readyBoxFileName:{
    display: "-webkit-box",
    "-webkit-line-clamp": 2,
    "-webkit-box-orient": "vertical",
    overflow: "hidden",
    textOverflow: "ellipsis",
    wordBreak: "break-word",
  },
  readyButtonChecked:{
    background: "#26BBB3",
    color: "white",
    "&:hover":{
      background: "#26BBB3",
      color: "white",
    }
  },
  checkcircleoutline:{
    marginRight: '8px'
  }
});
// Customizable Area End

export class ProjectTemplatesDocumentsUpload extends ProjectTemplatesDocumentsUploadController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End
  render() {
    // Customizable Area Start
    const { classes, basicInfos } = this.props;
    const { files, selectedFiles, isUploadingDocuments, selectedFile, filteredDocuments} =
      this.state;
    return (
      <>
        <Box className={classes.documentsSection}>
          <Box className={classes.documentsHeader}>
            <Typography variant="h1">
              Documents
              <span style={{ color: "#C5CBC9" }}>
                {" "}
                ({files ? files.length : 0})
              </span>
            </Typography>
            {filteredDocuments.length > 0 && (
              <Box className={classes.documentsToolBar}>
                <Input
                  onChange={this.handleFilterDocuments}
                  placeholder="Search documents..."
                  data-test-id="search-documents"
                  id="standard-adornment-amount"
                  startAdornment={
                    <InputAdornment position="start">
                      <SearchOutlined />
                    </InputAdornment>
                  }
                />
                <Select
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                  value={0}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'left',
                    },
                    transformOrigin: {
                      vertical: 'top',
                      horizontal: 'left',
                    },
                    getContentAnchorEl: null,
                  }}
                >
                  <MenuItem selected={true} value={0}>
                    All
                  </MenuItem>
                  <MenuItem value={1}>1</MenuItem>
                  <MenuItem value={2}>2</MenuItem>
                  <MenuItem value={3}>3</MenuItem>
                </Select>
                <Box className={classes.uploadMoreContainer}>
                  <img src={viewUploadIcon} />
                  <Typography className={classes.uploadMore}>
                    Upload More
                  </Typography>
                </Box>
                <IconButton>
                  <MoreVert />
                </IconButton>
              </Box>
            )}
          </Box>
          <Box className={classes.files} data-test-id="document-files">
            {filteredDocuments.length > 0 &&
              filteredDocuments.map((file, index) => (
                <Box
                  className={classes.fileBox}
                  key={index}
                  onClick={() => this.handleSelectFile(file)}
                >
                  {file.type === 'Ready' ?
                  <img src={downloadComplete} className={classes.circularProgress}></img>
                  :
                  <img src={downloadProgress} className={classes.circularProgress}></img>
                  }
                  <Typography className={classes.fileName}>
                    {file.name}
                  </Typography>
                  <IconButton>
                    <MoreVert />
                  </IconButton>
                </Box>
              ))}
          </Box>
          <Box className={classes.documentSectionContent}>
            <Box className={classes.leftSideSection}>
              {"name" in selectedFile ? (
                <iframe
                  className={classes.pdfIframe}
                  src="https://www.rd.usda.gov/sites/default/files/pdf-sample_0.pdf"
                ></iframe>
              ) : (
                <Box
                  onClick={this.handleUploadingDocuments}
                  data-test-id="documents-upload-section"
                  className={classes.uploadDocumentSection}
                >
                  <img src={uploadIcon}></img>
                  <Box sx={{ height: "77px" }}>
                    <Typography variant="h2" className={classes.dragAndDrop}>
                      Drag & Drop
                    </Typography>
                    <p className={classes.orStatement}>
                      or click <span style={{ color: "#4FC8EC" }}> here </span>{" "}
                      to browse your files
                    </p>
                  </Box>
                  <p className={classes.allowableStatement}>
                    {configJSON.allowableStatement}
                  </p>
                  <p className={classes.uploadTitle}>
                    {configJSON.uploadTitle}
                  </p>
                </Box>
              )}
            </Box>
            <Box className={classes.rightSideSection}>
              {"name" in selectedFile && (
                <Box className={classes.readyBox}>
                  <Box className={classes.readyBoxHeader}>
                    <Typography className={classes.readyBoxFileName}>{selectedFile.name}</Typography>
                    <img width="24px" height="24px" src={downloadIcon} />
                  </Box>
                  <Button data-test-id="ready-btn"onClick={() => this.makeDocumentReady()} className={clsx(classes.readyButton, {
                      [classes.readyButtonChecked]: selectedFile.type === 'Ready',
                    })}>
                    {selectedFile.type === 'Ready' && <CheckCircleOutline className={classes.checkcircleoutline}/>}
                    Ready
                    </Button>
                  <Button data-test-id="not-ready-btn"className={classes.notReadyButton}>Not Ready</Button>
                </Box>
              )}
              <Box className={classes.basicInformation}>
                <Typography variant="h2">Basic Information</Typography>
                <Box sx={{ marginTop: "40px" }} data-test-id="basic-infos">
                  {Object.entries(basicInfos).map(([key, value]) => (
                    <Typography
                      variant="body1"
                      className={classes.basicInfoTypo}
                      key={key}
                    >
                      {key}{" "}
                      <span className={classes.basicInfoValue}>{value}</span>
                    </Typography>
                  ))}
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <Dialog
          data-test-id="documents-upload-dialog"
          open={isUploadingDocuments}
          keepMounted
          onClose={() => {
            this.handleStopUploadingDocuments();
            this.releaseFiles();
          }}
          aria-describedby="alert-dialog-slide-description"
          BackdropProps={{
            classes: {
              root: classes.backDrop,
            },
          }}
          className={classes.documentUploadDialog}
        >
          <Box
            className={classes.documentUploadDialogHeader}
            data-test-id="documents-upload-dialog-header"
          >
            <ClearIcon
              className={classes.closeIcon}
              data-test-id="close-btn"
              onClick={() => {
                this.handleStopUploadingDocuments();
                this.releaseFiles();
              }}
            />
          </Box>
          <DialogContent
            className={classes.documentUploadDialogContent}
            data-test-id="documents-upload-dialog-content"
          >
            <Typography className={classes.documentUploadDialogTitle}>
              Upload your documents
            </Typography>

            <Dropzone
              data-test-id="files-dropzone"
              multiple={true}
              onDrop={(selectedFiles) =>
                this.handleAddSelectedFiles(selectedFiles)
              }
            >
              {({ getRootProps, getInputProps }) => (
                <section>
                  <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    <Box className={classes.browseFiles}>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        <img src={uploadIcon}></img>
                        <Box sx={{ height: "77px", textAlign: "center" }}>
                          <Typography className={classes.dragAndDrop}>
                            Drag & Drop
                          </Typography>
                          <p className={classes.orStatement}>
                            or click{" "}
                            <span style={{ color: "#4FC8EC" }}> here </span> to
                            browse your files
                          </p>
                        </Box>
                        <p className={classes.allowableStatement}>
                          {configJSON.allowableStatement}
                        </p>
                      </Box>
                    </Box>
                  </div>
                  {selectedFiles.length > 0 && (
                    <Box sx={{ marginTop: 2 }}>
                      <Typography
                        style={{ fontSize: "16px" }}
                        className={classes.documentUploadDialogTitle}
                      >
                        Selected Files:
                      </Typography>
                      <ul className={classes.selectedFiles} data-test-id="selected-files">
                        {selectedFiles.map((file, index) => (
                          <li
                            key={index}
                            className={classes.selectedFileContainer}
                          >
                            <Box
                              sx={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <Typography className={classes.selectedFileName}>
                                {file.name}
                              </Typography>
                              <Typography className={classes.selectedFileName}>
                                {(file.size / 1024).toFixed(2)} (KB)
                              </Typography>
                            </Box>
                            <IconButton
                              data-test-id='icon-btn'
                              onClick={() => this.handleDeleteFile(file)}
                              aria-label="delete"
                              size="small"
                            >
                              <DeleteIcon fontSize="small" />
                            </IconButton>
                          </li>
                        ))}
                      </ul>
                    </Box>
                  )}
                </section>
              )}
            </Dropzone>
          </DialogContent>

          <DialogActions
            className={classes.documentUploadDialogActions}
            data-test-id="documents-upload-dialog-actions"
          >
            <Button
              data-test-id="cancel-btn"
              className={classes.cancelBtn}
              onClick={() => {
                this.handleStopUploadingDocuments();
                this.releaseFiles();
              }}
            >
              Cancel
            </Button>
            <Button
              disabled={selectedFiles.length == 0}
              data-test-id="submit-btn"
              className={classes.submitBtn}
              onClick={() => {
                this.handleStopUploadingDocuments();
                this.uploadFiles(selectedFiles);
                this.releaseFiles();
              }}
            >
              Submit
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
    // Customizable Area End
  }
}

export default withStyles(styles)(ProjectTemplatesDocumentsUpload);
