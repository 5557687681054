Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start
exports.apiContentType = "application/json";
exports.getStateEndPoint = "https://vitumarketplace1-511976-ruby.b511976.dev.eastus.az.svc.builder.cafe/get_states"
exports.loginAlertMsg = "Please login first";
exports.listOfOrdersEndPoints = "order_management/orders";
exports.listOfOrdersMethod = "GET";
exports.createOrderEndPoint = "order_management/orders";
exports.createOrderItemInOrderEndPoint = "order_management/orders/:id/add_order_items";
exports.createOrderMethod = "POST";
exports.getOrderByIdEndPoint = "order_management/orders/:id";
exports.getOrderByIdMethod = "GET";
exports.deleteOrderItemEndPoint = "order_management/orders/:id/remove_order_items";
exports.deleteOrderItemMethod = "DELETE";
exports.appplyCouponCodeEndpoint = "order_management/orders/:id/apply_coupon";
exports.appplyCouponCodeMethod = "POST";
exports.getAddressListEndPoint = "order_management/addresses";
exports.getAddressListMethod = "GET";
exports.createAddressEntPoint = "order_management/addresses";
exports.createAddressMethod = "POST";
exports.addAddressToAddressEndPoint = "order_management/orders/:id/add_address_to_order";
exports.addAddressToAddressMethod = "PUT";
exports.getStatesMethod = "GET";
exports.deleteOrderEndPoint = "order_management/orders/:id";
exports.deleteOrderMethod = "DELETE";
exports.cencelOrderEndPoint = "order_management/orders/:id/cancel_order";
exports.cencelOrderMethod = "PUT";
exports.orderCreatedAlertMsg = "You order is created successfully!";
exports.orderNumberText = "Order Number";
exports.quantityText = "Quantity";
exports.orderedOnText = "Ordered On";
exports.cancelOrderText = "Cancel Order";
exports.deleteOrderText = "Delete Order";
exports.cancelledText = "Cancelled";
exports.descriptionText = "Description: ";
exports.categoryText = "Category: ";
exports.createNewOrderText = "Create New Order"
exports.createNewOrderItemText = "Create New Order Item"
exports.addNewOrderItem = "Add New Order Item";
exports.viewDetailBtn = "View Details";
exports.loadingText = "Loading...";
exports.cancelBtnLabel = "Cancel";
exports.createBtnLabel = "Create";
exports.deleteItemBtnLabel = "Delete this item";
exports.currencySymbole = "$";
exports.subTotalText = "Sub total";
exports.shippingChargeText = "Shipping Charges";
exports.totalTaxText = "Total Tax";
exports.discountText = "Discount";
exports.totalText = "Total";
exports.couponCodePlaceholder = "Please enter coupon code";
exports.applyBtnLabel = "Apply";
exports.addressLabel = "Address:";
exports.selectAddress = "Select Add";
exports.changeAddress = "Change Add";
exports.addressNameLabel = "Name";
exports.addressTypeLabel = "Address Type";
exports.flatNoLabel = "Flat no.";
exports.landmarkLabel = "Landmark";
exports.addressLabel = "Address";
exports.addressLine2Label = "Address Line 2";
exports.cityLabel = "City";
exports.stateLabel = "State";
exports.countryLabel = "Country";
exports.zipcodeLabel = "Zipcode";
exports.createNewAddressText = "Create New Address";
exports.addressTypePlaceholder = "Please enter address type*";
exports.namePlaceholder = "Please enter address name*";
exports.flatNoPlaceholder = "Please enter flat no.*";
exports.zipCodePlaceholder = "Please enter zipcode*";
exports.addressPlaceholder = "Please enter address*";
exports.addressLine2Placeholder = "Please enter address line 2";
exports.landmarkPlaceholder = "Please enter landmark";
exports.cityPlaceholder = "Please enter city";
exports.statePlaceholder = "Please enter state";
exports.countryPlaceholder = "Please enter country";
exports.phoneNoPlaceholder = "Please enter phone no*";
exports.selectThisAddressBtn = "Select this address";
exports.addressNavigationAlert = "Order ID not present";
exports.emptyOrderListMsg = "Order list is empty";
exports.catalogueIdPlaceholder = "Please enter catalogue id";
exports.catalogueVariantIdPlaceholder = "Please enter catalogue variant id";
exports.quantityPlaceholder = "Please enter quantity";
exports.createNewOrderTitle = "Create New Order";
exports.orderManagementLabel = "Order Management";

exports.createdealheading = "How do you want to proceed?";
exports.createdealdescr ="You can file the forms yourself or hire a Service Provider to file them for you.";
exports.fillmyself = "File the Forms Myself";
exports.fillmyselfdescr = "I am capable of independently completing the filing process, possessing the requisite knowledge and expertise.";
exports.fillmyformbutton = "File the Forms Myself";
exports.hireserviceprovider = "Hire a Service Provider";
exports.serviceproviderdescr = "I am seeking to engage the services of an individual who can proficiently complete the form on my behalf.";
exports.serviceproviderbutton = "Hire a Service Provider";
exports.backbutton = "Back";
exports.transactionfee = "Transaction Fee";
exports.transactionmsg = "Thank you for opting for our service! To utilize our Vitu Marketplace service, a nominal transaction fee is required.";
exports.transactionfeepaid = "Transaction fees: "
exports.amount = "$50.00";
exports.discount = "10% off due to having a";
exports.paynow = "Pay Now";
exports.totalfee = "Total Fee";
exports.attention = "Attention Please";
exports.attentiondescr = "After you complete the payment, we will ask for the state you are registering the vehicle. You will fill out the required information, and downloadable forms will be generated for you to sign and take to the state authority. (e.g., DMV)";

exports.formFeeTitle = "Pre-Populated Forms Fee"
exports.formFeeThanksLine1 = "Thank you for opting for our service! To produce these"
exports.formFeeThanksLine2 = "documents, a fee is required."
exports.attention = "Attention Please"
exports.attentionDetail = "After you complete the payment, you will fill out the required forms, and then the auto-populated documents will be ready in your dashboard."
exports.agreeTermAndCondition = "I agree to the Terms and Conditions"

exports.blankFormsFee="Blank Forms Fee"
exports.blankFormsFeeThanksLine1 = "Thank you for opting for our service! To acquire this Forms, a"
exports.blankFormsFeeThanksLine2 = "fee is required."
exports.blankFormsFeeAttentionDetail = "After you complete the payment, the blank forms will be available in the deal details."

exports.checklistFee="Checklist Fee"
exports.checklistFeeThanksLine1 = "Thank you for opting for our service! To acquire this checklist,"
exports.checklistFeeThanksLine2 = "a fee is required."
exports.checklistFeeAttentionDetail = "After you complete the payment, the checklist will be available in the deal details."

exports.calculateRegistrationFee="Calculate Registration Fees"
exports.calculateRegistrationFeeThanksLine1 = "Thanks for choosing our calculate Registration Fees service. Please"
exports.calculateRegistrationFeeThanksLine2 = "confirm you wish to proceed with this add-on."
exports.calculateRegistrationFeeAttentionDetail = "After you complete the payment, the Registration Fees will be available in the deal details."
// Customizable Area End
