import React from "react";
// Customizable Area Start
import {
  Box,
  withStyles,
  createStyles,
  Typography,
  Button,
  CardContent,
  Card,
  CardHeader,
  Avatar,
  createTheme,
  ThemeProvider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import DealDashboardNoteController, {
  Props,
  configJSON,
} from './DealDashboardNoteController';
import CustomNoteTypeChange from '../../../components/src/CustomNoteTypeChange';
import CustomRichTextEditor from '../../../components/src/CustomRichTextEditor.web';
import TaskMenu from '../../../../packages/components/src/TaskMenu';

const styles = createStyles({
  taskNoteSection: {
    overflow: 'auto',
    marginTop: '25px',
    padding: '40px 30px 35px 30px',
    gap: '30px',
    borderRadius: '10px',
    opacity: '0px',
    background: '#FFFFFF',
    boxShadow: '0px 0px 4px 0px #8989895C',
    maxHeight: '475px',
  },

  taskNoteHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',

    '& span': {
      textTransform: 'initial',
      color: '#4FC8EC',
    },
  },

  noteList: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: '40px',
  },

  noteCard: {
    width: '100%',
    boxShadow: 'none',
    padding: '23px 0px',
    borderBottom: '1px solid #E5E6E5'
  },

  cardHeaderContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  cardHeader: {
    padding: 0,

    '&:nth-child(2):nth-child(2)': {
      fontSize: '16px',
    },
  },

  typeSymbol: {
    width: '75px',
    height: '32px',
    padding: '6px 12px 6px 12px',
    gap: '7px',
    borderRadius: '26px',
    opacity: '0px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  typeText: {
    fontFamily: 'Roboto',
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '22px',
    textAlign: 'right',
  },

  internal: {
    background: '#EDF5F7',
    color: '#346472',
  },

  external: {
    background: '#FDEDE7',
    color: '#F05E22',
  },

  cardContent: {
    padding: 0,
    marginTop: '10px',

    '&:last-child': {
      paddingBottom: 0,
    },
  },

  cardHeaderSub: {
    fontSize: '16px',
  },

  dialog: {
    '& > div:nth-child(3) > div:nth-child(1)': {
      width: '600px',
      gap: '40px',
      borderRadius: '8px',
      opacity: '0px',
      background: '#FFFFFF',
      boxShadow: 'none',
      margin: 0,
      display: 'block',
      flex: 'none',
    },
  },

  dialogHeader: {
    padding: '24px 16px 24px 40px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '544px',
    height: '32px',
    borderBottom: '1px solid rgba(241, 244, 243, 1)',
  },

  dialogTitle: {
    padding: 0,
    '& > h2': {
      fontFamily: 'Inter, sans-serif',
      fontSize: '24px',
      fontWeight: 700,
      lineHeight: '32px',
      letterSpacing: '-0.11999999731779099px',
      textAlign: 'left',
      color: 'rgba(1, 61, 79, 1)',
    },
  },

  dialogContent: {
    height: '273px',
    padding: '0px 40px',
  },

  dialogActions: {
    textTransform: 'initial',
    padding: '0px 35px',
    height: '104px',
    borderTop: '1px solid rgba(242, 243, 242, 1)',

    '& > button': {
      width: '120px',
      height: '56px',
      padding: '16px',
      gap: '8px',
      borderRadius: '8px',
      opacity: '0px',
    },

    '& > button > span': {
      fontFamily: 'Roboto',
      fontSize: '16px',
      fontWeight: 700,
      lineHeight: '24px',
      textAlign: 'left',
      textTransform: 'initial',
    },
  },

  disagreeBtn: {
    border: '1px solid rgba(76, 77, 76, 1)',

    '& > span': {
      color: 'rgba(76, 77, 76, 1)',
    },
  },

  agreeBtn: {
    background: '#4FC8EC',
    '&:hover': {
      background: '#4FC8EC',
    },

    '&.Mui-disabled': {
      background: '#E8F8FD',
      '& > span': {
        color: '#94A3B8',
      },
    },

    '& > span': {
      color: '#FFFFFF',
    },
  },

  backDrop: {
    backdropFilter: 'blur(3px)',
    backgroundColor: 'rgba(0,0,30,0.4)',
  },

  noteLength: {
    color: '#C5CBC9 !important',
  },

  editNoteInput: {
    width: '100%',

    '& > div': {
      height: '20px',
    },

    '& input': {
      fontFamily: 'Gotham Book',
      fontSize: '18px',
      lineHeight: '18px',
      textAlign: 'left',
    },
  },

  reactquill: {
    marginTop: '37px',

    '& p': {
      fontFamily: 'Inter',
      fontSize: '16px',
      lineHeight: '24px',
      textAlign: 'left',
      wordWrap: 'break-word',
    },

    '& > .ql-snow': {
      border: 'none',
      padding: 0,
    },

    '& .ql-formats': {
      width: '216px',
      height: '24px',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginRight: 0,
    },

    '& > .ql-container': {
      marginTop: '15px',
      height: '109px',
      width: '520px',
      padding: '14px 16px',
      gap: '8px',
      borderRadius: '8px',
      border: '1px solid rgba(197, 203, 201, 1) !important',
      color: 'black',
      fontFamily: 'Inter, sans-serif',
      '& > .ql-editor': {
        padding: 0,
      },
    },

    '& .ql-blank::before': {
      fontStyle: 'normal',
      fontFamily: 'Inter',
      fontSize: '16px',
    },
  },
  noteContentText: {
    marginTop: '2px',

    '& p': {
      margin: 0,
    },
    '& ul': {
      margin: 0,
    },
  },
});

const theme = createTheme({
  typography: {
    fontFamily: 'Roboto',

    h1: {
      fontFamily: 'Gotham, sans-serif',
      fontSize: '28px',
      fontWeight: 400,
      lineHeight: '18px',
      color: '#013D4F',
    },

    h3: {
      fontFamily: 'Gotham, sans-serif',
      fontSize: '20px',
      fontWeight: 300,
      lineHeight: '25px',
      color: '#4B4C4B',
    },

    body1: {
      fontFamily: 'Roboto',
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: '31px',
      color: '#939F9B',
    },

    body2: {
      fontFamily: 'Roboto',
      fontSize: '20px',
      fontWeight: 400,
      lineHeight: '25px',
      textAlign: 'left',
    },
  },
});

const RawHTMLRenderer = (html: string) => {
  return <Typography dangerouslySetInnerHTML={{ __html: html }} variant="h3" />;
};
// Customizable Area End

export class DealDashboardNote extends DealDashboardNoteController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  resetNoteToDefault = () => {
    this.setState({
      note: this.getDefaultNote(),
      isChanging: false,
    });
  };
  // Customizable Area End
  render() {
    // Customizable Area Start
    const { classes } = this.props;
    const { notes, isAddingNote, isEditingNote, note, isChanging } = this.state;
    return (
      <ThemeProvider theme={theme}>
        <Box className={classes.taskNoteSection}>
          <Box className={classes.taskNoteHeader}>
            <Typography variant="h1">
              Notes
              <span className={classes.noteLength}> ({notes.length})</span>
            </Typography>
            <Button
              color="primary"
              data-test-id="add-note-btn"
              onClick={this.handleClickOpen}
              style={{fontWeight: "700", fontSize: "16px"}}
            >
              Add Note
            </Button>
          </Box>
          <Box className={classes.noteList} data-test-id="note-list">
            {notes &&
              notes.map((note, index) => (
                <Card className={classes.noteCard} key={index}>
                  <Box className={classes.cardHeaderContainer}>
                    <CardHeader
                      className={classes.cardHeader}
                      avatar={
                        <Avatar
                          aria-label="recipe"
                          src={note.account?.profile_picture}
                        />
                      }
                      title={
                        <span className={classes.cardHeaderTitle}>
                          {note.account?.full_name}
                        </span>
                      }
                      subheader={
                        <span className={classes.cardHeaderSub}>{note.dealership_name}</span>
                      }
                    />
                    {/* <Box className={note.type === "internal" ? `${classes.internal} ${classes.typeSymbol}` : `${classes.external} ${classes.typeSymbol}`}>
                        <span className={note.type === "internal" ? `${classes.internal} ${classes.typeText}` : `${classes.external} ${classes.typeText}`}>{note.type}</span>
                      </Box> */}
                  </Box>
                  <CardContent className={classes.cardContent}>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        height: '28px',
                      }}
                    >
                      <Typography
                        variant="body1"
                        component="p"
                        style={{ fontWeight: 400 }}
                      >
                        {note.updated_date} &emsp; {note.updated_time}
                      </Typography>
                      {this.state.currentAccountId.toString() ===
                        note.account?.id.toString() && (
                        <TaskMenu
                          data-test-id="note-menu"
                          onSelectAction={(value) =>
                            this.onSelectChange(value, note.id)
                          }
                        />
                      )}
                    </Box>
                    <div className={classes.noteContentText}>
                      {RawHTMLRenderer(note.note)}
                    </div>
                  </CardContent>
                </Card>
              ))}
          </Box>
        </Box>
        <Dialog
          data-test-id="add-note-dialog"
          open={isAddingNote || isEditingNote}
          keepMounted
          onClose={() => {this.handleClose(); this.resetNoteToDefault();}}
          aria-describedby="alert-dialog-slide-description"
          BackdropProps={{
            classes: {
              root: classes.backDrop,
            },
          }}
          className={classes.dialog}
        >
          <Box className={classes.dialogHeader}>
            <DialogTitle className={classes.dialogTitle}>
              {isEditingNote ? configJSON.editNote : configJSON.addNote}
            </DialogTitle>
            <ClearIcon
              data-test-id="close-icon"
              onClick={() => {
                this.handleClose();
                this.resetNoteToDefault();
              }}
            />
          </Box>
          <DialogContent className={classes.dialogContent}>
            <Box
              className={classes.dialogContentText}
              id="alert-dialog-slide-description"
            >
              <CustomNoteTypeChange
                data-test-id="custom-switch"
                type={note.note_type}
                onTypeChange={this.handleTypeChange}
              />
              <CustomRichTextEditor
                data-test-id="note-text-area"
                placeholder="Type your note..."
                className={classes.reactquill}
                theme="snow"
                value={note.note}
                onChange={this.handleContentChange}
                onKeyDown={()=>this.setState({isChanging: true})}
              />
            </Box>
          </DialogContent>
          <DialogActions className={classes.dialogActions}>
            <Button
              data-test-id="cancel-btn"
              className={classes.disagreeBtn}
              onClick={() => {
                this.handleClose();
                this.resetNoteToDefault();
              }}
            >
              Cancel
            </Button>
            <Button
              disabled={!isChanging || this.state.note.note === "<p><br></p>"}
              data-test-id="submit-btn"
              className={classes.agreeBtn}
              onClick={() => {
                if (isAddingNote) {
                  this.handleAddNote();
                }
                if (isEditingNote) {
                  this.handleEditNote();
                }
                this.handleClose();
                this.resetNoteToDefault();
              }}
            >
              {isAddingNote ? 'Add' : 'Submit'}
            </Button>
          </DialogActions>
        </Dialog>
      </ThemeProvider>
    );
    // Customizable Area End
  }
}

export default withStyles(styles)(DealDashboardNote);