// Customizable Area Start
Object.defineProperty(exports, "__esModule", {
  value: true,
});

exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "projectnotes";
exports.labelBodyText = "projectnotes Body";
exports.createNoteApiEndPoint = "bx_block_projectnotes/notes";
exports.btnExampleTitle = "CLICK ME";
exports.deleteNoteApiEndPoint = "DELETE";
exports.updateNoteMethod = "PUT";
exports.httpsRegex =
  /^(?:https?:\/\/)?([a-z\.-]+)\.([a-z]{2,6})(?:\/(\w|-)*)*\/?$/i;
exports.htmlRegex = /<[^<>]+>/g;
exports.addNote = "Add Note";
exports.editNote = "Edit Note";
// Customizable Area End
